import { LOGOUT_SUCCESS } from '../auth/actions';
import { PLAY_AUDIO, PAUSE_AUDIO, SET_AUDIO_TRACK, SET_PLAYBACK_TIME, SET_ARTWORK, SET_DURATION, SET_PLAYER_IDENTIFIER } from './actions';

const initialState = {
  isPlaying: false,
  playerIdentifier: null,
  track: null,
  currentTime: 0,
  artwork: null,
  duration: 0
};

const audioPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case PLAY_AUDIO:
      if(action.payload === null){
        return {
          ...state,
          isPlaying: true,
        }
      }
      return { 
        ...state, 
        isPlaying: true, 
        track: action.payload, 
        playerIdentifier: action.payload.playerIdentifier 
      };
    case PAUSE_AUDIO:
      if (state.playerIdentifier === action.payload) {
        return { ...state, isPlaying: false, playerIdentifier: null };
      }
      return state;
    case SET_AUDIO_TRACK:
      return { ...state, track: action.payload };
    case SET_PLAYBACK_TIME:
      return { ...state, currentTime: action.payload };
    case SET_ARTWORK:
      return {
        ...state,
        artwork: action.payload,
      };
    case SET_DURATION:
      return {
        ...state,
        duration: action.payload,
      };
    case SET_PLAYER_IDENTIFIER: 
      return {
        ...state,
        playerIdentifier: action.payload
      };
    default:
      return state;
  }
};

export default audioPlayerReducer;
