
export const INIT_SPOTIFY_API = '@SPOTIFY/INIT_SPOTIFY_API';
export const SET_SPOTIFY_ACCESS_TOKEN = '@SPOTIFY/SET_SPOTIFY_ACCESS_TOKEN';
export const GET_SPOTIFY_AUTH_URL = '@SPOTIFY/GET_SPOTIFY_AUTH_URL';
export const GET_SPOTIFY_AUTH_URL_CUSTOM_STATE = '@SPOTIFY/GET_SPOTIFY_AUTH_URL_CUSTOM_STATE';
export const SEND_SPOTIFY_CODE = '@SPOTIFY/SEND_SPOTIFY_CODE';
export const SPOTIFY_LOGIN_REDIRECT = '@SPOTIFY/SPOTIFY_LOGIN_REDIRECT';
export const IMPORT_SPOTIFY_PLAYLIST = '@SPOTIFY/IMPORT_SPOTIFY_PLAYLIST';
export const HANDLE_SPOTIFY_AUTH = '@SPOTIFY/HANDLE_SPOTIFY_AUTH';
export const SEARCH_SPOTIFY_SONGS = '@SPOTIFY/SEARCH_SPOTIFY_SONGS';
export const FETCH_SPOTIFY_PLAYLISTS = '@SPOTIFY/FETCH_SPOTIFY_PLAYLISTS';
export const SET_SPOTIFY_PLAYLISTS = '@SPOTIFY/SET_SPOTIFY_PLAYLISTS';
export const SET_SPOTIFY_SEARCH_RESULTS = '@SPOTIFY/SET_SPOTIFY_SEARCH_RESULTS';
export const CLEAR_SPOTIFY_SEARCH_RESULTS = '@SPOTIFY/CLEAR_SPOTIFY_SEARCH_RESULTS';
export const LOAD_SONG_META = '@SPOTIFY/LOAD_SONG_META';
export const CLEAR_SONG_META_CACHE = '@SPOTIFY/CLEAR_SONG_META_CACHE';
export const SET_SONG_META = '@SPOTIFY/SET_SONG_META';
export const SET_SONG_META_LOADING = '@SPOTIFY/SET_SONG_META_LOADING';
export const CONNECT_SPOTIFY_ACCOUNT = '@SPOTIFY/CONNECT_SPOTIFY_ACCOUNT';
export const GET_SPOTIFY_RECOMMENDATIONS = '@SPOTIFY/GET_SPOTIFY_RECOMMENDATIONS';
export const GET_SPOTIFY_RECOMMENDATIONS_SUCCESS = '@SPOTIFY/GET_SPOTIFY_RECOMMENDATIONS_SUCCESS';
export const GET_SPOTIFY_RECOMMENDATIONS_FAILURE = '@SPOTIFY/GET_SPOTIFY_RECOMMENDATIONS_FAILURE';
export const CLEAR_SPOTIFY_RECOMMENDATIONS = '@SPOTIFY/CLEAR_SPOTIFY_RECOMMENDATIONS';

export const clearSpotifyRecommendations = () => ({
  type: CLEAR_SPOTIFY_RECOMMENDATIONS,
});
export const getSpotifyRecommendations = (setListGroupId: string, index: number) => ({
  type: GET_SPOTIFY_RECOMMENDATIONS,
  payload: {
    setListGroupId,
    index,
  },
});
export const getSpotifyRecommendationsSuccess = (recommendations) => ({
  type: GET_SPOTIFY_RECOMMENDATIONS_SUCCESS,
  payload: recommendations,
});
export const getSpotifyRecommendationsFailure = (error) => ({
  type: GET_SPOTIFY_RECOMMENDATIONS_FAILURE,
  payload: error,
});
export const connectSpotifyAccount = () => ({
  type: CONNECT_SPOTIFY_ACCOUNT,
});
export const setSongMetaLoading = (loading) => ({
  type: SET_SONG_META_LOADING,
  payload: loading,
});
export const setSongMeta = (songMeta) => ({
  type: SET_SONG_META,
  payload: songMeta,
});
export const clearSongMetaCache = () => ({
  type: CLEAR_SONG_META_CACHE,
});
export const loadSongMeta = (isrc, spotifyId) => ({
  type: LOAD_SONG_META,
  payload: { isrc, spotifyId },
});
export const clearSpotifySearchResults = () => ({
  type: CLEAR_SPOTIFY_SEARCH_RESULTS,
});

export const setSpotifySearchResults = (searchResults) => ({
  type: SET_SPOTIFY_SEARCH_RESULTS,
  payload: searchResults,
});
export const fetchSpotifyPlaylists = () => ({
  type: FETCH_SPOTIFY_PLAYLISTS,
});

export const setSpotifyPlaylists = (playlists) => ({
  type: SET_SPOTIFY_PLAYLISTS,
  payload: playlists,
});

export const importSpotifyPlaylist = (playlistId, setListGroupId) => ({
  type: IMPORT_SPOTIFY_PLAYLIST,
  payload: { playlistId, setListGroupId },
});

export const handleSpotifyAuth = (code, state) => ({
  type: HANDLE_SPOTIFY_AUTH,
  payload: { code, state },
});

export const searchSpotifySongs = (searchString) => ({
  type: SEARCH_SPOTIFY_SONGS,
  payload: searchString,
});
export const sendSpotifyCode = (code, redirectUrl) => ({
  type: SEND_SPOTIFY_CODE,
  payload: { code, redirectUrl },
});


export const spotifyLoginRedirect = (redirectState?) => ({
  type: SPOTIFY_LOGIN_REDIRECT,
  payload: { redirectState },
});


export const initSpotifyApi = (accessToken) => ({
  type: INIT_SPOTIFY_API,
  payload: accessToken,
});

export const setSpotifyAccessToken = (accessToken) => ({
  type: SET_SPOTIFY_ACCESS_TOKEN,
  payload: accessToken,
});

export const getSpotifyAuthUrl = () => ({
  type: GET_SPOTIFY_AUTH_URL,
});

export const getSpotifyAuthUrlWithCustomState = (state) => ({
  type: GET_SPOTIFY_AUTH_URL_CUSTOM_STATE,
  payload: state,
});
