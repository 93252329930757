import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { Modal, Select, NumberInput } from '@mantine/core';

export const SetListPrintModal = ({ show, actions, setList, setListSongs, setListGroups }: any) => {
    const pdfRef = useRef(null);

    // Use state to manage group new page settings, font size, and text alignment
    const [groups, setGroups] = useState<any[]>([]);
    const [fontSize, setFontSize] = useState(12);
    const [alignment, setAlignment] = useState('center');  // Options: 'left', 'center', 'right'

    useEffect(() => {
        if(show){
            setGroups(setListGroups.map(group => ({
                ...group,
                newPage: !!group.newPage
            })));
        }
    }, [show, setListGroups]);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                generatePdfDocument();
            }, 0);
        }
    }, [show, groups, fontSize, alignment]);

    const splitText = (doc:any, text: string, maxWidth: number) => {
        const words = text.split(' ');
        const lines: string[] = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
            const word = words[i];
            const width = doc.getTextWidth(currentLine + ' ' + word);
            if (width < maxWidth) {
                currentLine += ' ' + word;
            } else {
                lines.push(currentLine);
                currentLine = word;
            }
        }
        lines.push(currentLine);  // Push the last line
        return lines;
    };

    const generatePdfDocument = () => {
        const doc = new jsPDF();
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(18);
        // @ts-expect-error - Missing types for internal
        doc.text(setList.title, doc.internal.pageSize.width / 2, 20, null, 'center');  // Center the title

        let y = 30; // Starting Y position after the title

        groups.forEach((group) => {
            if (group.newPage || y + 10 > doc.internal.pageSize.height - 20) {
                doc.addPage();
                y = 20;
            }

            doc.setDrawColor(0);
            doc.setFillColor(230, 230, 230);
            doc.rect(10, y - 6, doc.internal.pageSize.width - 20, 12, 'F');
            // @ts-expect-error - Missing types for internal
            doc.text(group.title, doc.internal.pageSize.width / 2, y, null, 'center');
            y += 20;

            doc.setFont('helvetica', 'normal');
            doc.setFontSize(fontSize);
            const maxWidth = doc.internal.pageSize.width - 20;  // Max width for text wrapping

            const songs = setListSongs.filter(song => song.setListGroupId === group.id)
                                      .sort((a, b) => a.order - b.order);
            songs.forEach(song => {
                if (y + 10 > doc.internal.pageSize.height - 20) {
                    doc.addPage();
                    y = 20;
                }

                let x = 10; // Default x-coordinate for left alignment
                if (alignment === 'center') {
                    x = doc.internal.pageSize.width / 2; // Center alignment
                } else if (alignment === 'right') {
                    x = doc.internal.pageSize.width - 10; // Right alignment
                }

                const songText = `${song.title} - ${song.artistName}`;
                const lines = splitText(doc, songText, maxWidth);
                lines.forEach(line => {
                    //@ts-expect-error - Missing types for text
                    doc.text(line, x, y, null, alignment);
                    y += 10;  // Adjust line height
                });
            });
        });

        addFooter(doc);
        const outputString = doc.output('datauristring');
        // @ts-expect-error - Missing types for src
        pdfRef.current.src = outputString;
    };

    const handleNewPageChange = (index, checked) => {
        const updatedGroups = groups.map((group, idx) => {
            if (idx === index) {
                return { ...group, newPage: checked };
            }
            return group;
        });
        setGroups(updatedGroups);
        generatePdfDocument();
    };

    const addFooter = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, 105, doc.internal.pageSize.height - 10, null, null, 'center');
        }
    };

    return ( 
        <Modal
            opened={show}
            onClose={actions.close}
            title={`Print Set List: ${setList?.title}`}
            fullScreen
            radius={0}
            transitionProps={{ transition: 'fade', duration: 200 }}
        >
            <h5>Formatting Options</h5>
            <div style={{ marginBottom: '20px' }}>
                <Select
                    label="Text Alignment"
                    value={alignment}
                    onChange={setAlignment as any}
                    data={[
                        { value: 'left', label: 'Left' },
                        { value: 'center', label: 'Center' },
                        { value: 'right', label: 'Right' }
                    ]}
                />
                <NumberInput
                    label="Font Size for Song Titles"
                    value={fontSize}
                    onChange={value => setFontSize(Number(value || 12))}
                    min={8}
                    max={20}
                    step={1}
                />
            </div>
            {groups.map((group, index) => {
                if(index === 0) return null;
                return <div key={group.id}>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={group.newPage}
                            onChange={(e) => handleNewPageChange(index, e.target.checked)}
                        />
                        Begin {group.title} on a new page?
                    </label>
                </div>
})}
            <iframe ref={pdfRef} width="100%" height="600px" title="Set List PDF"></iframe>
        </Modal>
    );
};
