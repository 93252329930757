import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAccount } from '../../redux/auth/actions';
import { type RootState } from '../../redux';
export const ConfirmAccount = () => {
  const { token } = useParams<{ token: string }>();
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const dispatch = useDispatch();
  const loading  = useSelector((state: RootState) => state.auth.loading);
  const success = useSelector((state: RootState) => state.auth.confirmAccountSuccess);
  const user = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    dispatch(confirmAccount(token))
  }, [token]);

  useEffect(() => {
    if (success) {
      const interval = setInterval(() => {
        setRedirectCountdown((countdown) => countdown - 1);
        if(redirectCountdown === 0) clearInterval(interval);
      }, 1000);
      return () => clearInterval(interval);
    }

  }, [success, loading]);

  useEffect(() => {
    if (redirectCountdown === 0) {
      if (user) {
        window.location.href = '/';
      } else {
        window.location.href = '/login';
      }
    }
  }, [redirectCountdown, user]);


  return (
    <div>
      {loading && <p>Confirming account...</p>}
      {!loading && !success && <p>Account confirmation failed</p>}
      {!loading && success && <p>Account confirmed!</p>}
      {(success || !loading) && <p>Redirecting in {redirectCountdown}...</p>}

    </div>
  );
}