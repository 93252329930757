import { LOGOUT_SUCCESS } from "../auth/actions";
import { DASHBOARD_LOADED } from "./actions";

  
  const initialState = {
    dashboard: null,
    dashboardLoading: true,
  };
  
  export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
      case LOGOUT_SUCCESS:
        return initialState;
      case DASHBOARD_LOADED: {
        return {
          ...state,
          dashboard: action.payload,
          dashboardLoading: false,
        };
      }
    
      default:
        return state;
    }
  }