import { TextInput } from '@mantine/core';
import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
//@ts-expect-error ts(2304)
const sessionToken = new google.maps.places.AutocompleteSessionToken()


export const LocationSearchInput = ({ form, field = 'address', placeIdField="venueId", label }: any) => {
  const handleSelect = async (address, placeId) => {

    form.setFieldValue(field, address); // Update your form field
    form.setFieldValue(placeIdField, placeId); // Update your form field

  };
  return (
    <PlacesAutocomplete
    searchOptions={{sessionToken}}
      value={form.values[field]}
      onChange={value => form.setFieldValue(field, value)}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextInput label={label} {...getInputProps({placeholder: 'Search or input the address...'})} value={form.values[field]} />
          <div>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div key={suggestion.id} {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;