export const LOAD_INVOICES = "@INVOICE/LOAD_INVOICES";
export const LOAD_INVOICES_SUCCESS = "@INVOICE/LOAD_INVOICES_SUCCESS";
export const LOAD_INVOICES_FAILURE = "@INVOICE/LOAD_INVOICES_FAILURE";
export const LOAD_INVOICE = "@INVOICE/LOAD_INVOICE";
export const LOAD_INVOICE_SUCCESS = "@INVOICE/LOAD_INVOICE_SUCCESS";
export const LOAD_INVOICE_FAILURE = "@INVOICE/LOAD_INVOICE_FAILURE";
export const CREATE_INVOICE = "@INVOICE/CREATE_INVOICE";
export const CREATE_INVOICE_SUCCESS = "@INVOICE/CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILURE = "@INVOICE/CREATE_INVOICE_FAILURE";
export const UPDATE_INVOICE = "@INVOICE/UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "@INVOICE/UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILURE = "@INVOICE/UPDATE_INVOICE_FAILURE";
export const DELETE_INVOICE = "@INVOICE/DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "@INVOICE/DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "@INVOICE/DELETE_INVOICE_FAILURE";
export const UPDATE_INVOICE_CONTENT = "@INVOICE/UPDATE_INVOICE_CONTENT";
export const UPDATE_INVOICE_CONTENT_SUCCESS = "@INVOICE/UPDATE_INVOICE_CONTENT_SUCCESS";
export const UPDATE_INVOICE_CONTENT_FAILURE = "@INVOICE/UPDATE_INVOICE_CONTENT_FAILURE";


export const updateInvoiceContent = (id, data) => ({
  type: UPDATE_INVOICE_CONTENT,
  payload: { id, data },
});
export const updateInvoiceContentSuccess = (invoice) => ({
  type: UPDATE_INVOICE_CONTENT_SUCCESS,
  payload: invoice,
});
export const updateInvoiceContentFailure = (error) => ({
  type: UPDATE_INVOICE_CONTENT_FAILURE,
  payload: error,
});

export const loadInvoices = () => ({
  type: LOAD_INVOICES,
});
export const loadInvoicesSuccess = (invoices) => ({
  type: LOAD_INVOICES_SUCCESS,
  payload: invoices,
});
export const loadInvoicesFailure = (error) => ({
  type: LOAD_INVOICES_FAILURE,
  payload: error,
});
export const loadInvoice = (id) => ({
  type: LOAD_INVOICE,
  payload: id,
});
export const loadInvoiceSuccess = (invoice) => ({
  type: LOAD_INVOICE_SUCCESS,
  payload: invoice,
});
export const loadInvoiceFailure = (error) => ({
    type: LOAD_INVOICE_FAILURE,
    payload: error,
    });
export const createInvoice = (data) => ({
    type: CREATE_INVOICE,
    payload: data,
    });
export const createInvoiceSuccess = (invoice) => ({
    type: CREATE_INVOICE_SUCCESS,
    payload: invoice,
    });
export const createInvoiceFailure = (error) => ({
    type: CREATE_INVOICE_FAILURE,
    payload: error,
    });
export const updateInvoice = (id, data) => ({
    type: UPDATE_INVOICE,
    payload: {id, data},
    });
export const updateInvoiceSuccess = (invoice) => ({
    type: UPDATE_INVOICE_SUCCESS,
    payload: invoice,
    });
export const updateInvoiceFailure = (error) => ({
    type: UPDATE_INVOICE_FAILURE,
    payload: error,
    });
export const deleteInvoice = (id) => ({
    type: DELETE_INVOICE,
    payload: id,
    });
export const deleteInvoiceSuccess = (invoice) => ({
    type: DELETE_INVOICE_SUCCESS,
    payload: invoice,
    });
export const deleteInvoiceFailure = (error) => ({
    type: DELETE_INVOICE_FAILURE,
    payload: error,
    });
    