import { Profile } from '../../Api';
import { LOGOUT_SUCCESS } from '../auth/actions';
import * as actions from './actions';

const initialState: {
    profiles: Profile[],
    presence: any[],
    profile?: Profile | null,
    checkSlugResult: any | null,
    loading: boolean,
    error: any | null,
    showCreatePersonalProfileModal: boolean,
    showCreateProfileModal: boolean,
    createProfileResult: Profile | null,
} = {
  profiles: [],
  presence: [],
  profile: null,
  checkSlugResult: null, 
  loading: false,
  error: null,
  showCreatePersonalProfileModal: false,
  showCreateProfileModal: false,
  createProfileResult: null,
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case actions.CLEAR_CREATE_PROFILE_RESULT: 
        return {
            ...state,
            createProfileResult: null,
        };
    case actions.CLEAR_SLUG_RESULT:
        return {
            ...state,
            checkSlugResult: null,
        };
    case actions.SET_PROFILE:
        return {
            ...state,
            profile: action.payload,
        };
    case action.SET_USER_LOCATION: 
    // update item in presence by id if exists, else add to presence
    return {
        ...state,
        presence: [...state.presence.map((item: any) => {
            if (item.id === action.payload.id) {
                return {
                    ...item,
                    ...action.payload.location,
                }
            }
            return item
        })]
    }
    case action.TOGGLE_CREATE_PROFILE_MODAL:
        return {
            ...state,
            showCreateProfileModal: action.payload,
        };
    case actions.TOGGLE_CREATE_PERSONAL_MODAL:
        return {
            ...state,
            showCreatePersonalProfileModal: action.payload,
        };
    case actions.SET_USER_PRESENCE:
        return {
            ...state,
            presence: action.payload,
        };
    case actions.CREATE_PROFILE:
    case actions.DELETE_PROFILE:
    case actions.UPDATE_PROFILE:
    case actions.GET_PROFILES:
    case actions.GET_PROFILE:
    case actions.CHECK_SLUG:
    case actions.CHANGE_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case actions.CREATE_PROFILE_SUCCESS:
    case actions.UPDATE_PROFILE_SUCCESS:
    case actions.GET_PROFILE_SUCCESS:
    case actions.CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        createProfileResult: action.payload,
        checkSlugResult: null,
      };
    case actions.DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: state.profiles.filter((profile: Profile) => profile.id !== action.payload.id),
      };
    case actions.GET_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        profiles: action.payload,
      };
    case actions.CHECK_SLUG_SUCCESS:
      return {
        ...state,
        loading: false,
        checkSlugResult: action.payload,
      };
    case actions.CREATE_PROFILE_ERROR:
    case actions.DELETE_PROFILE_ERROR:
    case actions.UPDATE_PROFILE_ERROR:
    case actions.GET_PROFILES_ERROR:
    case actions.GET_PROFILE_ERROR:
    case actions.CHECK_SLUG_ERROR:
    case actions.CHANGE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default profileReducer;