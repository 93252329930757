import { Card, Title } from "@mantine/core"
import { SetListDetails } from "./components/SetListDetails";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { loadSetList, setCurrentSetListGroup } from "../../redux/set-list/actions";
import { SetListEditor } from "./components/SetListEditor";
import { useDisclosure } from "@mantine/hooks";
import { SetListSongOverview } from "./components/SetListSongOverview";
import { useQuery } from "../../hooks/router/use-query";
import { ImportSpotifyPlaylist } from "../../components/SetList/ImportSpotifyPlaylist";
import { RootState } from "../../redux";
export const SetListBuilder = () => {
    const dispatch = useDispatch();
    const { setListId } = useParams();
    const [showAddSong, addSongActions] = useDisclosure(false);
    const [showImportFromSpotify, setShowImportFromSpotify] = useState(false); 
    const [setListSongDetails, setSetListSongDetails] = useState(null);

    useEffect(() => {
        dispatch(loadSetList(setListId));
    }, [setListId])
    const query = useQuery();
    const currentGroup = useSelector((state: RootState) => state.setList.currentGroup);
    console.log({currentGroup})
    const setList = useSelector((state: RootState) => state.setList.setLists).find((setList: any) => setList.id === setListId);
    const setListGroups = useSelector((state: RootState) => state.setList.setListGroups).filter((setListGroup: any) => setListGroup.setListId === setListId).sort((a: any, b: any) => a.order - b.order);
    const setListSongs = useSelector((state: RootState) => state.setList.setListSongs).sort((a: any, b: any) => a.order - b.order);
    const filteredSetListSongs = setListSongs.filter((song: any) => song.setListGroupId === currentGroup);
    const setListLoading = useSelector((state: RootState) => state.setList.loading[setListId!]) === true;
    const [searchParams] = useSearchParams();
    const setCurrentGroup = (groupId: string | null) => {
      dispatch(setCurrentSetListGroup(groupId));
    };
    useEffect(() => {
        if(searchParams.has('setListGroupId')){
          setCurrentGroup(searchParams.get('setListGroupId')!);
          searchParams.delete('setListGroupId');
          searchParams.delete('authSuccess');
          return;
        }
        console.log('got set list groups', setListGroups, currentGroup)
        if (setListGroups.length > 0 && currentGroup === null) {
          console.log('setting current group', setListGroups[0].id)
          setCurrentGroup(setListGroups[0].id);
        }
      }, [setListGroups, currentGroup, searchParams]);
    useEffect(() => {
      if(query.oauthSuccess){
        setShowImportFromSpotify(true);
      }
    }, [query.oauthSuccess]);

    useEffect(() => {
      let wakeLock: any = null;

      const requestWakeLock = async () => {
        console.log('requesting wake lock')
          try {
              if ('wakeLock' in navigator && navigator.wakeLock) {
                  wakeLock = await (navigator.wakeLock as any).request('screen');
                  console.log('Wake Lock is active');
              }
          } catch (err) {
              console.error(`Could not acquire wake lock: ${err}`);
          }
      };

      const handleVisibilityChange = () => {
        console.log('visibility change', wakeLock, document.visibilityState)
          if (wakeLock !== null && document.visibilityState === 'visible') {
              requestWakeLock();
          }
      };

      requestWakeLock();
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
          wakeLock?.release().then(() => {
              console.log('Wake Lock was released');
          });
          document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
  }, []);

  useEffect(() => {
    return () => {
      setCurrentGroup(null);
    }
  }, []);
   
    return <Card>
        <Title order={3}>Set List Builder</Title>
        <SetListDetails setList={setList} loading={setListLoading} />
        <SetListEditor setSetListSongDetails={setSetListSongDetails} setListId={setListId} showAddSong={showAddSong} addSongActions={addSongActions} currentGroup={currentGroup} setCurrentGroup={setCurrentGroup} setList={setList} filteredSetListSongs={filteredSetListSongs} setListSongs={setListSongs} setListGroups={setListGroups}/>
        <SetListSongOverview setListSongDetails={setListSongDetails} setSetListSongDetails={setSetListSongDetails} />
        <ImportSpotifyPlaylist opened={showImportFromSpotify} toggle={setShowImportFromSpotify} handleImport={() => {}} setListGroupId={currentGroup} />
    </Card>
}