import { RepertoireView } from "../../Api";
import { LOAD_REPERTOIRE, LOAD_REPERTOIRE_FAILURE, LOAD_REPERTOIRE_SUCCESS } from "./actions";



const initialState: {
    repertoire: RepertoireView[],
    loading: boolean,
    error: any,
} = {
  repertoire: [],
  loading: true,
  error: null
};

const repertoireReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REPERTOIRE:
      return {
        ...state,       
        loading: true,
      };
    case LOAD_REPERTOIRE_SUCCESS:
        return {
            ...state,
            loading: false,
            repertoire: action.payload,
        };
    case LOAD_REPERTOIRE_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
        };
    default:
      return state;
  }
};

export default repertoireReducer;
