import React from "react";
import { AccountEdit } from "./AccountEdit";
import { ChangePassword } from "./ChangePassword";

export const Account = () => {
  return (
    <div>
      <AccountEdit />
      <ChangePassword />
    </div>
  );
};
