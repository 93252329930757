import { Invoice } from '../../Api';
import { LOGOUT_SUCCESS } from '../auth/actions';
import * as actions from './actions';

const initialState: {
  invoices: Invoice[],
  invoice: Invoice | null,
  loading: boolean,
  error: any | null,
} = {
  invoices: [],
  invoice: null,
  loading: false,
  error: null,
};


function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case actions.LOAD_INVOICES:
    case actions.LOAD_INVOICE:
    case actions.CREATE_INVOICE:
    case actions.UPDATE_INVOICE:
    case actions.DELETE_INVOICE:
      return {
        ...state,
        loading: true,
      };
    case actions.LOAD_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload,
      };
    case actions.UPDATE_INVOICE_CONTENT_SUCCESS: {
// update invoice content by invoices.*.content.id 
      const invoices = [...state.invoices];
      const contentIndex  = invoices.findIndex((invoice:any) => invoice.content.id === action.payload.id);
      invoices[contentIndex].content = action.payload;
      return {
        ...state,
        loading: false,
        invoices: invoices,
      };
    }
      
    case actions.LOAD_INVOICE_SUCCESS:
    case actions.CREATE_INVOICE_SUCCESS:
    case actions.UPDATE_INVOICE_SUCCESS:

      return {
        ...state,
        loading: false,
        invoice: action.payload,
        invoices: [...state.invoices.filter((invoice:any) => invoice.id !== action.payload.id), action.payload],
      };
    case actions.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: [...state.invoices.filter((invoice:any) => invoice.id !== action.payload.id)],
      };
    case actions.LOAD_INVOICES_FAILURE:
    case actions.LOAD_INVOICE_FAILURE:
    case actions.CREATE_INVOICE_FAILURE:
    case actions.UPDATE_INVOICE_FAILURE:
    case actions.DELETE_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default invoiceReducer;