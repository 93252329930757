import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";

import { createSetListGroup, updateSetListGroup } from '../../../redux/set-list/actions';

export function SetListAddGroupModal({ opened, actions, pageCount, setListGroup, setSetListGroup, setListId}:any) {
  const dispatch = useDispatch();

    const form = useForm({
        initialValues: {
          title: `Page ${pageCount + 1}`,
          description: '',
        },
        // validate: {
        //   title: (value) => value.trim().length > 0,
        // },
      });
      useEffect(() => {
        console.log({setListGroup})
        if(setListGroup){
          return form.setValues({
            title: setListGroup.title,
            description: setListGroup.description,
          });
        }

        form.setValues({
          title: `Page ${pageCount + 1}`,
          description: '',
        });
      }, [opened, setListGroup, pageCount]);
      const handleSubmit = (values: any) => {
        if(setListGroup){
          dispatch(updateSetListGroup(setListGroup.id, values));
        } else {
          dispatch(createSetListGroup(values.title, values.description, setListId));
        }
        actions.close();
      };
  return (
    <>
      <Modal opened={opened} onClose={() => {
        form.reset();
        setSetListGroup(null);
        actions.close();
      }} title="Add New Group" centered>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <TextInput
          withAsterisk
          label="Title"
          {...form.getInputProps('title')}
        />

        <TextInput
        type='textarea'
          label="Description"
          {...form.getInputProps('description')}
        />
        <Group justify="flex-end" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
      </Modal>
    </>
  );
}