// sagas.js
import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchGigsSuccess, fetchGigsFailure, FETCH_GIGS, CREATE_GIG, createGigSuccess, FETCH_GIG, fetchGigSuccess, fetchGigFailure, UPDATE_GIG } from './actions';
import { Api } from '../../Api';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { push } from 'redux-first-history';
function* fetchGigsSaga(api: Api<any>, action) {
  try {
    const request = RequestQueryBuilder.create({
        filter: [{
            field: 'startDate',
            operator: '$gte',
            value: action.payload.startDate
        }, {
            field: 'startDate',
            operator: '$lte',
            value: action.payload.endDate
        }]
    })
    const queryObject = action.payload.personal ? { query: {filter: [action.payload.startDate, action.payload.endDate ]}}: request.queryObject;
    const response = yield call(action.payload.personal ? api.gigs.gigControllerGetPersonalCalendar : api.gigs.getManyBaseGigControllerGig,
        queryObject
    );
    yield put(fetchGigsSuccess(response.data));
  } catch (error: any) {
    yield put(fetchGigsFailure(error.message));
  }
}

function* createGigSaga(api: Api<any>, action) {
  try {
    
    const response = yield call(api.gigs.createOneBaseGigControllerGig, {
      ...action.payload.data
    });
    yield put(createGigSuccess(response.data));
    if(action.payload.navigate){
      yield put(push(`/gigs/${response.data.id}`));
    }
    
  } catch (error: any) {
    yield put(fetchGigsFailure(error.message));
  }
}
function* fetchGigSaga(api: Api<any>, action) {
  try {
    const response = yield call(api.gigs.getOneBaseGigControllerGig, action.payload);
    yield put(fetchGigSuccess(response.data));
  } catch (error: any) {
    yield put(fetchGigFailure(error.message));
  }
}
function* updateGigSaga(api: Api<any>, action) {
  try {
    const response = yield call(api.gigs.updateOneBaseGigControllerGig, action.payload.id, action.payload.data);
    yield put(fetchGigSuccess(response.data));
  } catch (error: any) {
    yield put(fetchGigFailure(error.message));
  }
}
export default function* rootSaga(api: Api<any>) {
  yield takeLatest(FETCH_GIGS, fetchGigsSaga, api);
  yield takeLatest(CREATE_GIG, createGigSaga, api);
  yield takeLatest(FETCH_GIG, fetchGigSaga, api);
  yield takeLatest(UPDATE_GIG, updateGigSaga, api);
}
