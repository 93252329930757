// audioPlayerActions.js

export const PLAY_AUDIO = '@AUDIO_PLAYER/PLAY_AUDIO';
export const PAUSE_AUDIO = '@AUDIO_PLAYER/PAUSE_AUDIO';
export const SET_AUDIO_TRACK = '@AUDIO_PLAYER/SET_AUDIO_TRACK';
export const SET_PLAYBACK_TIME = '@AUDIO_PLAYER/SET_PLAYBACK_TIME';
export const SET_ARTWORK = '@AUDIO_PLAYER/SET_ARTWORK';
export const SET_DURATION = '@AUDIO_PLAYER/SET_DURATION';
export const SET_PLAYER_IDENTIFIER = '@AUDIO_PLAYER/SET_PLAYER_IDENTIFIER';
export const AUDIO_LOADED = '@AUDIO_PLAYER/AUDIO_LOADED';
export const AUDIO_ENDED = '@AUDIO_PLAYER/AUDIO_ENDED';

export const audioEnded = () => ({
  type: AUDIO_ENDED,
});
export const setDuration = (duration) => ({
  type: SET_DURATION,
  payload: duration,
});

export const setPlayerIdentifier = (playerIdentifier) => ({
  type: SET_PLAYER_IDENTIFIER,
  payload: playerIdentifier,
});

export const setArtwork = (artwork) => ({
  type: SET_ARTWORK,
  payload: artwork,
});

export const playAudio = (trackData: any = null) => ({
  type: PLAY_AUDIO,
  payload: trackData,
});

export const pauseAudio = (playerIdentifier: string | null = null) => ({
  type: PAUSE_AUDIO,
  payload: playerIdentifier,
});

export const setAudioTrack = (trackData) => ({
  type: SET_AUDIO_TRACK,
  payload: trackData,
});

export const setPlaybackTime = (time) => ({
  type: SET_PLAYBACK_TIME,
  payload: time,
});
