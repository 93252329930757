
export const CREATE_PROFILE = "@PROFILE/CREATE_PROFILE";
export const CREATE_PROFILE_SUCCESS = "@PROFILE/CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_ERROR = "@PROFILE/CREATE_PROFILE_ERROR";
export const DELETE_PROFILE = "@PROFILE/DELETE_PROFILE";
export const DELETE_PROFILE_SUCCESS = "@PROFILE/DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_ERROR = "@PROFILE/DELETE_PROFILE_ERROR";
export const UPDATE_PROFILE = "@PROFILE/UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "@PROFILE/UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "@PROFILE/UPDATE_PROFILE_ERROR";
export const GET_PROFILES = "@PROFILE/GET_PROFILES";
export const GET_PROFILES_SUCCESS = "@PROFILE/GET_PROFILES_SUCCESS";
export const GET_PROFILES_ERROR = "@PROFILE/GET_PROFILES_ERROR";
export const GET_PROFILE = "@PROFILE/GET_PROFILE";
export const GET_PROFILE_SUCCESS = "@PROFILE/GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "@PROFILE/GET_PROFILE_ERROR";
export const CHECK_SLUG = "@PROFILE/CHECK_SLUG";
export const CHECK_SLUG_SUCCESS = "@PROFILE/CHECK_SLUG_SUCCESS";
export const CHECK_SLUG_ERROR = "@PROFILE/CHECK_SLUG_ERROR";
export const CLEAR_SLUG_RESULT = "@PROFILE/CLEAR_SLUG_RESULT";
export const CHANGE_PROFILE = "@PROFILE/CHANGE_PROFILE";
export const CHANGE_PROFILE_SUCCESS = "@PROFILE/CHANGE_PROFILE_SUCCESS";
export const CHANGE_PROFILE_ERROR = "@PROFILE/CHANGE_PROFILE_ERROR";
export const SET_PROFILE = "@PROFILE/SET_PROFILE";
export const UPDATE_USER_LOCATION = "@PROFILE/UPDATE_USER_LOCATION";
export const UPDATE_USER_LOCATION_SUCCESS = "@PROFILE/UPDATE_USER_LOCATION_SUCCESS";
export const UPDATE_USER_LOCATION_ERROR = "@PROFILE/UPDATE_USER_LOCATION_ERROR";
export const SET_USER_PRESENCE = "@PROFILE/SET_USER_PRESENCE";
export const SET_USER_LOCATION = '@PROFILE/SET_USER_LOCATION';
export const TOGGLE_CREATE_PERSONAL_MODAL = '@PROFILE/TOGGLE_CREATE_PERSONAL_MODAL';
export const TOGGLE_CREATE_PROFILE_MODAL = '@PROFILE/TOGGLE_CREATE_PROFILE_MODAL';
export const LOAD_PERSONAL_PROFILE = '@PROFILE/LOAD_PERSONAL_PROFILE';
export const LOAD_PERSONAL_PROFILE_SUCCESS = '@PROFILE/LOAD_PERSONAL_PROFILE_SUCCESS';
export const LOAD_PERSONAL_PROFILE_ERROR = '@PROFILE/LOAD_PERSONAL_PROFILE_ERROR';
export const CREATE_PERSONAL_PROFILE = '@PROFILE/CREATE_PERSONAL_PROFILE';
export const CREATE_PERSONAL_PROFILE_SUCCESS = '@PROFILE/CREATE_PERSONAL_PROFILE_SUCCESS';
export const CREATE_PERSONAL_PROFILE_ERROR = '@PROFILE/CREATE_PERSONAL_PROFILE_ERROR';
export const CLEAR_CREATE_PROFILE_RESULT = '@PROFILE/CLEAR_CREATE_PROFILE_RESULT';

export const clearCreateProfileResult = () => ({
    type: CLEAR_CREATE_PROFILE_RESULT,
    });
export const createPersonalProfile = (profile: any) => ({
    type: CREATE_PERSONAL_PROFILE,
    payload: profile,
    });
export const createPersonalProfileSuccess = (profile: any) => ({
    type: CREATE_PERSONAL_PROFILE_SUCCESS,
    payload: profile,
    });
export const createPersonalProfileError = (error: any) => ({
    type: CREATE_PERSONAL_PROFILE_ERROR,
    payload: error,
    });


export const loadPersonalProfile = () => ({
    type: LOAD_PERSONAL_PROFILE,
    });
export const loadPersonalProfileSuccess = (profile: any) => ({
    type: LOAD_PERSONAL_PROFILE_SUCCESS,
    payload: profile,
    });
export const loadPersonalProfileError = (error: any) => ({
    type: LOAD_PERSONAL_PROFILE_ERROR,
    payload: error,
    });
export const toggleCreatePersonalModal = (value: boolean) => ({
    type: TOGGLE_CREATE_PERSONAL_MODAL,
    payload: value,
    });
export const toggleCreateProfileModal = (value: boolean) => ({
    type: TOGGLE_CREATE_PROFILE_MODAL,
    payload: value,
    });
export const setUserLocation = (location: any) => ({
    type: SET_USER_LOCATION,
    payload: location,
    });
export const setUserPresence = (presence: any) => ({
    type: SET_USER_PRESENCE,
    payload: presence,
    }); 
export const updateUserLocation = (location: any) => ({
    type: UPDATE_USER_LOCATION,
    payload: location,
    });
export const createProfile = (profile: any) => ({
  type: CREATE_PROFILE,
  payload: profile,
});
export const createProfileSuccess = (profile: any) => ({
  type: CREATE_PROFILE_SUCCESS,
  payload: profile,
});
export const createProfileError = (error: any) => ({
  type: CREATE_PROFILE_ERROR,
  payload: error,
});
export const deleteProfile = (id: string) => ({
  type: DELETE_PROFILE,
  payload: id,
});
export const deleteProfileSuccess = (id: string) => ({
  type: DELETE_PROFILE_SUCCESS,
  payload: id,
});

export const deleteProfileError = (error: any) => ({    
    type: DELETE_PROFILE_ERROR,
    payload: error,
    });
export const updateProfile = (id: string, profile: any) => ({
    type: UPDATE_PROFILE,
    payload: profile,
    });
export const updateProfileSuccess = (profile: any) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: profile,
    });
export const updateProfileError = (error: any) => ({
    type: UPDATE_PROFILE_ERROR,
    payload: error,
    });
export const getProfiles = () => ({
    type: GET_PROFILES,
    });
export const getProfilesSuccess = (profiles: any) => ({
    type: GET_PROFILES_SUCCESS,
    payload: profiles,
    });
export const getProfilesError = (error: any) => ({
    type: GET_PROFILES_ERROR,
    payload: error,
    });
export const getProfile = (id: string) => ({
    type: GET_PROFILE,
    payload: id,
    });
export const getProfileSuccess = (profile: any) => ({
    type: GET_PROFILE_SUCCESS,
    payload: profile,
    });
export const getProfileError = (error: any) => ({
    type: GET_PROFILE_ERROR,
    payload: error,
    });
export const checkSlug = (slug: string) => ({
    type: CHECK_SLUG,
    payload: slug,
    });
export const checkSlugSuccess = (slug: string) => ({
    type: CHECK_SLUG_SUCCESS,
    payload: slug,
    });
export const checkSlugError = (error: any) => ({
    type: CHECK_SLUG_ERROR,
    payload: error,
    });
export const clearSlugResult = () => ({
    type: CLEAR_SLUG_RESULT,
    });
    
export const changeProfile = (id: string) => ({
    type: CHANGE_PROFILE,
    payload: id,
    });
export const changeProfileSuccess = (profile: any) => ({
    type: CHANGE_PROFILE_SUCCESS,
    payload: profile,
    });
export const changeProfileError = (error: any) => ({
    type: CHANGE_PROFILE_ERROR,
    payload: error,
    });
export const setProfile = (profile: any) => ({
    type: SET_PROFILE,
    payload: profile,
    });
