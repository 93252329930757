// Action Types for Optimistic UI Updates
export const CREATE_SET_LIST_SONG_OPTIMISTICALLY = '@SET_LIST/CREATE_SET_LIST_SONG_OPTIMISTICALLY';
export const UPDATE_SET_LIST_SONG_OPTIMISTICALLY = '@SET_LIST/UPDATE_SET_LIST_SONG_OPTIMISTICALLY';
export const DELETE_SET_LIST_SONG_OPTIMISTICALLY = '@SET_LIST/DELETE_SET_LIST_SONG_OPTIMISTICALLY';
export const CONFIRM_SET_LIST_SONG_UPDATE = '@SET_LIST/CONFIRM_SET_LIST_SONG_UPDATE';
export const REVERT_SET_LIST_SONG_UPDATE = '@SET_LIST/REVERT_SET_LIST_SONG_UPDATE';
export const CONFIRM_SET_LIST_SONG_CREATE = '@SET_LIST/CONFIRM_SET_LIST_SONG_CREATE';

// Action Types for Set List Group Management
export const CREATE_SET_LIST_GROUP = '@SET_LIST/CREATE_SET_LIST_GROUP';
export const SET_SET_LIST_GROUP_DATA = '@SET_LIST/SET_SET_LIST_GROUP_DATA';
export const UPDATE_SET_LIST_GROUP = '@SET_LIST/UPDATE_SET_LIST_GROUP';
export const ADD_NEW_SET_LIST_GROUP = '@SET_LIST/ADD_NEW_SET_LIST_GROUP';

// Action Types for Set List Management
export const LOAD_SET_LIST = '@SET_LIST/LOAD_SET_LIST';
export const UPDATE_SET_LIST = '@SET_LIST/UPDATE_SET_LIST';
export const SET_SET_LIST_DATA = '@SET_LIST/SET_SET_LIST_DATA';
export const REMOVE_SET_LIST_DATA = '@SET_LIST/REMOVE_SET_LIST_DATA';
export const SET_LOADING_SET_LIST = '@SET_LIST/SET_LOADING_SET_LIST';

// Action Types for Spotify Playlist Import
export const IMPORT_SPOTIFY_PLAYLIST = '@SET_LIST/IMPORT_SPOTIFY_PLAYLIST';

// Action Types for Set List Song Data Management
export const SET_SET_LIST_SONG_DATA = '@SET_LIST/SET_SET_LIST_SONG_DATA';
export const REORDER_SET_LIST_SONGS = '@SET_LIST/REORDER_SET_LIST_SONGS';
export const SET_SET_LIST_SONG_ORDER = '@SET_LIST/SET_SET_LIST_SONG_ORDER';
export const UPDATE_SET_LIST_SONG = '@SET_LIST/UPDATE_SET_LIST_SONG';

export const LIST_SET_LISTS = '@SET_LIST/LIST_SET_LISTS';
export const LIST_SET_LISTS_SUCCESS = '@SET_LIST/LIST_SET_LISTS_SUCCESS';
export const LIST_SET_LISTS_FAILURE = '@SET_LIST/LIST_SET_LISTS_FAILURE';
export const CREATE_SET_LIST = '@SET_LIST/CREATE_SET_LIST_SONG';
export const CREATE_SET_LIST_SUCCESS = '@SET_LIST/CREATE_SET_LIST_SONG_SUCCESS';
export const CREATE_SET_LIST_FAILURE = '@SET_LIST/CREATE_SET_LIST_SONG_FAILURE';

export const ADD_RECOMMENDED_SONG = '@SET_LIST/ADD_RECOMMENDED_SONG';

export const DRAG_ACROSS_GROUPS = '@SET_LIST/DRAG_ACROSS_GROUPS';

export const SET_CURRENT_GROUP = '@SET_LIST/SET_CURRENT_GROUP';


export const setCurrentSetListGroup = (groupId: string | null) => ({
  type: SET_CURRENT_GROUP,
  payload: { groupId },
});

export const dragAcrossGroups = (source, destination, draggableId) => ({
  type: DRAG_ACROSS_GROUPS,
  payload: { source, destination, draggableId },
});

export const addRecommendedSong = (spotifyId: string, index: number, setListGroupId: string) => ({
  type: ADD_RECOMMENDED_SONG,
  payload: {
    spotifyId,
    index,
    setListGroupId,
  },
});
export const createSetList = (title, description, navigate = true) => ({
  type: CREATE_SET_LIST,
  payload: { title, description, navigate },
});
export const createSetListSuccess = (setList) => ({
  type: CREATE_SET_LIST_SUCCESS,
  payload: setList,
});
export const createSetListFailure = (error) => ({
  type: CREATE_SET_LIST_FAILURE,
  payload: error,
});
export const listSetLists = () => ({ type: LIST_SET_LISTS });
export const listSetListsSuccess = (setLists) => ({ type: LIST_SET_LISTS_SUCCESS, payload: setLists });
export const listSetListsFailure = (error) => ({ type: LIST_SET_LISTS_FAILURE, payload: error });
export const updateSetListSong = (id, data) => ({
  type: UPDATE_SET_LIST_SONG,
  payload: { id, data },
});

export const confirmSetListSongCreate = (data) => ({
  type: CONFIRM_SET_LIST_SONG_CREATE,
  payload: data,
});

export const setSetListSongOrder = (songs) => ({
  type: SET_SET_LIST_SONG_ORDER,
  payload: songs,
});
export const reorderSetListSongs = (setListId, setListGroupId, songs) => ({
  type: REORDER_SET_LIST_SONGS,
  payload: { setListId, setListGroupId, songs },
})
export const createSetListSongOptimistically = (setListGroupId, data, optimisticId) => ({
  type: CREATE_SET_LIST_SONG_OPTIMISTICALLY,
  payload: { setListGroupId, data, optimisticId },
});

export const updateSetListSongOptimistically = (id, data, optimisticId) => ({
  type: UPDATE_SET_LIST_SONG_OPTIMISTICALLY,
  payload: { id, data, optimisticId },
});

export const deleteSetListSongOptimistically = (setListSongId, optimisticId) => ({
  type: DELETE_SET_LIST_SONG_OPTIMISTICALLY,
  payload: { setListSongId, optimisticId },
});

export const confirmSetListSongUpdate = (optimisticId, data) => ({
  type: CONFIRM_SET_LIST_SONG_UPDATE,
  payload: { optimisticId, data },
});

export const revertSetListSongUpdate = (optimisticId) => ({
  type: REVERT_SET_LIST_SONG_UPDATE,
  payload: { optimisticId },
});

// Action Creators for Set List Group Management
export const createSetListGroup = (title, description, setListId) => ({
  type: CREATE_SET_LIST_GROUP,
  payload: { title, description, setListId },
});

export const setSetListGroupData = (data) => ({
  type: SET_SET_LIST_GROUP_DATA,
  payload: { data },
});

export const updateSetListGroup = (id, data) => ({
  type: UPDATE_SET_LIST_GROUP,
  payload: { id, data },
});

export const addNewSetListGroup = (title, description) => ({
  type: ADD_NEW_SET_LIST_GROUP,
  payload: { title, description },
});

// Action Creators for Set List Management
export const loadSetList = (setListId) => ({
  type: LOAD_SET_LIST,
  payload: { setListId },
});

export const updateSetList = (updateDto, optimistic = true) => ({
  type: UPDATE_SET_LIST,
  payload: {
    ...updateDto,
    id: updateDto.setListId
  },
  meta: { optimistic }
});
export const setSetListData = (data) => ({
  type: SET_SET_LIST_DATA,
  payload: { data },
});

export const setLoadingSetList = (namespace, loading, id = null) => ({
  type: SET_LOADING_SET_LIST,
  payload: { namespace, loading, id },
});

export const removeSetListData = (namespace, id) => ({
  type: REMOVE_SET_LIST_DATA,
  payload: { namespace, id },
});

// Action Creators for Spotify Playlist Import
export const importSpotifyPlaylist = (playlistId, setListGroupId) => ({
  type: IMPORT_SPOTIFY_PLAYLIST,
  payload: { playlistId, setListGroupId },
});

// Action Creators for Set List Song Data Management
export const setSetlistSongData = (data) => ({
  type: SET_SET_LIST_SONG_DATA,
  payload: { data },
});

// Add any additional action creators as needed for
