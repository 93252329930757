import React, { useEffect, useState } from 'react';
import { Button,  TextInput, Group } from '@mantine/core';
import { useClipboard, } from '@mantine/hooks';
import { Calendar } from '../../components/Calendar/Calendar';
import { useSelector, useDispatch } from 'react-redux';
import { GigForm } from './GigForm';
import { fetchGigs } from '../../redux/calendar/actions';
import { RootState } from '../../redux';

// Assume the iCalUrl is generated on the backend and passed to the frontend
// Here we're setting a static URL for demonstration purposes

export const BandCalendar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);  
  const gigs = useSelector((state: RootState) => state.calendar.gigs);
  const profile = useSelector((state: RootState) => state.profile.profile);
  const iCalUrl = `https://app.bandhouse.io/cal/${profile?.id}.ics`;

  const [todaysDate] = useState(new Date());
  const [newGigEventData, setNewGigEventData] = useState<null | any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { copy, copied } = useClipboard({ timeout: 2000 });
  const [startDate, setStartDate] = useState(new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(todaysDate.getFullYear(), todaysDate.getMonth() + 1, 0));
  const events = gigs.filter((item:any) => item.profileId === user.activeProfileId).map((gig: any) => {



    return {
        id: gig.id, 
        start: gig.startDate,
        end: gig.endDate, 
        title: gig.name, 
        description: gig.description
    }
  }) ?? [];

  useEffect(() => {
    if(profile){
      dispatch(fetchGigs(startDate.toISOString(), endDate.toISOString()));
    }
  }, [startDate, endDate, profile?.id]);

  const handleDateClick = (arg) => {
    setNewGigEventData(arg)
    setModalOpen(true);
  };

 

  const handleRangeChange = ({start, end}) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
    <h1>{profile?.name}&apos;s Calendar</h1>
      <Calendar events={events} onDateClick={handleDateClick} onRangeChange={handleRangeChange} />
      <Group mt="md">
        <TextInput value={iCalUrl} readOnly />
        <Button onClick={() => copy(iCalUrl)}>
          {copied ? 'Copied' : 'Copy URL'}
        </Button>
      </Group>

      {profile && <GigForm profileId={profile.id} newGigEventData={newGigEventData} open={modalOpen} toggle={setModalOpen} /> }
    </>
  );
};

