import { Slider, ActionIcon } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconCircleCaretRight,
  IconPlayerPauseFilled,
} from "@tabler/icons-react";
import { pauseAudio, playAudio, setPlaybackTime } from "../../redux/audio-player/actions";
import { RootState } from "../../redux";

export const AudioPlayer = () => {
  const [elapsed, setElapsed] = useState(0);
  const [choosing, setChoosing] = useState(false);
  const dispatch = useDispatch();
  const { isPlaying, currentTime, duration, artwork } = useSelector((state: RootState) => state.audioPlayer);

  const handleSliderDrag = (value: number) => {
    setElapsed(value);
    //
  };
  const handleChoose = () => {
    if (isPlaying) {
      setChoosing(true);
      dispatch(pauseAudio())
    }
  };
  const handleChooseEnd = () => {
    const newTime = (elapsed / 100) * duration;
    dispatch(setPlaybackTime(newTime));

    if (choosing) {
      dispatch(playAudio());
      setChoosing(false);
    }
  };
  useEffect(() => {
    if (!choosing) {
      setElapsed(Math.floor((currentTime / duration) * 100));
    }
  }, [currentTime, duration]);

  // ...state and functions to control audio playback
  const currentTimeMinutes = useMemo(() => {
    const minutes = Math.floor(currentTime / 60);
    return minutes < 10 ? `0${minutes}` : minutes;
  }, [currentTime]);
  const currentTimeSeconds = useMemo(() => {
    const seconds = Math.floor(currentTime % 60);
    return seconds < 10 ? `0${seconds}` : seconds;
  }, [currentTime]);
  const durationMinutes = useMemo(() => {
    const minutes = Math.floor(duration / 60);
    return minutes < 10 ? `0${minutes}` : minutes;
  }, [duration]);
  const durationSeconds = useMemo(() => {
    const seconds = Math.floor(duration % 60);
    return seconds < 10 ? `0${seconds}` : seconds;
  }, [duration]);
  const handleButtonClick = () => {
    if(isPlaying){
      dispatch(pauseAudio());
    }
    else{
      dispatch(playAudio());
    }
  }
  return (
   <div style={{display: 'flex', alignItems: 'center', padding: '8px'}}>      <ActionIcon
        onClick={() => handleButtonClick()}
        mr={8}
        size="lg"
      >
        {/* Conditionally render Play or Pause icon <Player size={16}/>*/}{" "}
        {isPlaying ? (
          <IconPlayerPauseFilled />
        ) : (
          <IconCircleCaretRight />
        )}
      </ActionIcon>
      <span style={{marginRight: '8px'}}>
        {currentTimeMinutes}:{currentTimeSeconds}/{durationMinutes}:
        {durationSeconds}
      </span>
      <Slider
        thumbChildren={
          <img
            style={{
              height: "400%",
              borderRadius: "12px",
              border: "1px solid white",
            }}
            src={artwork}
          />
        }
        onChange={handleSliderDrag}
        onMouseDown={handleChoose}
        onMouseUp={handleChooseEnd}
        value={elapsed}
        /* Slider props */ style={{ flexGrow: 1 }}
      />
      {/* Other controls like volume, track info, etc. */}
      </div>

  );
};
