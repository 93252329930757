// chatWebSocketSaga.js
import { takeEvery, put } from 'redux-saga/effects';
import {
  LIST_CHAT_THREADS,
  LOAD_CHAT_THREAD,
  SEND_CHAT_MESSAGE,
} from './actions';
import { sendWebsocketMessage } from '../websocket/actions';

function* handleListChatThreads() {
  yield put(sendWebsocketMessage('@CHAT/LIST_THREADS', {}));
}

function* handleLoadChatThread(action) {
  yield put(sendWebsocketMessage('@CHAT/LOAD_THREAD', { chatId: action.payload }));
}

function* handleSendChatMessage(action) {
  yield put(sendWebsocketMessage('@CHAT/SEND_MESSAGE', { message: action.payload.message, chatId: action.payload.chatId }));
}

export default function* chatSaga() {
  yield takeEvery(LIST_CHAT_THREADS, handleListChatThreads);
  yield takeEvery(LOAD_CHAT_THREAD, handleLoadChatThread);
  yield takeEvery(SEND_CHAT_MESSAGE, handleSendChatMessage);
}
