import React, { useState } from "react";
import {
  Button,
  Paper,
  Title,
  Container,
  TextInput,
} from "@mantine/core";
import { useDispatch } from 'react-redux';
import { changePassword } from '../../redux/auth/actions'; // Import the change password action

export const ChangePassword = () => {
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const onSubmit = async (event: any) => {
    event.preventDefault();
    dispatch(changePassword(currentPassword, newPassword, confirmNewPassword));
  };

  return (
    <Container size={400} style={{ margin: "50px auto", marginTop: 40 }}>
      <Paper>
        <Title order={4}>Change Password</Title>
        <form onSubmit={onSubmit}>
          <TextInput
            value={currentPassword}
            onChange={(event) => setCurrentPassword(event.target.value)}
            type="password"
            label="Current Password"
            placeholder="Enter current password"
          />
          <TextInput
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            type="password"
            label="New Password"
            placeholder="Enter new password"
          />
          <TextInput
            value={confirmNewPassword}
            onChange={(event) => setConfirmNewPassword(event.target.value)}
            type="password"
            label="Confirm New Password"
            placeholder="Confirm new password"
          />
          <Button style={{ marginTop: 20 }} type="submit">
            Change Password
          </Button>
        </form>
      </Paper>
    </Container>
  );
};
