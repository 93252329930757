import { Gig } from '../../Api';
import { LOGOUT_SUCCESS } from '../auth/actions';
import {FETCH_GIGS, FETCH_GIGS_SUCCESS, FETCH_GIGS_FAILURE, CREATE_GIG_SUCCESS, FETCH_GIG, FETCH_GIG_SUCCESS, FETCH_GIG_FAILURE, UPDATE_GIG, UPDATE_GIG_SUCCESS, CLEAR_CREATE_GIG_RESULT} from './actions';
const initialState: {
  gigs: Gig[],
  createGigResult: Gig | null,
  loading: boolean,
  error: any
} = {
    gigs: [],
    createGigResult: null,
    loading: false,
    error: null,
  };
  
  const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGOUT_SUCCESS:
        return initialState;
      case FETCH_GIG: 
      case FETCH_GIGS:
      case UPDATE_GIG:
        return { ...state, loading: true };
      case UPDATE_GIG_SUCCESS:
      case FETCH_GIG_SUCCESS: {
        // if gig is already in state, replace it, otherwise add it
        const gigIndex = state.gigs.findIndex((gig: any) => gig.id === action.payload.id);
        if(gigIndex > -1){
          const gigs: any[] = [...state.gigs];
          gigs[gigIndex] = action.payload;
          return { ...state, loading: false, gigs };
        }
        return { ...state, loading: false, gigs: [...state.gigs, action.payload] };
      }
      case FETCH_GIG_FAILURE: {
        return { ...state, loading: false, error: action.payload };
      }
      case FETCH_GIGS_SUCCESS:
        return { ...state, loading: false, gigs: action.payload };
      case FETCH_GIGS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case CREATE_GIG_SUCCESS:
        return { ...state, gigs: [...state.gigs, action.payload], createGigResult: action.payload };
      case CLEAR_CREATE_GIG_RESULT: 
        return { ...state, createGigResult: null };
  
      default:
        return state;
    }
  };
  
  export default calendarReducer;
  