import { all } from 'redux-saga/effects';
import auth from './auth/saga';
import spotify from './spotify/saga';
import audioPlayer from './audio-player/saga';
import { type Api } from '../Api';
import websocketSaga from './websocket/saga';
import setListSaga from './set-list/saga';
import calendarSaga from './calendar/saga';

import chatSaga from './chat/saga';
import dashboardSaga from './dashboard/saga';
import invoiceSaga from './invoice/saga';
import todoSaga from './todos/saga';
import profileSaga from './profile/saga';
import repertoireSaga from './repertoire/saga';

export default function* rootSaga(api: Api<any>) {
    // Pass the api instance to child sagas
    yield all([
        auth(api), 
        spotify(api),
        audioPlayer(),
        websocketSaga(),
        setListSaga(api),
        calendarSaga(api),
        chatSaga(),
        dashboardSaga(api),
        invoiceSaga(api),
        todoSaga(api), 
        profileSaga(api),
        repertoireSaga(api),
    ]);
}