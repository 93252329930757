import React, { useEffect, useState } from 'react';
import { Button, Fieldset, TextInput, Textarea } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { updateSetList } from '../../../redux/set-list/actions';
export const SetListDetails =  ({setList}: any) => {  
  const [title, setTitle] = useState(setList?.title);
  const [description, setDescription] = useState(setList?.description);
  useEffect(() => {
    setTitle(setList?.title);
    setDescription(setList?.description);
  }, [setList?.title, setList?.description])
    const dispatch = useDispatch();
     
    const handleSubmit = (event) => {
      event.preventDefault()
      const title = event.target[0].value;
      const description = event.target[1].value;
      dispatch(updateSetList({
        setListId: setList.id,
        title,
        description
      }))
    }

    return (
        <Fieldset legend="Set list details">
          <form onSubmit={handleSubmit}>
          <TextInput value={title} label="Name" onChange={event => setTitle(event.target.value)}/>
          <Textarea value={description} label="Description" mt="md" onChange={event => setDescription(event.target.value)} />
          <Button mt={8} type="submit">Update</Button>
          </form>
        </Fieldset>
      );
}