import { SetList, SetListGroup, SetListSong } from '../../Api';
import { LOGOUT_SUCCESS } from '../auth/actions';
import {
  CREATE_SET_LIST_SONG_OPTIMISTICALLY,
  UPDATE_SET_LIST_SONG_OPTIMISTICALLY,
  DELETE_SET_LIST_SONG_OPTIMISTICALLY,
  CONFIRM_SET_LIST_SONG_UPDATE,
  REVERT_SET_LIST_SONG_UPDATE,
  SET_SET_LIST_GROUP_DATA,
  UPDATE_SET_LIST_GROUP,
  ADD_NEW_SET_LIST_GROUP,
  LOAD_SET_LIST,
  UPDATE_SET_LIST,
  IMPORT_SPOTIFY_PLAYLIST,
  SET_SET_LIST_DATA,
  SET_LOADING_SET_LIST,
  REMOVE_SET_LIST_DATA,
  SET_SET_LIST_SONG_DATA,
  REORDER_SET_LIST_SONGS,
  SET_SET_LIST_SONG_ORDER,
  CONFIRM_SET_LIST_SONG_CREATE,
  UPDATE_SET_LIST_SONG,
  CREATE_SET_LIST_SUCCESS,
  LIST_SET_LISTS_SUCCESS,
  SET_CURRENT_GROUP
  // Other imported action types
} from './actions';

const initialState: {
  setListSongs: SetListSong[]
  setListGroups: SetListGroup[]
  setLists: SetList[]
  loading: {[key: string]: boolean},
  currentGroup: string | null
} = {
  setListSongs: [],
  setListGroups: [],
  setLists: [],
  loading: {},
  currentGroup: null
  // Other initial state properties
};

const setListReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case LIST_SET_LISTS_SUCCESS: {
      return {
        ...state,
        setLists: action.payload
      };
    }
    case CREATE_SET_LIST_SUCCESS: {
      return {
        ...state,
        setLists: [action.payload, ...state.setLists]
      };
    }
    case CREATE_SET_LIST_SONG_OPTIMISTICALLY: {
      return {
        ...state,
        setListSongs: [...state.setListSongs, { ...action.payload.data, optimisticId: action.payload.optimisticId, optimisticPending: true }]
      };
    }
    case UPDATE_SET_LIST_SONG_OPTIMISTICALLY: {
      return {
        ...state,
        setListSongs: state.setListSongs.map((song:any) =>
          song.optimisticId === action.payload.optimisticId
            ? { ...song, ...action.payload.data }
            : song
        )
      };
    }
    case DELETE_SET_LIST_SONG_OPTIMISTICALLY: {
      return {
        ...state,
        setListSongs: state.setListSongs.filter((song:any) => song.optimisticId !== action.payload.optimisticId)
      };
    }
    case CONFIRM_SET_LIST_SONG_UPDATE: {
      return {
        ...state,
        setListSongs: state.setListSongs.map((song:any) =>
          song.optimisticId === action.payload.optimisticId
            ? { ...song, ...action.payload.data, optimisticPending: false }
            : song
        )
      };
    }
    case UPDATE_SET_LIST_SONG: {
      return {
        ...state,
        setListSongs: state.setListSongs.map((song:any) =>
          song.id === action.payload.id
            ? { ...song, ...action.payload.data }
            : song
        )
      };
    }
    case CONFIRM_SET_LIST_SONG_CREATE: {
      if(!action.payload.optimisticId) {
        const index = state.setListSongs.findIndex((song:any) => song.id === action.payload.id);
        if(index === -1){
          return {
            ...state,
            setListSongs: [...state.setListSongs, action.payload]
          };
        }
        return {
          ...state,
          setListSongs: state.setListSongs.map((song:any) =>
            song.id === action.payload.id
              ? { ...song, ...action.payload, optimisticPending: false }
              : song
          )
        };
      }
      
      const index = state.setListSongs.findIndex((song:any) => song.optimisticId === action.payload.optimisticId);
      if(index === -1){
        return {
          ...state,
          setListSongs: [...state.setListSongs, action.payload]
        };
      }
      return {
        ...state,
        setListSongs: state.setListSongs.map((song:any) =>
          song.optimisticId === action.payload.optimisticId
            ? { ...song, ...action.payload, optimisticPending: false }
            : song
        )
      };

    }
    case SET_CURRENT_GROUP: {
      return {
        ...state,
        currentGroup: action.payload.groupId
      };
    }
    case REVERT_SET_LIST_SONG_UPDATE: {
      return {
        ...state,
        setListSongs: state.setListSongs.filter((song:any) => song.optimisticId !== action.payload.optimisticId)
      };
    }
    
    case SET_SET_LIST_GROUP_DATA: {
      // action.payload.data is an array of set list group data, replace by id if exists or add if not
      const incomingData = action.payload.data;
      const existingSetListGroups = state.setListGroups;
    
      // Create a map from the existing set list groups for quick lookup
      const setListGroupMap = new Map(existingSetListGroups.map(item => [item.id, item]));
    
      // Update the map with incoming data or add new items
      incomingData.forEach(setListGroup => {
        const existingItem = setListGroupMap.get(setListGroup.id);
        if (existingItem) {
          // If exists, merge the existing item with the incoming data
          setListGroupMap.set(setListGroup.id, { ...existingItem, ...setListGroup });
        } else {
          // If not exists, add the new item to the map
          setListGroupMap.set(setListGroup.id, setListGroup);
        }
      });
    
      // Convert the map back to an array
      const updatedSetListGroups = Array.from(setListGroupMap.values());
    
      console.log({updatedSetListGroups});
    
      return {
        ...state,
        setListGroups: updatedSetListGroups
      };
    }
    case UPDATE_SET_LIST_GROUP: {
      return {
        ...state,
        setListGroups: state.setListGroups.map((group:any) =>
          group.id === action.payload.id
            ? { ...group, ...action.payload.data }
            : group
        )
      };
    }
    case ADD_NEW_SET_LIST_GROUP: {
      return {
        ...state,
        setListGroups: [...state.setListGroups, action.payload]
      };
    }
    case LOAD_SET_LIST: {
      // Logic to handle loading set list
      return state;
    }
    case UPDATE_SET_LIST: {
      return {
        ...state,
        setLists: state.setLists.map((setList: any) => 
          setList.id === action.payload.id 
            ? { ...setList, ...action.payload } 
            : setList
        )
      };
    
      return state;
    }
    case IMPORT_SPOTIFY_PLAYLIST: {
      // Logic to handle Spotify playlist import
      return state;
    }
    case SET_SET_LIST_DATA: {
      // payload.data is an array of set list data, replace by id if exists or add if not
      const data = action.payload.data;
      const setLists = state.setLists;
      const newSetLists = data.map((setList:any) => {
        const existingSetList = setLists.find((existingSetList: any) => existingSetList.id === setList.id);
        return existingSetList ? { ...existingSetList, ...setList } : setList;
      });

      return {
        ...state,
        setLists: [...newSetLists]
      };
    }
    case SET_LOADING_SET_LIST: {
      return {
        ...state,
        loading: { ...state.loading, [action.payload.namespace]: action.payload.loading }
      };
    }
    case REMOVE_SET_LIST_DATA: {
      // Logic to remove set list data
      return state;
    }
 case REORDER_SET_LIST_SONGS: {
  // action.payload of songs contains an array of {id: 'uuid', order: 1}
  const songs = action.payload.songs;
  const setListSongs = state.setListSongs;

  // Map over the existing setListSongs to apply updates
  const updatedSetListSongs = setListSongs.map(existingSetListSong => {
    // Find the corresponding song update by id
    const songUpdate = songs.find(song => song.id === existingSetListSong.id);
    if (songUpdate) {
      // Update the order if an update is found
      return { ...existingSetListSong, order: songUpdate.order };
    }
    // If no update is found, return the song as is
    return existingSetListSong;
  });

  return {
    ...state,
    setListSongs: updatedSetListSongs
  };
}

    case SET_SET_LIST_SONG_ORDER: {
      // action.payload.data contains an array of {id: 'uuid', order: 1}
      const songs = action.payload.data;
      const setListSongs = state.setListSongs;
      console.log({setListSongs})
      const updatedSetListSongs = setListSongs.map(existingSetListSong => {
        // Find if there is an update for this song in the payload
        const songUpdate = songs.find(song => song.id === existingSetListSong.id);
        if (songUpdate) {
          // If found, return a new object with updated order
          return { ...existingSetListSong, order: songUpdate.order };
        }
        // If not found, return the song as is
        return existingSetListSong;
      });
      console.log({updatedSetListSongs})
    
      return {
        ...state,
        setListSongs: updatedSetListSongs
      };
    }
    
    case SET_SET_LIST_SONG_DATA: {
      // payload.data is an array of set list song data, replace by id if exists or add if not
      const data = action.payload.data;
      const setListSongs = state.setListSongs;
      const newSetListSongs = data.map((setListSong:any) => {
        const existingSetListSong = setListSongs.find((existingSetListSong: any) => existingSetListSong.id === setListSong.id);
        return existingSetListSong ? { ...existingSetListSong, ...setListSong } : setListSong;
      });
      return {
        ...state,
        setListSongs: [...newSetListSongs]
      };
      
    }
    // Add cases for other actions as needed
    default:
      return state;
  }
};

export default setListReducer;
