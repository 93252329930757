import React, { type PropsWithChildren, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { AppShell, Burger, Group, Title, Text, Indicator } from "@mantine/core";
import { Navbar } from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { UserButton } from "../UserButton/UserButton";
import { CreateBand } from "../Band/CreateBand";
import { AudioPlayer } from "../AudioPlayer/AudioPlayer";
import { Chat } from "../Chat/Chat";
import { RootState } from "../../redux";

export function AuthenticatedView({ children }: PropsWithChildren) {
  const [showCreateBand, setShowCreateBand] = useState(false);
  const showCreatePersonalProfileModal = useSelector((state: RootState) => state.profile.showCreatePersonalProfileModal);

  const [navbarOpen, { toggle: toggleNavbar }] = useDisclosure();
  // const [messengerOpen, { toggle: toggleMessenger }] = useDisclosure();
  const [messengerOpen] = useDisclosure();

  return (
      <AppShell
      pb={50}
        header={{ height: 50 }}
        navbar={{
          width: 200,
          breakpoint: "sm",
          collapsed: { mobile: !navbarOpen },
        }}
        aside={{
          breakpoint: "sm",
          collapsed: {  mobile: !messengerOpen, desktop: !messengerOpen },
          width: 300
        }}
        footer={{
          height: 50,
        }}
        padding="md"
      >
        <AppShell.Header>
         
          <Group justify="space-between" h="100%">
            <Group h="100%">
            <Indicator processing label="Beta" position="bottom-end">

            <Title ml={8} order={2}>
              <Link style={{ textDecoration: 'none' }} to="/">
            <Text inherit variant="gradient" component="span" gradient={{ from: 'pink', to: 'yellow' }} style={{fontWeight:700, letterSpacing: '4px'}}>
               BANDHOUSE</Text>
               </Link>
               </Title>
               </Indicator>
              <Burger
                opened={navbarOpen}
                onClick={toggleNavbar}
                hiddenFrom="sm"
                size="sm"
              />
            </Group>

            <Group>
              {/* <Button size="compact-xs" onClick={() => setShowCreateBand(true)}>
                Create a band
              </Button>
              <NotificationsButton/>
              <Indicator disabled={!toggleMessenger}>
            <UnstyledButton variant="transparent" onClick={toggleMessenger}>
                <IconMessage/>
            </UnstyledButton> 

        </Indicator>          */}    
        <UserButton />
            </Group>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <Navbar />
        </AppShell.Navbar>
        <AppShell.Main
          style={{ backgroundColor: "var(--mantine-color-gray-0)" }}
        >
          {children}
        </AppShell.Main>
        <AppShell.Aside p="md">
         <Chat/>
        </AppShell.Aside>
        <CreateBand
          opened={showCreatePersonalProfileModal || showCreateBand}
          onClose={() => setShowCreateBand(false)}
        />
      
      
          <AppShell.Footer>
          <AudioPlayer />
          </AppShell.Footer>
      </AppShell>
  );
}
