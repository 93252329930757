import { call, fork, put, select, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { CHANGE_PROFILE, CHECK_SLUG, CREATE_PERSONAL_PROFILE, CREATE_PROFILE, GET_PROFILES, GET_PROFILES_ERROR, GET_PROFILES_SUCCESS, UPDATE_PROFILE, UPDATE_USER_LOCATION, changeProfileSuccess, checkSlugError, checkSlugSuccess, createPersonalProfileError, createPersonalProfileSuccess, createProfileError, createProfileSuccess, loadPersonalProfileError, loadPersonalProfileSuccess, setProfile, toggleCreatePersonalModal, toggleCreateProfileModal, updateProfileError, updateProfileSuccess } from "./actions";
import { RootState } from "..";
import { changePasswordFailure, updateAccount } from "../auth/actions";
import { sendWebsocketMessage } from "../websocket/actions";
function* getProfiles(api: Api<any>, action) {
    try {
        const profiles = yield call(api.profile.getManyBaseProfileControllerProfile, action.payload);
        yield put({ type: GET_PROFILES_SUCCESS, payload: profiles.data });
    } catch (error:any ) {
        yield put({ type: GET_PROFILES_ERROR, payload: error.message });
    }
}
function* changeProfile(api: Api<any>, action ){
    const profileId = action.payload;
    const user = yield select((state:RootState) => state.auth.user);
    const profiles = yield select((state:RootState) => state.profile.profiles);

    const profile = profiles.find((profile: any) => profile.id === profileId)
    try {
        yield localStorage.setItem(
            "activeProfile",
            JSON.stringify(profile)
        );
        yield put(updateAccount(user.id, {activeProfileId : profileId}));
        yield put(setProfile(profile))
        yield put(changeProfileSuccess(profile))
    } catch(error: any){
        yield put(changePasswordFailure(error.message));
    }
}
function* updateProfile(api: Api<any>, action) {
    try {
        const updatedProfile = yield call(api.profile.updateOneBaseProfileControllerProfile, action.payload.id, action.payload.data);
        yield put(updateProfileSuccess(updatedProfile));
    } catch (error: any) {
        yield put(updateProfileError(error.message));
    }
}
function* checkSlug(api: Api<any>, action) {
    try {
        const response = yield call(api.profile.profileControllerCheckSlug, {slug: action.payload});
        yield put(checkSlugSuccess(response.data));
    } catch (error: any) {
        yield put(checkSlugError(error.message));
    }
}
function* createPersonalProfile(api: Api<any>, action) {
    try {
        const user = yield select((state: RootState) => state.auth.user);
        action.payload.profileType = 'INDIVIDUAL';
        const profile = yield call(api.profile.createOneBaseProfileControllerProfile, action.payload);
        yield put(createPersonalProfileSuccess(profile));
        yield put(toggleCreatePersonalModal(false));
        yield put(updateAccount(user.id, { activeProfileId: profile.id }));
    } catch (error: any) {
        yield put(createPersonalProfileError(error.message));
    }
}
function* createProfile(api: Api<any>, action) {
    try {
        const profile = yield call(api.profile.createOneBaseProfileControllerProfile, action.payload);
        yield put(createProfileSuccess(profile));
        yield put(toggleCreateProfileModal(false));
    } catch (error: any) {
        yield put(createProfileError(error.message));
    }
}
function* updateUserLocation(action){
    yield put(sendWebsocketMessage('@PROFILE/UPDATE_LOCATION', action.payload))
}
function* initProfile(api: Api<any>){
    const authenticated = select((state: RootState) => state.auth.authenticated);
    if(!authenticated){
        return;
    }
    const localProfile = yield localStorage.getItem("activeProfile");
    console.log({localProfile})
    if(localProfile){
        const profile = JSON.parse(localProfile);
        yield put(setProfile(profile));            
        return put(loadPersonalProfileSuccess(profile));

    } else {
        try {
            const personalProfile = yield call(api.profile.profileControllerGetPersonalProfile);
            console.log('got personal profile', personalProfile)
            if(!personalProfile.data){
                yield put(toggleCreatePersonalModal(true));
                return;
            }
            yield put(setProfile(personalProfile.data.profile));
            return put(loadPersonalProfileSuccess(personalProfile.data.profile));
        } catch(err: any){
            if(err.code === 404){
                yield put(toggleCreatePersonalModal(true));
            } else {
                yield put(loadPersonalProfileError(err.message))
            }
        }

    }
    yield put(toggleCreatePersonalModal(true));
}
export default function* profileSaga(api: Api<any>) {
    yield fork(initProfile, api);
    yield takeEvery(GET_PROFILES, getProfiles, api);
    yield takeEvery(CHANGE_PROFILE, changeProfile, api);
    yield takeEvery(UPDATE_PROFILE, updateProfile, api);
    yield takeEvery(CREATE_PERSONAL_PROFILE, createPersonalProfile, api);
    yield takeEvery(CHECK_SLUG, checkSlug, api);
    yield takeEvery(UPDATE_USER_LOCATION, updateUserLocation);
    yield takeEvery(CREATE_PROFILE, createProfile, api);

}