import { Button, Modal, Table } from "@mantine/core";
import { useEffect,  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSpotifyPlaylists, importSpotifyPlaylist } from "../../redux/spotify/actions";
import { RootState } from "../../redux";


export const ImportSpotifyPlaylist = ({opened, toggle, setListGroupId }: any ) => {
    const dispatch = useDispatch();
    const playlists = useSelector((state: RootState) => state.spotify.playlists);
    useEffect(() => {
        if(opened){
            dispatch(fetchSpotifyPlaylists())
        }

    }, [opened]);
    const importPlaylist = async (playlist:any) => {
        dispatch(importSpotifyPlaylist(playlist.id, setListGroupId))
        toggle(false);
    };
    return <Modal
        opened={opened}
        onClose={() => toggle(false)}
        fullScreen
        radius={0}
        transitionProps={{ transition: "fade", duration: 200 }}
    >

<Table>
    <Table.Thead>
        <Table.Tr>
            <Table.Th></Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th></Table.Th>
        </Table.Tr>
    </Table.Thead>
    <Table.Tbody>
        {playlists.map((playlist) => {
            return <Table.Tr key={playlist.id}>
                <Table.Td><img style={{height:'30px'}} src={playlist.images[0].url} alt={playlist.name} /></Table.Td>
                <Table.Td>{playlist.name}</Table.Td>
                <Table.Td><Button onClick={() => importPlaylist(playlist)}>Import</Button></Table.Td>
            </Table.Tr>
        })}
    </Table.Tbody>
</Table>



     </Modal>
};