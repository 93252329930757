import { useEffect, useMemo } from "react";
import {
  Button,
  Card,
  Group,
  Modal,
  Space,
  Table,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSetList, listSetLists } from "../../redux/set-list/actions";
import { RootState } from "../../redux";
export const SetLists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setLists = useSelector((state: RootState) => state.setList.setLists);
  const [opened, { open, close }] = useDisclosure(false);
  const rows = useMemo(() => {

    return setLists.map((element: any) => (
      <Table.Tr key={element.id}>
        <Table.Td>{element.title}</Table.Td>
        <Table.Td>{element.description}</Table.Td>
        <Table.Td>
          <Button onClick={() => navigate(`/set-list/${element.id}`)}>
            Open
          </Button>
        </Table.Td>
      </Table.Tr>
    ));
  }, [setLists]);
  const form = useForm({
    initialValues: {
      title: "",
      description: "",
    },
    validate: {},
  });
  useEffect(() => {
    dispatch(listSetLists())
  }, []);
  const handleCloseModal = () => {
    form.reset();
    close();
  };
  const  handleCreateSetList = () => {
    dispatch(createSetList(form.values.title, form.values.description, true));
  };
  return (
    <div>
      <Modal opened={opened} onClose={handleCloseModal} title="Create Set List">
        <form onSubmit={form.onSubmit(handleCreateSetList)}>
          <TextInput
            withAsterisk
            required
            label="Name"
            {...form.getInputProps("title")}
          />

          <Textarea
            label="Description"
            {...form.getInputProps("description")}
          ></Textarea>

          <Group justify="flex-end" mt="md">
            <Button type="submit">Submit</Button>
          </Group>
        </form>{" "}
      </Modal>

      <Card>
          <Button onClick={open}>Create</Button>
        <Space h="md" />

          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Description</Table.Th>
                <Table.Th></Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
      </Card>
    </div>
  );
};
