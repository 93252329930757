export const FETCH_GIGS = '@GIGS/FETCH_GIGS';
export const FETCH_GIGS_SUCCESS = '@GIGS/FETCH_GIGS_SUCCESS';
export const FETCH_GIGS_FAILURE = '@GIGS/FETCH_GIGS_FAILURE';
export const FETCH_GIG = '@GIGS/FETCH_GIG';
export const FETCH_GIG_SUCCESS = '@GIGS/FETCH_GIG_SUCCESS';
export const FETCH_GIG_FAILURE = '@GIGS/FETCH_GIG_FAILURE';
export const CREATE_GIG = '@GIGS/CREATE_GIG';
export const CREATE_GIG_SUCCESS = '@GIGS/CREATE_GIG_SUCCESS';
export const UPDATE_GIG = '@GIGS/UPDATE_GIG';
export const UPDATE_GIG_SUCCESS = '@GIGS/UPDATE_GIG_SUCCESS';
export const UPDATE_GIG_FAILURE = '@GIGS/UPDATE_GIG_FAILURE';
export const CLEAR_CREATE_GIG_RESULT = '@GIGS/CLEAR_CREATE_GIG_RESULT';


export const clearCreateGigResult = () => ({
    type: CLEAR_CREATE_GIG_RESULT,
  });
export const fetchGig = id => ({
    type: FETCH_GIG,
    payload: id,
  });
  
  export const fetchGigSuccess = gig => ({
    type: FETCH_GIG_SUCCESS,
    payload: gig,
  });
  
  export const fetchGigFailure = error => ({
    type: FETCH_GIG_FAILURE,
    payload: error,
  });

export const createGigSuccess = gig => ({
    type: CREATE_GIG_SUCCESS,
    payload: gig,
  });
export const fetchGigs = (startDate, endDate, personal = false) => ({
    type: FETCH_GIGS,
    payload: { startDate, endDate, personal },
  });
  
  export const fetchGigsSuccess = gigs => ({
    type: FETCH_GIGS_SUCCESS,
    payload: gigs,
  });
  
  export const fetchGigsFailure = error => ({
    type: FETCH_GIGS_FAILURE,
    payload: error,
  });
  
  export const createGig = (data, navigate = false, createInvoice = false) => ({
    type: CREATE_GIG,
    payload: { data, navigate, createInvoice},
  });
  
  export const updateGig = (data, id) => ({
    type: UPDATE_GIG,
    payload: {id, data},
  });
  export const updateGigSuccess = gig => ({
    type: UPDATE_GIG_SUCCESS,
    payload: gig,
  });
  export const updateGigFailure = error => ({
    type: UPDATE_GIG_FAILURE,
    payload: error,
  });