// chatActions.js
export const LIST_CHAT_THREADS = '@CHAT/LIST_THREADS';
export const LOAD_CHAT_THREAD = '@CHAT/LOAD_THREAD';
export const SEND_CHAT_MESSAGE = '@CHAT/SEND_MESSAGE';
export const RECEIVE_CHAT_MESSAGE = '@CHAT/RECEIVE_MESSAGE';
export const RECEIVE_THREAD_LIST = '@CHAT/RECEIVE_THREAD_LIST';
export const RECEIVE_THREAD_MESSAGES = '@CHAT/RECEIVE_THREAD_MESSAGES';
export const CLASSIFY_MESSAGE = '@CHAT/CLASSIFY_MESSAGE';

export const listChatThreads = () => ({ type: LIST_CHAT_THREADS });
export const loadChatThread = (chatId) => ({ type: LOAD_CHAT_THREAD, payload: chatId });
export const sendChatMessage = (message, chatId) => ({ type: SEND_CHAT_MESSAGE, payload: { message, chatId } });
export const receiveChatMessage = (message) => ({ type: RECEIVE_CHAT_MESSAGE, payload: message });
export const receiveThreadList = (threads) => ({ type: RECEIVE_THREAD_LIST, payload: threads });
export const receiveThreadMessages = (messages) => ({ type: RECEIVE_THREAD_MESSAGES, payload: messages });


export const classifyMessage = (classification) => ({ type: CLASSIFY_MESSAGE, payload: classification });
