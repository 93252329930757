export const getApiUrl = () => {
    const protocol = 'https';
    let baseUrl = 'local.app.bandhouse.io/api';
    if (process.env.NODE_ENV === 'production') {
        baseUrl = process.env.REACT_APP_API_URL as string;
    }
    return `${protocol}://${baseUrl}`;
}


export const getWsApiUrl = () => {
    const protocol = 'wss';
    let baseUrl = 'local.app.bandhouse.io';
    if (process.env.NODE_ENV === 'production') {
        baseUrl = process.env.REACT_APP_API_URL as string;
    }
    return `${protocol}://${baseUrl}`;
}
