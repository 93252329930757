/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { Avatar, Card, Grid } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { loadDashboard } from "../../redux/dashboard/actions";
import { RootState } from "../../redux";
const Home = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state:RootState) => state.profile.profile); 
  
  useEffect(() => {
    dispatch(loadDashboard());
  }, []);
  
  return (
<Grid>
  <Grid.Col span={12}>
  <Card shadow="sm" padding="lg" radius="md" withBorder>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar />
        
        <h1 style={{ marginLeft: "10px" }}>Welcome, {profile?.name}!</h1>
      </div>
    </Card>
    
  </Grid.Col>
  {/* <Grid.Col span={6}>
  <GigStats dashboard={dashboard} />

  </Grid.Col> */}
  <Grid.Col span={12}>
  <Card shadow="sm" padding="lg" radius="md" withBorder>

    <p>
    Welcome aboard! We're thrilled to have you join our community of talented musicians. Our platform is designed to streamline your music creation and collaboration process, letting you focus on what you do best - making great music. Let's dive into what you can do today and what's on the horizon!
    </p>
    <h3>Get started with our Beta tools:</h3>
    <h4>📖 Set List Builder</h4>
    <p>Craft your perfect set list with ease. Whether you're preparing for a gig, a rehearsal, or just organizing your tracks, our set list builder is here to keep your music organized and accessible.</p>
 
    </Card>
  </Grid.Col>
</Grid>
 
  );
};

export default Home;
