import React, { useEffect, useState } from "react";
import { useForm } from '@mantine/form';
import { Button, Modal, TextInput, Textarea, UnstyledButton } from "@mantine/core";

import useDebounce from "../../hooks/util/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { checkSlug, clearCreateProfileResult, createPersonalProfile, createProfile } from "../../redux/profile/actions";
import { RootState } from "../../redux";
import { AddressInput } from "../Input/AddressInput";

export const CreateBand = ({ opened, onClose }: any) => {
  const isPersonal = useSelector((state: RootState) => state.profile.showCreatePersonalProfileModal);
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const [validSlug, setValidSlug] = useState(true);
  const [slugModified, setSlugModified] = useState(false);
  const slugData = useSelector((state: RootState) => state.profile.checkSlugResult);
  const [placeSearchValue, setPlaceSearchValue] = useState('');
  const form = useForm({
    initialValues: {
      name: '',
      slug: '',
      bio: '',
      website: '',
      genre: '',
      // Address fields
      street: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      placeId: '',
    },
  });
  useEffect(() => {
    if(user) {
      console.log(user)
      form.setFieldValue('name', user.name);
      handleChangeSlug(user.name, false);
    }
  }, [user]);

  const handleCheckSlug = useDebounce(async (value) => {
    dispatch(checkSlug(value));
  }, 300);

  useEffect(() => {
    if (slugData) {
      setValidSlug(slugData.available);
    }
  }, [slugData]);

  const handleChangeSlug = (value: string, indirect = false) => {
    const slugifiedValue = value.toLowerCase().replace(/[^a-z0-9-]/g, '-');
    if(!indirect){
      setSlugModified(true);
    }
    form.setFieldValue('slug', slugifiedValue);
    handleCheckSlug(slugifiedValue);
  };

  const handleSubmitProfile = (values) => {
    if (!validSlug) return; // Prevent form submission if slug is invalid

    const profileData = {
      ...values,
      profileType: "BAND",
    };

    if(isPersonal) {
      dispatch(createPersonalProfile(profileData));
    } else {
      dispatch(createProfile(profileData));
    }
  };

  const handleClose = () => {
    dispatch(clearCreateProfileResult());
    onClose();
  };
  const handleChangeName = (value: string) => {
    form.setFieldValue('name', value);
    if(!slugModified) {
      handleChangeSlug(value, true);
    }

  }

  return (
    <Modal opened={opened} onClose={handleClose} fullScreen radius={0} transitionProps={{ transition: "fade", duration: 200 }}>
      <form onSubmit={form.onSubmit(handleSubmitProfile)}>
        <TextInput {...form.getInputProps('name')} label="Band Name" onChange={(event) => handleChangeName(event.target.value)}/>
        <TextInput
          value={form.values.slug}
          onChange={(event) => handleChangeSlug(event.target.value)}
          label="Profile Url"
          error={!validSlug && <div>Url not available, how about <UnstyledButton onClick={() => {
            form.setFieldValue('slug', slugData?.suggestedSlug);
            setValidSlug(true);
          }} style={{color: 'blue'}}>{slugData?.suggestedSlug}</UnstyledButton></div>}
        />
        <Textarea {...form.getInputProps('bio')} label="Bio" />
        <TextInput {...form.getInputProps('website')} label="Website" type="url" />
        <TextInput {...form.getInputProps('genre')} label="Genre" />
        <AddressInput searchValue={placeSearchValue} setSearchValue={setPlaceSearchValue} form={form} />
        <Button type="submit" disabled={!validSlug}>Create Band</Button>
      </form>
    </Modal>
  );
};
