export const LOAD_REPERTOIRE = "@REPERTOIRE/LOAD";
export const LOAD_REPERTOIRE_SUCCESS = "@REPERTOIRE/LOAD_SUCCESS";
export const LOAD_REPERTOIRE_FAILURE = "@REPERTOIRE/LOAD_FAILURE";
export const loadRepertoire = () => ({
    type: LOAD_REPERTOIRE,
    });
export const loadRepertoireSuccess = (repertoire) => ({
    type: LOAD_REPERTOIRE_SUCCESS,
    payload: repertoire,
    });
export const loadRepertoireFailure = (error) => ({
    type: LOAD_REPERTOIRE_FAILURE,
    payload: error,
    });
    