import React from 'react';
import styles from './OpenInSpotifyButton.module.css';
import { Button } from '@mantine/core';
const OpenInSpotifyButton = ({ spotifyId }: any) => {
  const openSpotify = () => {
    // Construct the Spotify URL with the provided spotifyId
    const spotifyUrl = `https://open.spotify.com/track/${spotifyId}`;
    // Open a new tab with the Spotify URL
    window.open(spotifyUrl, '_blank');
  };

  return (
    <Button size="xs" onClick={openSpotify} className={styles['open-in-spotify-button']}>
      Open in Spotify
    </Button>
  );
};

export default OpenInSpotifyButton;