import React, { useState } from "react";
import { notifications } from "@mantine/notifications";
import { useDispatch, useSelector } from 'react-redux';

import styles from "./AudioPreview.module.css";
import useUUID from "../../hooks/uuid/use-uuid";
import {
  IconCircleCaretRight,
  IconPlayerPauseFilled,
} from "@tabler/icons-react";
import { pauseAudio, playAudio } from "../../redux/audio-player/actions";
import { RootState } from "../../redux";

const AudioPreview = ({ previewUrl, graphicUrl }: any) => {
  const [url, setUrl] = useState<string | null>(null);
  const playerIdentifier = useUUID();
  const dispatch = useDispatch();
  const audioPlayer = useSelector((state: RootState) => state.audioPlayer);  
  const [previewLoading, setPreviewLoading] = useState(false);
  const togglePlay = async () => {
    if (!url) {
      setPreviewLoading(true);
      if (!previewUrl && previewLoading) {
        setPreviewLoading(false);
        return notifications.show({
          title: "No preview available",
          message: "Sorry, there is no preview available for this track.",
          color: "red",
        });
      }
      setUrl(previewUrl);
      setPreviewLoading(false);
    }
    if (audioPlayer.playerIdentifier === playerIdentifier && audioPlayer.isPlaying) {
      dispatch(pauseAudio(playerIdentifier));
    } else {
      dispatch(playAudio({
        url: previewUrl,
        playerIdentifier,
        artwork: graphicUrl
      }));
    }
  };

  return (
    <div className={styles.audioContainer}>
      <img src={graphicUrl} alt="Audio Graphic" className={styles.audioImage} />
      <button onClick={togglePlay} className={styles.playButton}>
        {audioPlayer.isPlaying &&
        audioPlayer.playerIdentifier === playerIdentifier ? (
          <IconPlayerPauseFilled />
        ) : (
          <IconCircleCaretRight />
        )}
      </button>
    </div>
  );
};

export default AudioPreview;
