import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = (): any => {
    const { search } = useLocation();
  
    return useMemo(() => {
        const params = new URLSearchParams(search);
        const query = {};
        // @ts-expect-error TS2802
        for (const [key, value] of params.entries()) {
            query[key] = value;
        }
        return query; 
    }, [search]);
  }
  