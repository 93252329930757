import React, { useEffect, useState } from 'react';
import { Button, TextInput, Group } from '@mantine/core';
import { useClipboard, } from '@mantine/hooks';
import { Calendar } from '../../components/Calendar/Calendar';
import { GigForm } from './GigForm';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGigs } from '../../redux/calendar/actions';
import { RootState } from '../../redux';


// Assume the iCalUrl is generated on the backend and passed to the frontend
// Here we're setting a static URL for demonstration purposes

export const PersonalCalendar = () => {
  const user = useSelector((state: RootState) => state.auth.user);  
  const iCalUrl = `https://app.bandhouse.io/cal/${user?.id}.ics`;
  const dispatch = useDispatch();
  const [todaysDate] = useState(new Date());
  const [modalOpen, setModalOpen] = useState(false);
  const { copy, copied } = useClipboard({ timeout: 2000 });
  const [startDate, setStartDate] = useState(new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(todaysDate.getFullYear(), todaysDate.getMonth() + 1, 0));
  const [newGigEventData, setNewGigEventData] = useState<null | any>(null);
  const data = useSelector((state: RootState) => state.calendar.gigs);
  const events = data.map((gig: any) => {
    return {
        id: gig.id, 
        start: gig.startDate,
        end: gig.endDate, 
        title: gig.name, 
        description: gig.description
    }
  }) ?? [];
  
  useEffect(() => {
      dispatch(fetchGigs(startDate.toISOString(), endDate.toISOString(), true));
  }, [startDate, endDate]);

  const handleDateClick = (arg) => {
    setModalOpen(true);
    setNewGigEventData(arg);
  };


  const handleRangeChange = ({start, end}) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
    <h1>{user?.firstName} {user?.lastName}&apos;s Calendar</h1>
      <Calendar events={events} onDateClick={handleDateClick} onRangeChange={handleRangeChange} />
      <Group mt="md">
        <TextInput value={iCalUrl} readOnly />
        <Button onClick={() => copy(iCalUrl)}>
          {copied ? 'Copied' : 'Copy URL'}
        </Button>
      </Group>

      <GigForm personal={true} newGigEventData={newGigEventData} open={modalOpen} toggle={setModalOpen} />
    </>
  );
};

