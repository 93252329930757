import { call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  LOGIN,
  LOGOUT, 
  SIGNUP, 
  UPDATE_ACCOUNT,
  loginSuccess, loginFailure,
  logoutSuccess, logoutFailure,
  signUpSuccess, signUpFailure,
  updateAccountSuccess, updateAccountFailure, login, signUp, updateAccount, initializeAuthSuccess, changePasswordSuccess, changePasswordFailure, CHANGE_PASSWORD, logout, SET_USER, confirmAccountSuccess, confirmAccountFailure, CONFIRM_ACCOUNT
} from './actions';
import { type Api } from '../../Api';


function* initializeAuthSaga(api: Api<any>) {
    const userString = localStorage.getItem('user');
    console.log('user string', userString)
    const user = userString === null || userString === 'undefined' ? null : JSON.parse(userString);
    if(user) {
      return yield put(initializeAuthSuccess(user));
    }
    try {
      const serverUser = yield call(api.users.userControllerRefreshToken, {});
      console.log('got user from server', serverUser)
      if(serverUser){
        yield localStorage.setItem('user', JSON.stringify(serverUser.data));

        return yield put(initializeAuthSuccess(serverUser.data));
      }
    } catch(error){
      console.log('token refresh error')
    }
    console.log('logging out in saga')
    yield put(logout());
}

function* confirmAccountSaga(api: Api<any>, action) {
  try {
    yield call(api.users.userControllerConfirmAccount, action.payload);
    yield put(confirmAccountSuccess());
    // Additional side effects like navigation can be implemented here
  } catch (error: any) {
    console.error(error);
    yield put(confirmAccountFailure(error.message));
  }
}

function* changePasswordSaga(api: Api<any>, action) {
    try {
      const { currentPassword, newPassword, newPasswordConfirm } = action.payload;
      // Call your API's change password method
      yield call(api.users.userControllerChangePassword, {currentPassword, newPassword, newPasswordConfirm } );
      yield put(changePasswordSuccess());
      // Additional logic after successful password change
    } catch (error: any) {
      yield put(changePasswordFailure(error.message));
    }
  }
  
  


function* loginSaga(api: Api<any>, action: ReturnType<typeof login>) {
  try {
    const response = yield call(api.users.userControllerLogin, {email: action.payload.email, password: action.payload.password});
    yield localStorage.setItem('user', JSON.stringify(response.data.user));
    yield put(loginSuccess(response.data.user));
    window.location.href = '/';

    // Additional side effects like navigation can be implemented here
  } catch (error: any) {
    console.error(error);
    yield put(loginFailure(error.message));
  }
}

function* logoutSaga(api: Api<any>) {
  try { 
    console.log('calling logout')
    api.users.userControllerLogout();
    console.log('removing user')
    localStorage.removeItem('user');
    console.log('removing active profile')
    localStorage.removeItem('activeProfile');
    yield put(logoutSuccess());
    window.location.href = process.env.NODE_ENV === 'production' ? 'https://bandhouse.io' : 'https://local.app.bandhouse.io/public';
    // Redirect to login page or other navigation logic
  } catch (error: any) {
    console.error(error);
    yield put(logoutFailure(error.message));

  }
}

function* signUpSaga(api: Api<any>, action: ReturnType<typeof signUp>) {
  try {
    const response = yield call(api.users.userControllerSignup, {email: action.payload.email, password: action.payload.password});
    yield  localStorage.setItem('user', JSON.stringify(response.data));
    yield put(signUpSuccess(response.data));
    window.location.href = '/';
    // Additional side effects like navigation can be implemented here
  } catch (error: any) {
    console.error(error);
    yield put(signUpFailure(error.message));
  }
}

function* updateAccountSaga(api: Api<any>, action: ReturnType<typeof updateAccount>) {
  try {
    const response = yield call(api.users.userControllerUpdate, action.payload.userId, action.payload.updateData);
    yield put(updateAccountSuccess(response.data));
    localStorage.setItem('user', JSON.stringify(response.data));
    // Additional side effects like refreshing the page or user data can be implemented here
    window.location.reload();
  } catch (error: any) {
    console.error(error);
    yield put(updateAccountFailure(error.message));
  }
}
function *setUserSaga(action){
  yield localStorage.setItem('user', JSON.stringify(action.payload));

}

export default function* watchAuthSagas(api: Api<any>) {
  yield fork(initializeAuthSaga, api),
  yield takeLatest(LOGIN, loginSaga, api);
  yield takeLatest(LOGOUT, logoutSaga, api);
  yield takeLatest(SIGNUP, signUpSaga, api);
  yield takeLatest(UPDATE_ACCOUNT, updateAccountSaga, api);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga, api);
  yield takeLatest(SET_USER, setUserSaga);
  yield takeLatest(CONFIRM_ACCOUNT, confirmAccountSaga, api);

}
