import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import { PLAY_AUDIO, PAUSE_AUDIO, SET_AUDIO_TRACK, setPlaybackTime, setDuration, SET_PLAYBACK_TIME, AUDIO_LOADED, AUDIO_ENDED, setPlayerIdentifier, setArtwork } from './actions';
import store from '..';

const audioRef = new Audio();

// Selector to get current state of audio player
function* playAudioSaga(action) {
  

  // If a playerIdentifier is provided and it's different from the current one, change the track
  if (action.payload && audioRef.src !== action.payload.url) {
    audioRef.src = action.payload.url;
    audioRef.load();
    audioRef.onloadeddata = () =>  {
      store.dispatch({ type: AUDIO_LOADED });
    };
    audioRef.onended = () =>{
      store.dispatch({ type: AUDIO_ENDED });
    };
    yield put(setPlayerIdentifier(action.payload.playerIdentifier));
    yield put(setArtwork(action.payload.artwork));
  }

  try {
    yield call([audioRef, audioRef.play]);
    yield put({ type: SET_PLAYBACK_TIME, payload: audioRef.currentTime });
    // yield put(setPlayerIdentifier(playerIdentifier));
    
  } catch (error) {
    console.error('Error playing audio:', error);
  }
}

function* pauseAudioSaga() {

  const audioPlayer = yield select(state => state.audioPlayer);

  // Pause if no playerIdentifier is provided, or if it matches the current one
  if (audioPlayer.isPlaying) {
    try {
      yield audioRef.pause();
    } catch (error) {
      console.error('Error pausing audio:', error);
    }
  }
}


function* setAudioTrackSaga(action) {
  const { url } = yield action.payload;
  audioRef.src = url;
  audioRef.onloadeddata = () =>  {
    store.dispatch({ type: AUDIO_LOADED });


  };
  audioRef.onended = function* () {
    yield put(setPlaybackTime(0));
  };
}

function* trackCurrentTime() {
  try {
    let prevTime = 0;
    while (true) {
      yield delay(1000); // Update every second
      const currentTime = audioRef.currentTime;
      if(prevTime !== currentTime){
        prevTime = currentTime;
        yield put(setPlaybackTime(currentTime));
      }
    }
  } catch (error) {
    console.error('Error tracking current time:', error);
  }
}
function* handleAudioLoaded() {
  yield put(setPlaybackTime(0));
  yield put(setDuration(audioRef.duration));
}
function* handleAudioEnded (){
  yield put(setPlaybackTime(0));
}

export default function* audioPlayerSaga() {
  yield takeLatest(PLAY_AUDIO, playAudioSaga);
  yield takeLatest(PAUSE_AUDIO, pauseAudioSaga);
  yield takeLatest(SET_AUDIO_TRACK, setAudioTrackSaga);
  yield takeLatest(AUDIO_LOADED, handleAudioLoaded);
  yield takeLatest(AUDIO_ENDED, handleAudioEnded);
  yield call(trackCurrentTime);

}
