import { Autocomplete, Button, ComboboxItem, Drawer, Fieldset, OptionsFilter, Tabs, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSongMeta, searchSpotifySongs } from "../../../redux/spotify/actions";
import { createSetListSongOptimistically } from "../../../redux/set-list/actions";
import useUUID from "../../../hooks/uuid/use-uuid";
import { RootState } from "../../../redux";
import { SetListRecommendations } from "./SetListRecommendations";
function millisToMinutesAndSeconds(millis: number) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0) as any;
  const isSecondsLessThanTen = seconds < 10;
  return minutes + ":" + (isSecondsLessThanTen ? '0' : '') + seconds;
}


export const SetListAddSongDrawer = ({ setListId, currentGroup, show, actions, addSongIndex}: any) => {
  const optimisticId = useUUID(show);
  const metaCache = useSelector((state: RootState) => state.spotify.songMetaCache);
  const addSongForm = useForm({
    initialValues: {
      songName: '',
      artistName: '',
      duration: '00:00',
      tempo: 0,
      lyrics: ''
    },
  });
  const addSong = (song:any) => {
    if(!currentGroup) return;
    dispatch(createSetListSongOptimistically(currentGroup, {
      title: song.songName,
      artistName: song.artistName,
      setListId,
      songId: metaCache.id,
      order: addSongIndex,
      lyrics: song.lyrics,
      graphicUrl: metaCache.graphicUrl,
      spotifyId: metaCache.spotifyId,
      setListGroupId: currentGroup
    }, optimisticId));
    actions.close();
    addSongForm.reset();
    setSearchString('');
  }


    const [searchString, setSearchString] = useState('');
    const dispatch = useDispatch();
    const options = useSelector((state: RootState) => state.spotify.searchResults);
    const optionsFilter: OptionsFilter = ({ options, search }) => {
        const splittedSearch = search.toLowerCase().trim().split(" ");
        return (options as ComboboxItem[]).filter((option) => {
          const words = option.label.toLowerCase().trim().split(" ");
          return splittedSearch.every((searchWord) =>
            words.some((word) => word.includes(searchWord))
          );
        });
      };
  
      useEffect(() => {
        if(searchString.length > 0){
            dispatch(searchSpotifySongs(searchString));
        }
      }, [searchString]);
      useEffect(() => {
        if(metaCache){
          let tempo = '';
          if(metaCache.audioFeatures && metaCache.audioFeatures.length > 0){
            tempo = metaCache.audioFeatures[0].tempo.toString();
          }
            addSongForm.setValues({
                songName: metaCache.songName,
                artistName: metaCache.artistName,
                duration: millisToMinutesAndSeconds(metaCache.duration),
                tempo: Number(tempo) || 0,
                lyrics: metaCache.lyrics
              });
        }
      }, [metaCache]);

    const addSpotifySong = async (songId: string) => {
        const song = options.find(
          (result: any) => result.songId === songId
        ) as any;
        dispatch(loadSongMeta(song.isrc, song.songId))
       
         
        
      };
    return (   <Drawer
        opened={show}
        onClose={actions.close}
        title="Add new song"
        position="right"
      >
        <Tabs defaultValue="Add Song">
          <Tabs.List>
            <Tabs.Tab value="Add Song">Add Song</Tabs.Tab>
            <Tabs.Tab value="Recommendations">Recommendations</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="Add Song">
            <Fieldset>
          <Autocomplete
            onChange={setSearchString}
            label="Search for song"
            data={options.map((result: any) => ({
              value: result.songId,
              label: `${result.artistName} - ${result.songName}`,
            }))}
            data-autofocus
            filter={optionsFilter}
            value={searchString}
            onOptionSubmit={addSpotifySong}
          />
        </Fieldset>
     <form 
     onSubmit={addSongForm.onSubmit((values) => addSong(values))}
     >
    <Fieldset mt={8}>
        <TextInput
          withAsterisk
          label="Song Name"
          {...addSongForm.getInputProps('songName')}
        />
        <TextInput
          withAsterisk
          label="Artist Name"
          {...addSongForm.getInputProps('artistName')}
        />
         <TextInput
          withAsterisk
          label="Duration"
          {...addSongForm.getInputProps('duration')}
        />
         <TextInput
          withAsterisk
          label="Tempo"
          {...addSongForm.getInputProps('tempo')}
        />

        <Textarea
        label="Lyrics"
        {...addSongForm.getInputProps('lyrics')}
        />
        </Fieldset>
        <Button type="submit">Add</Button>
      </form>
            </Tabs.Panel>
            <Tabs.Panel value="Recommendations">
              <SetListRecommendations index={addSongIndex} setListGroupId={currentGroup} />

              </Tabs.Panel>
        </Tabs>
      
      </Drawer>)
};