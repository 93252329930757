/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetManyGigResponseDto {
  data: Gig[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface Gig {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** Name of the gig */
  name: string;
  /** Description of the gig */
  description: string;
  /** Fee for the gig */
  fee?: number;
  /** Currency code for the gig fee */
  feeCurrency?:
    | "aed"
    | "afn"
    | "all"
    | "amd"
    | "ang"
    | "aoa"
    | "ars"
    | "aud"
    | "awg"
    | "azn"
    | "bam"
    | "bbd"
    | "bdt"
    | "bgn"
    | "bhd"
    | "bif"
    | "bmd"
    | "bnd"
    | "bob"
    | "brl"
    | "bsd"
    | "btn"
    | "bwp"
    | "byr"
    | "bzd"
    | "cad"
    | "cdf"
    | "chf"
    | "clp"
    | "cny"
    | "cop"
    | "crc"
    | "cuc"
    | "cup"
    | "cve"
    | "czk"
    | "djf"
    | "dkk"
    | "dop"
    | "dzd"
    | "egp"
    | "ern"
    | "etb"
    | "eur"
    | "fjd"
    | "fkp"
    | "gbp"
    | "gel"
    | "ggp"
    | "ghs"
    | "gip"
    | "gmd"
    | "gnf"
    | "gtq"
    | "gyd"
    | "hkd"
    | "hnl"
    | "hrk"
    | "htg"
    | "huf"
    | "idr"
    | "ils"
    | "imp"
    | "inr"
    | "iqd"
    | "irr"
    | "isk"
    | "jep"
    | "jmd"
    | "jod"
    | "jpy"
    | "kes"
    | "kgs"
    | "khr"
    | "kmf"
    | "kpw"
    | "krw"
    | "kwd"
    | "kyd"
    | "kzt"
    | "lak"
    | "lbp"
    | "lkr"
    | "lrd"
    | "lsl"
    | "lyd"
    | "mad"
    | "mdl"
    | "mga"
    | "mkd"
    | "mmk"
    | "mnt"
    | "mop"
    | "mro"
    | "mur"
    | "mvr"
    | "mwk"
    | "mxn"
    | "myr"
    | "mzn"
    | "nad"
    | "ngn"
    | "nio"
    | "nok"
    | "npr"
    | "nzd"
    | "omr"
    | "pab"
    | "pen"
    | "pgk"
    | "php"
    | "pkr"
    | "pln"
    | "pyg"
    | "qar"
    | "ron"
    | "rsd"
    | "rub"
    | "rwf"
    | "sar"
    | "sbd"
    | "scr"
    | "sdg"
    | "sek"
    | "sgd"
    | "shp"
    | "sll"
    | "sos"
    | "spl"
    | "srd"
    | "std"
    | "svc"
    | "syp"
    | "szl"
    | "thb"
    | "tjs"
    | "tmt"
    | "tnd"
    | "top"
    | "try"
    | "ttd"
    | "tvd"
    | "twd"
    | "tzs"
    | "uah"
    | "ugx"
    | "usd"
    | "uyu"
    | "uzs"
    | "vef"
    | "vnd"
    | "vuv"
    | "wst"
    | "xaf"
    | "xcd"
    | "xdr"
    | "xof"
    | "xpf"
    | "yer"
    | "zar"
    | "zmw"
    | "zwd";
  /** Address of the gig */
  address?: string;
  /** Indicates if ticketing is enabled for the gig */
  ticketingEnabled: boolean;
  /**
   * Total number of tickets
   * @default 0
   */
  ticketCount?: number;
  /**
   * Number of tickets sold
   * @default 0
   */
  ticketSales: number;
  /**
   * Booking status of the gig
   * @default "pending"
   */
  bookingStatus: "confirmed" | "cancelled" | "pending";
  /** Start date and time of the gig */
  startDate: object;
  /** Duration of the gig in hours */
  duration?: number;
  /** Profile ID associated with the gig */
  profileId: string;
  /** Load in time in HH:MM format */
  loadInTime?: string;
  /** Whether the gig is public */
  public?: boolean;
  /** Sound check time in HH:MM format */
  soundCheckTime?: string;
  /** Event start time in HH:MM format */
  eventStartTime?: string;
  /** Doors time in HH:MM format */
  doorsTime?: string;
  /** Notes about the gig */
  notes?: string;
  /** Attire for the gig */
  attire?: string;
  /** Food arrangements for the gig */
  food?: string;
  /** Backline for the gig */
  backline?: string;
  /** Parking arrangements for the gig */
  parking?: string;
  /** Room number for the gig */
  room?: string;
  /** Google venue id */
  venueId?: string;
  /** id of the associated set list */
  setListId?: string;
}

export interface CreateManyGigDto {
  bulk: Gig[];
}

export interface GetManyTicketResponseDto {
  data: Ticket[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface Ticket {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface CreateManyTicketDto {
  bulk: Ticket[];
}

export interface GetManyProfileResponseDto {
  data: Profile[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface GetManyProfileMemberResponseDto {
  data: ProfileMember[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface ProfileMember {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface GetManyProfileInviteResponseDto {
  data: ProfileInvite[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface ProfileInvite {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface GetManyChatResponseDto {
  data: Chat[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface Chat {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface GetManyTodoResponseDto {
  data: Todo[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface Todo {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** The text of the todo. */
  text: string;
  /** The due date of the todo. */
  dueDate?: string;
  /** Whether the todo is completed. */
  completed: boolean;
  /** The profile ID of the profile that owns the todo. */
  profileId: string;
  /** The order of the todo. */
  order: number;
  /**
   * The type of the todo.
   * @default "MANUAL"
   */
  type: "MANUAL" | "CREATE_SETLIST" | "CREATE_BAND" | "ADD_GIG" | "ADD_TO_GOOGLE_CALENDAR" | "SETUP_PAYMENTS_ACCOUNT";
  /** The ID of the user assigned to the todo. */
  assigneeId?: string;
}

export interface GetManyInvoiceResponseDto {
  data: Invoice[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface GetManyInvoiceLineItemResponseDto {
  data: InvoiceLineItem[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface InvoiceLineItem {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** The description of the invoice line item */
  description: string;
  /** The quantity of the invoice line item */
  quantity: number;
  /** The amount of the invoice line item */
  amount: number;
  /** The invoice ID of the invoice line item */
  invoiceId: string;
}

export interface GetManyCustomerResponseDto {
  data: Customer[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface Customer {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface GetManyInvoiceContentResponseDto {
  data: InvoiceContent[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface InvoiceContent {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** The logo of the invoice */
  logo: string;
  /** The width of the logo of the invoice */
  logoWidth: number;
  /** The title of the invoice */
  title: string;
  /** The company name */
  companyName: string;
  /** The name */
  name: string;
  /** The company address */
  companyAddress: string;
  /** The second line of the company address */
  companyAddress2: string;
  /** The country of the company */
  companyCountry: string;
  /** The bill to */
  billTo: string;
  /** The client name */
  clientName: string;
  /** The client address */
  clientAddress: string;
  /** The second line of the client address */
  clientAddress2: string;
  /** The country of the client */
  clientCountry: string;
  /** The label for the invoice title */
  invoiceTitleLabel: string;
  /** The invoice title */
  invoiceTitle: string;
  /** The label for the invoice date */
  invoiceDateLabel: string;
  /** The invoice date */
  invoiceDate: string;
  /** The label for the invoice due date */
  invoiceDueDateLabel: string;
  /** The invoice due date */
  invoiceDueDate: string;
  /** The description of the product line */
  productLineDescription: string;
  /** The quantity of the product line */
  productLineQuantity: string;
  /** The rate of the product line quantity */
  productLineQuantityRate: string;
  /** The amount of the product line quantity */
  productLineQuantityAmount: string;
  /** The label for the sub total */
  subTotalLabel: string;
  /** The label for the tax */
  taxLabel: string;
  /** The label for the total */
  totalLabel: string;
  /** The currency */
  currency: string;
  /** The label for the notes */
  notesLabel: string;
  /** The notes */
  notes: string;
  /** The label for the term */
  termLabel: string;
  /** The term */
  term: string;
}

export interface Invoice {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** The total amount of the invoice */
  amount: number;
  /** The currency code of the invoice */
  currency: string;
  /** The invoice number */
  invoiceNumber?: string;
  /** The description of the invoice */
  description: string;
  /**
   * The date of the invoice
   * @format date-time
   */
  dueDate: string;
  /** The status of the invoice */
  status: "paid" | "unpaid" | "pending";
  gig: Gig;
  lineItems: InvoiceLineItem[];
  customer: Customer;
  /** The profile ID of the invoice */
  profileId: string;
  profile: Profile;
  content: InvoiceContent;
}

export interface Profile {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /**
   * Name of the profile
   * @example "The Band"
   */
  name: string;
  /**
   * Biography of the profile
   * @example "A brief bio"
   */
  bio?: string;
  /**
   * Location of the profile
   * @example "New York"
   */
  location?: string;
  /**
   * Website of the profile
   * @example "http://bandwebsite.com"
   */
  website?: string;
  /**
   * Unique slug for the profile
   * @example "the-band"
   */
  slug: string;
  /**
   * Genre of the profile
   * @example "Rock"
   */
  genre?: string;
  /**
   * Formation year of the profile
   * @example 1990
   */
  formationYear?: number;
  /** Type of the profile */
  profileType: "BAND" | "VENUE" | "FAN" | "INDIVIDUAL";
  /**
   * Contact email of the profile
   * @example "contact@band.com"
   */
  contactEmail?: string;
  /**
   * Contact phone of the profile
   * @example "+123456789"
   */
  contactPhone?: string;
  /** Social media links of the profile */
  socialMediaLinks?: string[];
  /** List of profile members */
  profileMembers: ProfileMember[];
  /** List of profile invites */
  invites: ProfileInvite[];
  /** Chat ID associated with the profile */
  chatId?: string;
  /** Associated chat object */
  chat?: Chat;
  /** List of todos associated with the profile */
  todos: Todo[];
  /** List of invoices associated with the profile */
  invoices: Invoice[];
  /** Google Place ID */
  placeId?: string;
  /** Street address */
  street?: string;
  /** City */
  city?: string;
  /** State or province */
  state?: string;
  /** Country */
  country?: string;
  /** Postal or ZIP code */
  postalCode?: string;
}

export interface CheckSlugDto {
  /**
   * The slug to check for availability.
   * @minLength 3
   * @maxLength 50
   * @pattern /^[a-z0-9]+(?:-[a-z0-9]+)*$/
   * @example "my-band-name"
   */
  slug: string;
}

export interface CreateManyProfileDto {
  bulk: Profile[];
}

export interface CreateManyTodoDto {
  bulk: Todo[];
}

export interface CreateManyProfileInviteDto {
  bulk: ProfileInvite[];
}

export interface CreateManyProfileMemberDto {
  bulk: ProfileMember[];
}

export interface GetManySetListResponseDto {
  data: SetList[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface SetList {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** The title of the set list. */
  title: string;
  /** The description of the set list. */
  description: string;
  /**
   * The status of the set list.
   * @default "PUBLISHED"
   */
  status: "PUBLISHED" | "ARCHIVED";
  /** The profile ID of the band that owns the set list. */
  profileId: string;
}

export interface CreateManySetListDto {
  bulk: SetList[];
}

export interface GetManySetListGroupResponseDto {
  data: SetListGroup[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface SetListGroup {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** The title of the set list group. */
  title: string;
  /** The description of the set list group. */
  description: string;
  /** The set list ID of the set list group. */
  setListId: string;
  /** The order of the set list group. */
  order: number;
  /** The profile ID of the band that owns the set list group. */
  profileId: string;
  /**
   * The status of the set list import.
   * @default "NONE"
   */
  importStatus: "NONE" | "SPOTIFY_IMPORT" | "SPOTIFY_IMPORT_FAILURE";
}

export interface CreateManySetListGroupDto {
  bulk: SetListGroup[];
}

export interface GetManySetListSongResponseDto {
  data: SetListSong[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface SetListSong {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /** The title of the song */
  title: string;
  /** The name of the artist */
  artistName: string;
  /**
   * Order in the set list
   * @example 1
   */
  order: number;
  /** Additional notes */
  notes?: string;
  /** URL of the song graphic */
  graphicUrl: string;
  /** Spotify ID of the song */
  spotifyId: string;
  /** ID of the set list group */
  setListGroupId: string;
  /** Lyrics of the song */
  lyrics?: string;
  /** ID of the song */
  songId?: string;
}

export interface CreateManySetListSongDto {
  bulk: SetListSong[];
}

export type SpotifyConnectDto = object;

export interface UserToken {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface User {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
  /**
   *  Name of the user
   * @example "John Stephens"
   */
  name?: string;
  /**
   * Email of the user
   * @example "john.doe@example.com"
   */
  email: string;
  /**
   * Indicates if the user's email is verified
   * @format date-time
   * @example true
   */
  emailVerified: string;
  /** Password hash of the user */
  passwordHash?: string;
  /**
   * ID of the user's active profile
   * @format uuid
   * @example "uuid-of-active-profile"
   */
  activeProfileId?: string;
  /** List of chats the user is part of */
  chats: Chat[];
  /** Tokens associated with the user */
  tokens: UserToken[];
  /**
   * Spotify ID of the user
   * @example "spotify-user-id"
   */
  spotifyId?: string;
  /** Spotify access token of the user */
  spotifyAccessToken?: string;
  /** Spotify refresh token of the user */
  spotifyRefreshToken?: string;
}

export type UpdateUserDto = object;

export type LoginDto = object;

export type SignupDto = object;

export type RefreshTokenDto = object;

export type ChangePasswordDto = object;

export type RequestMetadataDto = object;

export interface GetRecommendationsDto {
  /** The song IDs of the recommendations */
  songIds: string[];
}

export interface CreateManyChatDto {
  bulk: Chat[];
}

export interface GetManyChatMessageResponseDto {
  data: ChatMessage[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface ChatMessage {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface CreateManyChatMessageDto {
  bulk: ChatMessage[];
}

export interface GetManyVenueResponseDto {
  data: Venue[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export type Venue = object;

export interface CreateManyVenueDto {
  bulk: Venue[];
}

export interface GetManyChatMessageClassificationResponseDto {
  data: ChatMessageClassification[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface ChatMessageClassification {
  /**
   * Unique identifier of the entity
   * @format uuid
   */
  id: string;
  /**
   * Date and time when the entity was created
   * @format date-time
   */
  createdAt: string;
  /**
   * Date and time when the entity was last updated
   * @format date-time
   */
  updatedAt: string;
}

export interface CreateManyChatMessageClassificationDto {
  bulk: ChatMessageClassification[];
}

export interface GetManyDashboardViewResponseDto {
  data: DashboardView[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export type DashboardView = object;

export interface CreateManyDashboardViewDto {
  bulk: DashboardView[];
}

export interface CreateManyInvoiceDto {
  bulk: Invoice[];
}

export interface CreateManyInvoiceLineItemDto {
  bulk: InvoiceLineItem[];
}

export interface CreateManyCustomerDto {
  bulk: Customer[];
}

export interface CreateManyInvoiceContentDto {
  bulk: InvoiceContent[];
}

export interface GetManyRepertoireViewResponseDto {
  data: RepertoireView[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface RepertoireView {
  /** Spotify ID of the song */
  spotifyId: string;
  /** Title of the song */
  title: string;
  /** Artist name of the song */
  artistName: string;
  /** Graphic URL of the song */
  graphicUrl: string;
  /** Profile ID associated with the set list group */
  profileId: string;
  /** Count of how many times the song appears */
  songCount: number;
  /** Concatenated list of set list group IDs */
  setListGroupIds: string;
  /** Preview URL of the song */
  previewUrl: string;
}

export interface CreateManyRepertoireViewDto {
  bulk: RepertoireView[];
}

export type AppControllerGetHelloData = any;

export type GigControllerGetPersonalCalendarData = Gig[];

export type CreateOneBaseGigControllerGigData = Gig;

export type GetManyBaseGigControllerGigData = GetManyGigResponseDto | Gig[];

export type UpdateOneBaseGigControllerGigData = Gig;

export type GetOneBaseGigControllerGigData = Gig;

export type ReplaceOneBaseGigControllerGigData = Gig;

export type DeleteOneBaseGigControllerGigData = any;

export type CreateManyBaseGigControllerGigData = Gig[];

export type GetOneBaseTicketControllerTicketData = Ticket;

export type UpdateOneBaseTicketControllerTicketData = Ticket;

export type ReplaceOneBaseTicketControllerTicketData = Ticket;

export type DeleteOneBaseTicketControllerTicketData = any;

export type GetManyBaseTicketControllerTicketData = GetManyTicketResponseDto | Ticket[];

export type CreateOneBaseTicketControllerTicketData = Ticket;

export type CreateManyBaseTicketControllerTicketData = Ticket[];

export type CreateOneBaseProfileControllerProfileData = Profile;

export type GetManyBaseProfileControllerProfileData = GetManyProfileResponseDto | Profile[];

export type ProfileControllerGetPersonalProfileData = any;

export type ProfileControllerCheckSlugData = any;

export type GetOneBaseProfileControllerProfileData = Profile;

export type UpdateOneBaseProfileControllerProfileData = Profile;

export type ReplaceOneBaseProfileControllerProfileData = Profile;

export type DeleteOneBaseProfileControllerProfileData = any;

export type CreateManyBaseProfileControllerProfileData = Profile[];

export type GetOneBaseTodoControllerTodoData = Todo;

export type UpdateOneBaseTodoControllerTodoData = Todo;

export type ReplaceOneBaseTodoControllerTodoData = Todo;

export type DeleteOneBaseTodoControllerTodoData = any;

export type GetManyBaseTodoControllerTodoData = GetManyTodoResponseDto | Todo[];

export type CreateOneBaseTodoControllerTodoData = Todo;

export type CreateManyBaseTodoControllerTodoData = Todo[];

export type ProfileInviteControllerCheckInvitesData = any;

export type GetOneBaseProfileInviteControllerProfileInviteData = ProfileInvite;

export type UpdateOneBaseProfileInviteControllerProfileInviteData = ProfileInvite;

export type ReplaceOneBaseProfileInviteControllerProfileInviteData = ProfileInvite;

export type DeleteOneBaseProfileInviteControllerProfileInviteData = any;

export type GetManyBaseProfileInviteControllerProfileInviteData = GetManyProfileInviteResponseDto | ProfileInvite[];

export type CreateOneBaseProfileInviteControllerProfileInviteData = ProfileInvite;

export type CreateManyBaseProfileInviteControllerProfileInviteData = ProfileInvite[];

export type CreateOneBaseProfileMemberControllerProfileMemberData = ProfileMember;

export type GetManyBaseProfileMemberControllerProfileMemberData = GetManyProfileMemberResponseDto | ProfileMember[];

export type GetOneBaseProfileMemberControllerProfileMemberData = ProfileMember;

export type UpdateOneBaseProfileMemberControllerProfileMemberData = ProfileMember;

export type ReplaceOneBaseProfileMemberControllerProfileMemberData = ProfileMember;

export type DeleteOneBaseProfileMemberControllerProfileMemberData = any;

export type CreateManyBaseProfileMemberControllerProfileMemberData = ProfileMember[];

export type CreateOneBaseSetListControllerSetListData = SetList;

export type GetManyBaseSetListControllerSetListData = GetManySetListResponseDto | SetList[];

export type GetOneBaseSetListControllerSetListData = SetList;

export type UpdateOneBaseSetListControllerSetListData = SetList;

export type ReplaceOneBaseSetListControllerSetListData = SetList;

export type DeleteOneBaseSetListControllerSetListData = any;

export type CreateManyBaseSetListControllerSetListData = SetList[];

export type GetOneBaseSetListGroupControllerSetListGroupData = SetListGroup;

export type UpdateOneBaseSetListGroupControllerSetListGroupData = SetListGroup;

export type ReplaceOneBaseSetListGroupControllerSetListGroupData = SetListGroup;

export type DeleteOneBaseSetListGroupControllerSetListGroupData = any;

export type GetManyBaseSetListGroupControllerSetListGroupData = GetManySetListGroupResponseDto | SetListGroup[];

export type CreateOneBaseSetListGroupControllerSetListGroupData = SetListGroup;

export type CreateManyBaseSetListGroupControllerSetListGroupData = SetListGroup[];

export type CreateOneBaseSetListSongControllerSetListSongData = SetListSong;

export type GetManyBaseSetListSongControllerSetListSongData = GetManySetListSongResponseDto | SetListSong[];

export type ReplaceOneBaseSetListSongControllerSetListSongData = SetListSong;

export type GetOneBaseSetListSongControllerSetListSongData = SetListSong;

export type UpdateOneBaseSetListSongControllerSetListSongData = SetListSong;

export type DeleteOneBaseSetListSongControllerSetListSongData = any;

export type CreateManyBaseSetListSongControllerSetListSongData = SetListSong[];

export type UserControllerAuthenticateSpotifyData = User;

export type UserControllerConnectSpotifyData = User;

export type UserControllerRefreshSpotifyTokenData = User;

export type UserControllerUpdateData = User;

export type UserControllerLoginData = User;

export type UserControllerSignupData = User;

export type UserControllerRefreshTokenData = any;

export type UserControllerLogoutData = any;

export type UserControllerChangePasswordData = any;

export type UserControllerResetPasswordData = any;

export type UserControllerConfirmAccountData = any;

export type SongControllerSearchTrackData = any;

export type SongControllerLoadMetaDataData = any;

export type SongControllerGetRecommendationsData = any;

export type GetOneBaseChatControllerChatData = Chat;

export type UpdateOneBaseChatControllerChatData = Chat;

export type ReplaceOneBaseChatControllerChatData = Chat;

export type DeleteOneBaseChatControllerChatData = any;

export type GetManyBaseChatControllerChatData = GetManyChatResponseDto | Chat[];

export type CreateOneBaseChatControllerChatData = Chat;

export type CreateManyBaseChatControllerChatData = Chat[];

export type GetOneBaseChatMessageControllerChatMessageData = ChatMessage;

export type UpdateOneBaseChatMessageControllerChatMessageData = ChatMessage;

export type ReplaceOneBaseChatMessageControllerChatMessageData = ChatMessage;

export type DeleteOneBaseChatMessageControllerChatMessageData = any;

export type GetManyBaseChatMessageControllerChatMessageData = GetManyChatMessageResponseDto | ChatMessage[];

export type CreateOneBaseChatMessageControllerChatMessageData = ChatMessage;

export type CreateManyBaseChatMessageControllerChatMessageData = ChatMessage[];

export type GetOneBaseVenueControllerVenueData = Venue;

export type UpdateOneBaseVenueControllerVenueData = Venue;

export type ReplaceOneBaseVenueControllerVenueData = Venue;

export type DeleteOneBaseVenueControllerVenueData = any;

export type GetManyBaseVenueControllerVenueData = GetManyVenueResponseDto | Venue[];

export type CreateOneBaseVenueControllerVenueData = Venue;

export type CreateManyBaseVenueControllerVenueData = Venue[];

export type GetOneBaseChatMessageClassificationControllerChatMessageClassificationData = ChatMessageClassification;

export type UpdateOneBaseChatMessageClassificationControllerChatMessageClassificationData = ChatMessageClassification;

export type ReplaceOneBaseChatMessageClassificationControllerChatMessageClassificationData = ChatMessageClassification;

export type DeleteOneBaseChatMessageClassificationControllerChatMessageClassificationData = any;

export type GetManyBaseChatMessageClassificationControllerChatMessageClassificationData =
  | GetManyChatMessageClassificationResponseDto
  | ChatMessageClassification[];

export type CreateOneBaseChatMessageClassificationControllerChatMessageClassificationData = ChatMessageClassification;

export type CreateManyBaseChatMessageClassificationControllerChatMessageClassificationData =
  ChatMessageClassification[];

export type GetOneBaseDashboardControllerDashboardViewData = DashboardView;

export type UpdateOneBaseDashboardControllerDashboardViewData = DashboardView;

export type ReplaceOneBaseDashboardControllerDashboardViewData = DashboardView;

export type DeleteOneBaseDashboardControllerDashboardViewData = any;

export type GetManyBaseDashboardControllerDashboardViewData = GetManyDashboardViewResponseDto | DashboardView[];

export type CreateOneBaseDashboardControllerDashboardViewData = DashboardView;

export type CreateManyBaseDashboardControllerDashboardViewData = DashboardView[];

export type GetOneBaseInvoiceControllerInvoiceData = Invoice;

export type UpdateOneBaseInvoiceControllerInvoiceData = Invoice;

export type ReplaceOneBaseInvoiceControllerInvoiceData = Invoice;

export type DeleteOneBaseInvoiceControllerInvoiceData = any;

export type GetManyBaseInvoiceControllerInvoiceData = GetManyInvoiceResponseDto | Invoice[];

export type CreateOneBaseInvoiceControllerInvoiceData = Invoice;

export type CreateManyBaseInvoiceControllerInvoiceData = Invoice[];

export type GetOneBaseInvoiceLineItemControllerInvoiceLineItemData = InvoiceLineItem;

export type UpdateOneBaseInvoiceLineItemControllerInvoiceLineItemData = InvoiceLineItem;

export type ReplaceOneBaseInvoiceLineItemControllerInvoiceLineItemData = InvoiceLineItem;

export type DeleteOneBaseInvoiceLineItemControllerInvoiceLineItemData = any;

export type GetManyBaseInvoiceLineItemControllerInvoiceLineItemData =
  | GetManyInvoiceLineItemResponseDto
  | InvoiceLineItem[];

export type CreateOneBaseInvoiceLineItemControllerInvoiceLineItemData = InvoiceLineItem;

export type CreateManyBaseInvoiceLineItemControllerInvoiceLineItemData = InvoiceLineItem[];

export type GetOneBaseCustomerControllerCustomerData = Customer;

export type UpdateOneBaseCustomerControllerCustomerData = Customer;

export type ReplaceOneBaseCustomerControllerCustomerData = Customer;

export type DeleteOneBaseCustomerControllerCustomerData = any;

export type GetManyBaseCustomerControllerCustomerData = GetManyCustomerResponseDto | Customer[];

export type CreateOneBaseCustomerControllerCustomerData = Customer;

export type CreateManyBaseCustomerControllerCustomerData = Customer[];

export type GetOneBaseInvoiceContentControllerInvoiceContentData = InvoiceContent;

export type UpdateOneBaseInvoiceContentControllerInvoiceContentData = InvoiceContent;

export type ReplaceOneBaseInvoiceContentControllerInvoiceContentData = InvoiceContent;

export type DeleteOneBaseInvoiceContentControllerInvoiceContentData = any;

export type GetManyBaseInvoiceContentControllerInvoiceContentData = GetManyInvoiceContentResponseDto | InvoiceContent[];

export type CreateOneBaseInvoiceContentControllerInvoiceContentData = InvoiceContent;

export type CreateManyBaseInvoiceContentControllerInvoiceContentData = InvoiceContent[];

export type GetOneBaseRepertoireControllerRepertoireViewData = RepertoireView;

export type UpdateOneBaseRepertoireControllerRepertoireViewData = RepertoireView;

export type ReplaceOneBaseRepertoireControllerRepertoireViewData = RepertoireView;

export type DeleteOneBaseRepertoireControllerRepertoireViewData = any;

export type GetManyBaseRepertoireControllerRepertoireViewData = GetManyRepertoireViewResponseDto | RepertoireView[];

export type CreateOneBaseRepertoireControllerRepertoireViewData = RepertoireView;

export type CreateManyBaseRepertoireControllerRepertoireViewData = RepertoireView[];

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "https://local.app.bandhouse.io:4000",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title BANDHOUSE Api
 * @version 1.0.0
 * @baseUrl https://local.app.bandhouse.io/api
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AppControllerGetHello
   * @request GET:/
   * @response `200` `AppControllerGetHelloData`
   */
  appControllerGetHello = (params: RequestParams = {}) =>
    this.request<AppControllerGetHelloData, any>({
      path: `/`,
      method: "GET",
      ...params,
    });

  gigs = {
    /**
     * No description
     *
     * @tags gigs
     * @name GigControllerGetPersonalCalendar
     * @summary Get personal calendar
     * @request GET:/gigs/personal-calendar
     * @secure
     * @response `200` `GigControllerGetPersonalCalendarData` Return a list of gigs.
     */
    gigControllerGetPersonalCalendar: (params: RequestParams = {}) =>
      this.request<GigControllerGetPersonalCalendarData, any>({
        path: `/gigs/personal-calendar`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags gigs
     * @name CreateOneBaseGigControllerGig
     * @summary Create a gig
     * @request POST:/gigs
     * @secure
     * @response `201` `CreateOneBaseGigControllerGigData` The gig has been successfully created.
     */
    createOneBaseGigControllerGig: (
      data: Gig,
      query?: {
        /** Flag to create an invoice for the gig */
        createInvoice?: any;
      },
      params: RequestParams = {},
    ) =>
      this.request<CreateOneBaseGigControllerGigData, any>({
        path: `/gigs`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags gigs
     * @name GetManyBaseGigControllerGig
     * @summary Retrieve multiple Gigs
     * @request GET:/gigs
     * @secure
     * @response `200` `GetManyBaseGigControllerGigData` Get many base response
     */
    getManyBaseGigControllerGig: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseGigControllerGigData, any>({
        path: `/gigs`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags gigs
     * @name UpdateOneBaseGigControllerGig
     * @summary Update a gig
     * @request PATCH:/gigs/{id}
     * @secure
     * @response `200` `UpdateOneBaseGigControllerGigData` The gig has been successfully updated.
     */
    updateOneBaseGigControllerGig: (id: string, data: Gig, params: RequestParams = {}) =>
      this.request<UpdateOneBaseGigControllerGigData, any>({
        path: `/gigs/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags gigs
     * @name GetOneBaseGigControllerGig
     * @summary Retrieve a single Gig
     * @request GET:/gigs/{id}
     * @secure
     * @response `200` `GetOneBaseGigControllerGigData` Get one base response
     */
    getOneBaseGigControllerGig: (
      id: string,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseGigControllerGigData, any>({
        path: `/gigs/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags gigs
     * @name ReplaceOneBaseGigControllerGig
     * @summary Replace a single Gig
     * @request PUT:/gigs/{id}
     * @secure
     * @response `200` `ReplaceOneBaseGigControllerGigData` Response
     */
    replaceOneBaseGigControllerGig: (id: string, data: Gig, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseGigControllerGigData, any>({
        path: `/gigs/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags gigs
     * @name DeleteOneBaseGigControllerGig
     * @summary Delete a single Gig
     * @request DELETE:/gigs/{id}
     * @secure
     * @response `200` `DeleteOneBaseGigControllerGigData` Delete one base response
     */
    deleteOneBaseGigControllerGig: (id: string, params: RequestParams = {}) =>
      this.request<DeleteOneBaseGigControllerGigData, any>({
        path: `/gigs/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags gigs
     * @name CreateManyBaseGigControllerGig
     * @summary Create multiple Gigs
     * @request POST:/gigs/bulk
     * @secure
     * @response `201` `CreateManyBaseGigControllerGigData` Get create many base response
     */
    createManyBaseGigControllerGig: (data: CreateManyGigDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseGigControllerGigData, any>({
        path: `/gigs/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  tickets = {
    /**
     * No description
     *
     * @name GetOneBaseTicketControllerTicket
     * @summary Retrieve a single Ticket
     * @request GET:/tickets/{id}
     * @response `200` `GetOneBaseTicketControllerTicketData` Get one base response
     */
    getOneBaseTicketControllerTicket: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseTicketControllerTicketData, any>({
        path: `/tickets/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseTicketControllerTicket
     * @summary Update a single Ticket
     * @request PATCH:/tickets/{id}
     * @response `200` `UpdateOneBaseTicketControllerTicketData` Response
     */
    updateOneBaseTicketControllerTicket: (id: number, data: Ticket, params: RequestParams = {}) =>
      this.request<UpdateOneBaseTicketControllerTicketData, any>({
        path: `/tickets/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseTicketControllerTicket
     * @summary Replace a single Ticket
     * @request PUT:/tickets/{id}
     * @response `200` `ReplaceOneBaseTicketControllerTicketData` Response
     */
    replaceOneBaseTicketControllerTicket: (id: number, data: Ticket, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseTicketControllerTicketData, any>({
        path: `/tickets/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseTicketControllerTicket
     * @summary Delete a single Ticket
     * @request DELETE:/tickets/{id}
     * @response `200` `DeleteOneBaseTicketControllerTicketData` Delete one base response
     */
    deleteOneBaseTicketControllerTicket: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseTicketControllerTicketData, any>({
        path: `/tickets/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseTicketControllerTicket
     * @summary Retrieve multiple Tickets
     * @request GET:/tickets
     * @response `200` `GetManyBaseTicketControllerTicketData` Get many base response
     */
    getManyBaseTicketControllerTicket: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseTicketControllerTicketData, any>({
        path: `/tickets`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseTicketControllerTicket
     * @summary Create a single Ticket
     * @request POST:/tickets
     * @response `201` `CreateOneBaseTicketControllerTicketData` Get create one base response
     */
    createOneBaseTicketControllerTicket: (data: Ticket, params: RequestParams = {}) =>
      this.request<CreateOneBaseTicketControllerTicketData, any>({
        path: `/tickets`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseTicketControllerTicket
     * @summary Create multiple Tickets
     * @request POST:/tickets/bulk
     * @response `201` `CreateManyBaseTicketControllerTicketData` Get create many base response
     */
    createManyBaseTicketControllerTicket: (data: CreateManyTicketDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseTicketControllerTicketData, any>({
        path: `/tickets/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  profile = {
    /**
     * No description
     *
     * @tags profile
     * @name CreateOneBaseProfileControllerProfile
     * @summary Create a single Profile
     * @request POST:/profile
     * @secure
     * @response `201` `CreateOneBaseProfileControllerProfileData` Get create one base response
     */
    createOneBaseProfileControllerProfile: (data: Profile, params: RequestParams = {}) =>
      this.request<CreateOneBaseProfileControllerProfileData, any>({
        path: `/profile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name GetManyBaseProfileControllerProfile
     * @summary Retrieve multiple Profiles
     * @request GET:/profile
     * @secure
     * @response `200` `GetManyBaseProfileControllerProfileData` Get many base response
     */
    getManyBaseProfileControllerProfile: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseProfileControllerProfileData, any>({
        path: `/profile`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerGetPersonalProfile
     * @summary Get personal profile
     * @request GET:/profile/personal
     * @secure
     * @response `200` `ProfileControllerGetPersonalProfileData` Personal profile retrieved.
     * @response `404` `void` Profile not found.
     */
    profileControllerGetPersonalProfile: (params: RequestParams = {}) =>
      this.request<ProfileControllerGetPersonalProfileData, void>({
        path: `/profile/personal`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ProfileControllerCheckSlug
     * @summary Check if a slug is available
     * @request POST:/profile/check-slug
     * @secure
     * @response `200` `ProfileControllerCheckSlugData` Slug availability checked.
     * @response `400` `void` Invalid slug.
     */
    profileControllerCheckSlug: (data: CheckSlugDto, params: RequestParams = {}) =>
      this.request<ProfileControllerCheckSlugData, void>({
        path: `/profile/check-slug`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name GetOneBaseProfileControllerProfile
     * @summary Retrieve a single Profile
     * @request GET:/profile/{id}
     * @secure
     * @response `200` `GetOneBaseProfileControllerProfileData` Get one base response
     */
    getOneBaseProfileControllerProfile: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseProfileControllerProfileData, any>({
        path: `/profile/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name UpdateOneBaseProfileControllerProfile
     * @summary Update a single Profile
     * @request PATCH:/profile/{id}
     * @secure
     * @response `200` `UpdateOneBaseProfileControllerProfileData` Response
     */
    updateOneBaseProfileControllerProfile: (id: number, data: Profile, params: RequestParams = {}) =>
      this.request<UpdateOneBaseProfileControllerProfileData, any>({
        path: `/profile/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name ReplaceOneBaseProfileControllerProfile
     * @summary Replace a single Profile
     * @request PUT:/profile/{id}
     * @secure
     * @response `200` `ReplaceOneBaseProfileControllerProfileData` Response
     */
    replaceOneBaseProfileControllerProfile: (id: number, data: Profile, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseProfileControllerProfileData, any>({
        path: `/profile/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name DeleteOneBaseProfileControllerProfile
     * @summary Delete a single Profile
     * @request DELETE:/profile/{id}
     * @secure
     * @response `200` `DeleteOneBaseProfileControllerProfileData` Delete one base response
     */
    deleteOneBaseProfileControllerProfile: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseProfileControllerProfileData, any>({
        path: `/profile/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name CreateManyBaseProfileControllerProfile
     * @summary Create multiple Profiles
     * @request POST:/profile/bulk
     * @secure
     * @response `201` `CreateManyBaseProfileControllerProfileData` Get create many base response
     */
    createManyBaseProfileControllerProfile: (data: CreateManyProfileDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseProfileControllerProfileData, any>({
        path: `/profile/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  todo = {
    /**
     * No description
     *
     * @name GetOneBaseTodoControllerTodo
     * @summary Retrieve a single Todo
     * @request GET:/todo/{id}
     * @response `200` `GetOneBaseTodoControllerTodoData` Get one base response
     */
    getOneBaseTodoControllerTodo: (
      id: string,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseTodoControllerTodoData, any>({
        path: `/todo/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseTodoControllerTodo
     * @summary Update a single Todo
     * @request PATCH:/todo/{id}
     * @response `200` `UpdateOneBaseTodoControllerTodoData` Response
     */
    updateOneBaseTodoControllerTodo: (id: string, data: Todo, params: RequestParams = {}) =>
      this.request<UpdateOneBaseTodoControllerTodoData, any>({
        path: `/todo/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseTodoControllerTodo
     * @summary Replace a single Todo
     * @request PUT:/todo/{id}
     * @response `200` `ReplaceOneBaseTodoControllerTodoData` Response
     */
    replaceOneBaseTodoControllerTodo: (id: string, data: Todo, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseTodoControllerTodoData, any>({
        path: `/todo/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseTodoControllerTodo
     * @summary Delete a single Todo
     * @request DELETE:/todo/{id}
     * @response `200` `DeleteOneBaseTodoControllerTodoData` Delete one base response
     */
    deleteOneBaseTodoControllerTodo: (id: string, params: RequestParams = {}) =>
      this.request<DeleteOneBaseTodoControllerTodoData, any>({
        path: `/todo/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseTodoControllerTodo
     * @summary Retrieve multiple Todos
     * @request GET:/todo
     * @response `200` `GetManyBaseTodoControllerTodoData` Get many base response
     */
    getManyBaseTodoControllerTodo: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseTodoControllerTodoData, any>({
        path: `/todo`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseTodoControllerTodo
     * @summary Create a single Todo
     * @request POST:/todo
     * @response `201` `CreateOneBaseTodoControllerTodoData` Get create one base response
     */
    createOneBaseTodoControllerTodo: (data: Todo, params: RequestParams = {}) =>
      this.request<CreateOneBaseTodoControllerTodoData, any>({
        path: `/todo`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseTodoControllerTodo
     * @summary Create multiple Todos
     * @request POST:/todo/bulk
     * @response `201` `CreateManyBaseTodoControllerTodoData` Get create many base response
     */
    createManyBaseTodoControllerTodo: (data: CreateManyTodoDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseTodoControllerTodoData, any>({
        path: `/todo/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  profileInvite = {
    /**
     * No description
     *
     * @name ProfileInviteControllerCheckInvites
     * @request GET:/profile-invite/check-invites
     * @response `200` `ProfileInviteControllerCheckInvitesData`
     */
    profileInviteControllerCheckInvites: (params: RequestParams = {}) =>
      this.request<ProfileInviteControllerCheckInvitesData, any>({
        path: `/profile-invite/check-invites`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOneBaseProfileInviteControllerProfileInvite
     * @summary Retrieve a single ProfileInvite
     * @request GET:/profile-invite/{id}
     * @response `200` `GetOneBaseProfileInviteControllerProfileInviteData` Get one base response
     */
    getOneBaseProfileInviteControllerProfileInvite: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseProfileInviteControllerProfileInviteData, any>({
        path: `/profile-invite/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseProfileInviteControllerProfileInvite
     * @summary Update a single ProfileInvite
     * @request PATCH:/profile-invite/{id}
     * @response `200` `UpdateOneBaseProfileInviteControllerProfileInviteData` Response
     */
    updateOneBaseProfileInviteControllerProfileInvite: (id: number, data: ProfileInvite, params: RequestParams = {}) =>
      this.request<UpdateOneBaseProfileInviteControllerProfileInviteData, any>({
        path: `/profile-invite/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseProfileInviteControllerProfileInvite
     * @summary Replace a single ProfileInvite
     * @request PUT:/profile-invite/{id}
     * @response `200` `ReplaceOneBaseProfileInviteControllerProfileInviteData` Response
     */
    replaceOneBaseProfileInviteControllerProfileInvite: (id: number, data: ProfileInvite, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseProfileInviteControllerProfileInviteData, any>({
        path: `/profile-invite/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseProfileInviteControllerProfileInvite
     * @summary Delete a single ProfileInvite
     * @request DELETE:/profile-invite/{id}
     * @response `200` `DeleteOneBaseProfileInviteControllerProfileInviteData` Delete one base response
     */
    deleteOneBaseProfileInviteControllerProfileInvite: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseProfileInviteControllerProfileInviteData, any>({
        path: `/profile-invite/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseProfileInviteControllerProfileInvite
     * @summary Retrieve multiple ProfileInvites
     * @request GET:/profile-invite
     * @response `200` `GetManyBaseProfileInviteControllerProfileInviteData` Get many base response
     */
    getManyBaseProfileInviteControllerProfileInvite: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseProfileInviteControllerProfileInviteData, any>({
        path: `/profile-invite`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseProfileInviteControllerProfileInvite
     * @summary Create a single ProfileInvite
     * @request POST:/profile-invite
     * @response `201` `CreateOneBaseProfileInviteControllerProfileInviteData` Get create one base response
     */
    createOneBaseProfileInviteControllerProfileInvite: (data: ProfileInvite, params: RequestParams = {}) =>
      this.request<CreateOneBaseProfileInviteControllerProfileInviteData, any>({
        path: `/profile-invite`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseProfileInviteControllerProfileInvite
     * @summary Create multiple ProfileInvites
     * @request POST:/profile-invite/bulk
     * @response `201` `CreateManyBaseProfileInviteControllerProfileInviteData` Get create many base response
     */
    createManyBaseProfileInviteControllerProfileInvite: (
      data: CreateManyProfileInviteDto,
      params: RequestParams = {},
    ) =>
      this.request<CreateManyBaseProfileInviteControllerProfileInviteData, any>({
        path: `/profile-invite/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  profileMember = {
    /**
     * No description
     *
     * @name CreateOneBaseProfileMemberControllerProfileMember
     * @summary Create a single ProfileMember
     * @request POST:/profile-member
     * @response `201` `CreateOneBaseProfileMemberControllerProfileMemberData` Get create one base response
     */
    createOneBaseProfileMemberControllerProfileMember: (data: ProfileMember, params: RequestParams = {}) =>
      this.request<CreateOneBaseProfileMemberControllerProfileMemberData, any>({
        path: `/profile-member`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseProfileMemberControllerProfileMember
     * @summary Retrieve multiple ProfileMembers
     * @request GET:/profile-member
     * @response `200` `GetManyBaseProfileMemberControllerProfileMemberData` Get many base response
     */
    getManyBaseProfileMemberControllerProfileMember: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseProfileMemberControllerProfileMemberData, any>({
        path: `/profile-member`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetOneBaseProfileMemberControllerProfileMember
     * @summary Retrieve a single ProfileMember
     * @request GET:/profile-member/{id}
     * @response `200` `GetOneBaseProfileMemberControllerProfileMemberData` Get one base response
     */
    getOneBaseProfileMemberControllerProfileMember: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseProfileMemberControllerProfileMemberData, any>({
        path: `/profile-member/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseProfileMemberControllerProfileMember
     * @summary Update a single ProfileMember
     * @request PATCH:/profile-member/{id}
     * @response `200` `UpdateOneBaseProfileMemberControllerProfileMemberData` Response
     */
    updateOneBaseProfileMemberControllerProfileMember: (id: number, data: ProfileMember, params: RequestParams = {}) =>
      this.request<UpdateOneBaseProfileMemberControllerProfileMemberData, any>({
        path: `/profile-member/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseProfileMemberControllerProfileMember
     * @summary Replace a single ProfileMember
     * @request PUT:/profile-member/{id}
     * @response `200` `ReplaceOneBaseProfileMemberControllerProfileMemberData` Response
     */
    replaceOneBaseProfileMemberControllerProfileMember: (id: number, data: ProfileMember, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseProfileMemberControllerProfileMemberData, any>({
        path: `/profile-member/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseProfileMemberControllerProfileMember
     * @summary Delete a single ProfileMember
     * @request DELETE:/profile-member/{id}
     * @response `200` `DeleteOneBaseProfileMemberControllerProfileMemberData` Delete one base response
     */
    deleteOneBaseProfileMemberControllerProfileMember: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseProfileMemberControllerProfileMemberData, any>({
        path: `/profile-member/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseProfileMemberControllerProfileMember
     * @summary Create multiple ProfileMembers
     * @request POST:/profile-member/bulk
     * @response `201` `CreateManyBaseProfileMemberControllerProfileMemberData` Get create many base response
     */
    createManyBaseProfileMemberControllerProfileMember: (
      data: CreateManyProfileMemberDto,
      params: RequestParams = {},
    ) =>
      this.request<CreateManyBaseProfileMemberControllerProfileMemberData, any>({
        path: `/profile-member/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  setList = {
    /**
     * No description
     *
     * @tags set-list
     * @name CreateOneBaseSetListControllerSetList
     * @summary Create a new set list
     * @request POST:/set-list
     * @secure
     * @response `201` `CreateOneBaseSetListControllerSetListData` Get create one base response
     */
    createOneBaseSetListControllerSetList: (data: SetList, params: RequestParams = {}) =>
      this.request<CreateOneBaseSetListControllerSetListData, any>({
        path: `/set-list`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name GetManyBaseSetListControllerSetList
     * @summary Retrieve multiple SetLists
     * @request GET:/set-list
     * @secure
     * @response `200` `GetManyBaseSetListControllerSetListData` Get many base response
     */
    getManyBaseSetListControllerSetList: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseSetListControllerSetListData, any>({
        path: `/set-list`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name GetOneBaseSetListControllerSetList
     * @summary Retrieve a single SetList
     * @request GET:/set-list/{id}
     * @secure
     * @response `200` `GetOneBaseSetListControllerSetListData` Get one base response
     */
    getOneBaseSetListControllerSetList: (
      id: string,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseSetListControllerSetListData, any>({
        path: `/set-list/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name UpdateOneBaseSetListControllerSetList
     * @summary Update a single SetList
     * @request PATCH:/set-list/{id}
     * @secure
     * @response `200` `UpdateOneBaseSetListControllerSetListData` Response
     */
    updateOneBaseSetListControllerSetList: (id: string, data: SetList, params: RequestParams = {}) =>
      this.request<UpdateOneBaseSetListControllerSetListData, any>({
        path: `/set-list/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name ReplaceOneBaseSetListControllerSetList
     * @summary Replace a single SetList
     * @request PUT:/set-list/{id}
     * @secure
     * @response `200` `ReplaceOneBaseSetListControllerSetListData` Response
     */
    replaceOneBaseSetListControllerSetList: (id: string, data: SetList, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseSetListControllerSetListData, any>({
        path: `/set-list/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name DeleteOneBaseSetListControllerSetList
     * @summary Delete a single SetList
     * @request DELETE:/set-list/{id}
     * @secure
     * @response `200` `DeleteOneBaseSetListControllerSetListData` Delete one base response
     */
    deleteOneBaseSetListControllerSetList: (id: string, params: RequestParams = {}) =>
      this.request<DeleteOneBaseSetListControllerSetListData, any>({
        path: `/set-list/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name CreateManyBaseSetListControllerSetList
     * @summary Create multiple SetLists
     * @request POST:/set-list/bulk
     * @secure
     * @response `201` `CreateManyBaseSetListControllerSetListData` Get create many base response
     */
    createManyBaseSetListControllerSetList: (data: CreateManySetListDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseSetListControllerSetListData, any>({
        path: `/set-list/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  setListGroup = {
    /**
     * No description
     *
     * @tags set-list-group
     * @name GetOneBaseSetListGroupControllerSetListGroup
     * @summary Retrieve a single SetListGroup
     * @request GET:/set-list-group/{id}
     * @secure
     * @response `200` `GetOneBaseSetListGroupControllerSetListGroupData` Get one base response
     */
    getOneBaseSetListGroupControllerSetListGroup: (
      id: string,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseSetListGroupControllerSetListGroupData, any>({
        path: `/set-list-group/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list-group
     * @name UpdateOneBaseSetListGroupControllerSetListGroup
     * @summary Update a single SetListGroup
     * @request PATCH:/set-list-group/{id}
     * @secure
     * @response `200` `UpdateOneBaseSetListGroupControllerSetListGroupData` Response
     */
    updateOneBaseSetListGroupControllerSetListGroup: (id: string, data: SetListGroup, params: RequestParams = {}) =>
      this.request<UpdateOneBaseSetListGroupControllerSetListGroupData, any>({
        path: `/set-list-group/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list-group
     * @name ReplaceOneBaseSetListGroupControllerSetListGroup
     * @summary Replace a single SetListGroup
     * @request PUT:/set-list-group/{id}
     * @secure
     * @response `200` `ReplaceOneBaseSetListGroupControllerSetListGroupData` Response
     */
    replaceOneBaseSetListGroupControllerSetListGroup: (id: string, data: SetListGroup, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseSetListGroupControllerSetListGroupData, any>({
        path: `/set-list-group/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list-group
     * @name DeleteOneBaseSetListGroupControllerSetListGroup
     * @summary Delete a single SetListGroup
     * @request DELETE:/set-list-group/{id}
     * @secure
     * @response `200` `DeleteOneBaseSetListGroupControllerSetListGroupData` Delete one base response
     */
    deleteOneBaseSetListGroupControllerSetListGroup: (id: string, params: RequestParams = {}) =>
      this.request<DeleteOneBaseSetListGroupControllerSetListGroupData, any>({
        path: `/set-list-group/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list-group
     * @name GetManyBaseSetListGroupControllerSetListGroup
     * @summary Retrieve multiple SetListGroups
     * @request GET:/set-list-group
     * @secure
     * @response `200` `GetManyBaseSetListGroupControllerSetListGroupData` Get many base response
     */
    getManyBaseSetListGroupControllerSetListGroup: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseSetListGroupControllerSetListGroupData, any>({
        path: `/set-list-group`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list-group
     * @name CreateOneBaseSetListGroupControllerSetListGroup
     * @summary Create a single SetListGroup
     * @request POST:/set-list-group
     * @secure
     * @response `201` `CreateOneBaseSetListGroupControllerSetListGroupData` Get create one base response
     */
    createOneBaseSetListGroupControllerSetListGroup: (data: SetListGroup, params: RequestParams = {}) =>
      this.request<CreateOneBaseSetListGroupControllerSetListGroupData, any>({
        path: `/set-list-group`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list-group
     * @name CreateManyBaseSetListGroupControllerSetListGroup
     * @summary Create multiple SetListGroups
     * @request POST:/set-list-group/bulk
     * @secure
     * @response `201` `CreateManyBaseSetListGroupControllerSetListGroupData` Get create many base response
     */
    createManyBaseSetListGroupControllerSetListGroup: (data: CreateManySetListGroupDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseSetListGroupControllerSetListGroupData, any>({
        path: `/set-list-group/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  setListSong = {
    /**
     * No description
     *
     * @tags set-list
     * @name CreateOneBaseSetListSongControllerSetListSong
     * @summary Create a single SetListSong
     * @request POST:/set-list-song
     * @secure
     * @response `201` `CreateOneBaseSetListSongControllerSetListSongData` Get create one base response
     */
    createOneBaseSetListSongControllerSetListSong: (data: SetListSong, params: RequestParams = {}) =>
      this.request<CreateOneBaseSetListSongControllerSetListSongData, any>({
        path: `/set-list-song`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name GetManyBaseSetListSongControllerSetListSong
     * @summary Retrieve multiple SetListSongs
     * @request GET:/set-list-song
     * @secure
     * @response `200` `GetManyBaseSetListSongControllerSetListSongData` Get many base response
     */
    getManyBaseSetListSongControllerSetListSong: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseSetListSongControllerSetListSongData, any>({
        path: `/set-list-song`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name ReplaceOneBaseSetListSongControllerSetListSong
     * @summary Replace a single SetListSong
     * @request PUT:/set-list-song/{id}
     * @secure
     * @response `200` `ReplaceOneBaseSetListSongControllerSetListSongData` Response
     */
    replaceOneBaseSetListSongControllerSetListSong: (id: number, data: SetListSong, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseSetListSongControllerSetListSongData, any>({
        path: `/set-list-song/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name GetOneBaseSetListSongControllerSetListSong
     * @summary Retrieve a single SetListSong
     * @request GET:/set-list-song/{id}
     * @secure
     * @response `200` `GetOneBaseSetListSongControllerSetListSongData` Get one base response
     */
    getOneBaseSetListSongControllerSetListSong: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseSetListSongControllerSetListSongData, any>({
        path: `/set-list-song/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name UpdateOneBaseSetListSongControllerSetListSong
     * @summary Update a single SetListSong
     * @request PATCH:/set-list-song/{id}
     * @secure
     * @response `200` `UpdateOneBaseSetListSongControllerSetListSongData` Response
     */
    updateOneBaseSetListSongControllerSetListSong: (id: number, data: SetListSong, params: RequestParams = {}) =>
      this.request<UpdateOneBaseSetListSongControllerSetListSongData, any>({
        path: `/set-list-song/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name DeleteOneBaseSetListSongControllerSetListSong
     * @summary Delete a single SetListSong
     * @request DELETE:/set-list-song/{id}
     * @secure
     * @response `200` `DeleteOneBaseSetListSongControllerSetListSongData` Delete one base response
     */
    deleteOneBaseSetListSongControllerSetListSong: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseSetListSongControllerSetListSongData, any>({
        path: `/set-list-song/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags set-list
     * @name CreateManyBaseSetListSongControllerSetListSong
     * @summary Create multiple SetListSongs
     * @request POST:/set-list-song/bulk
     * @secure
     * @response `201` `CreateManyBaseSetListSongControllerSetListSongData` Get create many base response
     */
    createManyBaseSetListSongControllerSetListSong: (data: CreateManySetListSongDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseSetListSongControllerSetListSongData, any>({
        path: `/set-list-song/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags users
     * @name UserControllerAuthenticateSpotify
     * @summary Authenticate user with Spotify
     * @request POST:/users/spotify
     * @response `200` `UserControllerAuthenticateSpotifyData`
     */
    userControllerAuthenticateSpotify: (data: SpotifyConnectDto, params: RequestParams = {}) =>
      this.request<UserControllerAuthenticateSpotifyData, any>({
        path: `/users/spotify`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerConnectSpotify
     * @summary Connect Spotify account
     * @request POST:/users/spotify-connect
     * @response `200` `UserControllerConnectSpotifyData`
     */
    userControllerConnectSpotify: (data: SpotifyConnectDto, params: RequestParams = {}) =>
      this.request<UserControllerConnectSpotifyData, any>({
        path: `/users/spotify-connect`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerRefreshSpotifyToken
     * @summary Refresh Spotify token
     * @request POST:/users/spotify-refresh
     * @response `200` `UserControllerRefreshSpotifyTokenData`
     */
    userControllerRefreshSpotifyToken: (params: RequestParams = {}) =>
      this.request<UserControllerRefreshSpotifyTokenData, any>({
        path: `/users/spotify-refresh`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerUpdate
     * @summary Update user information
     * @request PATCH:/users/{id}
     * @response `200` `UserControllerUpdateData` User updated successfully
     * @response `400` `void` Bad request
     */
    userControllerUpdate: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<UserControllerUpdateData, void>({
        path: `/users/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerLogin
     * @summary User login
     * @request POST:/users/login
     * @response `200` `UserControllerLoginData` User logged in successfully
     * @response `401` `void` Unauthorized
     */
    userControllerLogin: (data: LoginDto, params: RequestParams = {}) =>
      this.request<UserControllerLoginData, void>({
        path: `/users/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerSignup
     * @summary User signup
     * @request POST:/users/signup
     * @response `201` `UserControllerSignupData` User registered successfully
     */
    userControllerSignup: (data: SignupDto, params: RequestParams = {}) =>
      this.request<UserControllerSignupData, any>({
        path: `/users/signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerRefreshToken
     * @summary Refresh user token
     * @request POST:/users/refresh
     * @response `200` `UserControllerRefreshTokenData` Token refreshed
     */
    userControllerRefreshToken: (data: RefreshTokenDto, params: RequestParams = {}) =>
      this.request<UserControllerRefreshTokenData, any>({
        path: `/users/refresh`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerLogout
     * @summary User logout
     * @request POST:/users/logout
     * @response `200` `UserControllerLogoutData` User logged out
     */
    userControllerLogout: (params: RequestParams = {}) =>
      this.request<UserControllerLogoutData, any>({
        path: `/users/logout`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerChangePassword
     * @summary Change user password
     * @request POST:/users/change-password
     * @response `200` `UserControllerChangePasswordData` Password changed successfully
     */
    userControllerChangePassword: (data: ChangePasswordDto, params: RequestParams = {}) =>
      this.request<UserControllerChangePasswordData, any>({
        path: `/users/change-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerResetPassword
     * @summary Reset user password
     * @request POST:/users/password-reset
     * @response `200` `UserControllerResetPasswordData` Password reset process initiated
     */
    userControllerResetPassword: (params: RequestParams = {}) =>
      this.request<UserControllerResetPasswordData, any>({
        path: `/users/password-reset`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags users
     * @name UserControllerConfirmAccount
     * @summary Confirm account
     * @request GET:/users/confirm-account/{token}
     * @response `200` `UserControllerConfirmAccountData` Account confirmed successfully
     */
    userControllerConfirmAccount: (token: string, params: RequestParams = {}) =>
      this.request<UserControllerConfirmAccountData, any>({
        path: `/users/confirm-account/${token}`,
        method: "GET",
        ...params,
      }),
  };
  song = {
    /**
     * No description
     *
     * @name SongControllerSearchTrack
     * @request GET:/song/search
     * @response `200` `SongControllerSearchTrackData`
     */
    songControllerSearchTrack: (
      query: {
        q: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SongControllerSearchTrackData, any>({
        path: `/song/search`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name SongControllerLoadMetaData
     * @request POST:/song/load-metadata
     * @response `201` `SongControllerLoadMetaDataData`
     */
    songControllerLoadMetaData: (data: RequestMetadataDto, params: RequestParams = {}) =>
      this.request<SongControllerLoadMetaDataData, any>({
        path: `/song/load-metadata`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SongControllerGetRecommendations
     * @request POST:/song/recommendations
     * @response `201` `SongControllerGetRecommendationsData`
     */
    songControllerGetRecommendations: (data: GetRecommendationsDto, params: RequestParams = {}) =>
      this.request<SongControllerGetRecommendationsData, any>({
        path: `/song/recommendations`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  chat = {
    /**
     * No description
     *
     * @name GetOneBaseChatControllerChat
     * @summary Retrieve a single Chat
     * @request GET:/chat/{id}
     * @response `200` `GetOneBaseChatControllerChatData` Get one base response
     */
    getOneBaseChatControllerChat: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseChatControllerChatData, any>({
        path: `/chat/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseChatControllerChat
     * @summary Update a single Chat
     * @request PATCH:/chat/{id}
     * @response `200` `UpdateOneBaseChatControllerChatData` Response
     */
    updateOneBaseChatControllerChat: (id: number, data: Chat, params: RequestParams = {}) =>
      this.request<UpdateOneBaseChatControllerChatData, any>({
        path: `/chat/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseChatControllerChat
     * @summary Replace a single Chat
     * @request PUT:/chat/{id}
     * @response `200` `ReplaceOneBaseChatControllerChatData` Response
     */
    replaceOneBaseChatControllerChat: (id: number, data: Chat, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseChatControllerChatData, any>({
        path: `/chat/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseChatControllerChat
     * @summary Delete a single Chat
     * @request DELETE:/chat/{id}
     * @response `200` `DeleteOneBaseChatControllerChatData` Delete one base response
     */
    deleteOneBaseChatControllerChat: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseChatControllerChatData, any>({
        path: `/chat/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseChatControllerChat
     * @summary Retrieve multiple Chats
     * @request GET:/chat
     * @response `200` `GetManyBaseChatControllerChatData` Get many base response
     */
    getManyBaseChatControllerChat: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseChatControllerChatData, any>({
        path: `/chat`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseChatControllerChat
     * @summary Create a single Chat
     * @request POST:/chat
     * @response `201` `CreateOneBaseChatControllerChatData` Get create one base response
     */
    createOneBaseChatControllerChat: (data: Chat, params: RequestParams = {}) =>
      this.request<CreateOneBaseChatControllerChatData, any>({
        path: `/chat`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseChatControllerChat
     * @summary Create multiple Chats
     * @request POST:/chat/bulk
     * @response `201` `CreateManyBaseChatControllerChatData` Get create many base response
     */
    createManyBaseChatControllerChat: (data: CreateManyChatDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseChatControllerChatData, any>({
        path: `/chat/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  chatMessage = {
    /**
     * No description
     *
     * @name GetOneBaseChatMessageControllerChatMessage
     * @summary Retrieve a single ChatMessage
     * @request GET:/chat-message/{id}
     * @response `200` `GetOneBaseChatMessageControllerChatMessageData` Get one base response
     */
    getOneBaseChatMessageControllerChatMessage: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseChatMessageControllerChatMessageData, any>({
        path: `/chat-message/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseChatMessageControllerChatMessage
     * @summary Update a single ChatMessage
     * @request PATCH:/chat-message/{id}
     * @response `200` `UpdateOneBaseChatMessageControllerChatMessageData` Response
     */
    updateOneBaseChatMessageControllerChatMessage: (id: number, data: ChatMessage, params: RequestParams = {}) =>
      this.request<UpdateOneBaseChatMessageControllerChatMessageData, any>({
        path: `/chat-message/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseChatMessageControllerChatMessage
     * @summary Replace a single ChatMessage
     * @request PUT:/chat-message/{id}
     * @response `200` `ReplaceOneBaseChatMessageControllerChatMessageData` Response
     */
    replaceOneBaseChatMessageControllerChatMessage: (id: number, data: ChatMessage, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseChatMessageControllerChatMessageData, any>({
        path: `/chat-message/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseChatMessageControllerChatMessage
     * @summary Delete a single ChatMessage
     * @request DELETE:/chat-message/{id}
     * @response `200` `DeleteOneBaseChatMessageControllerChatMessageData` Delete one base response
     */
    deleteOneBaseChatMessageControllerChatMessage: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseChatMessageControllerChatMessageData, any>({
        path: `/chat-message/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseChatMessageControllerChatMessage
     * @summary Retrieve multiple ChatMessages
     * @request GET:/chat-message
     * @response `200` `GetManyBaseChatMessageControllerChatMessageData` Get many base response
     */
    getManyBaseChatMessageControllerChatMessage: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseChatMessageControllerChatMessageData, any>({
        path: `/chat-message`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseChatMessageControllerChatMessage
     * @summary Create a single ChatMessage
     * @request POST:/chat-message
     * @response `201` `CreateOneBaseChatMessageControllerChatMessageData` Get create one base response
     */
    createOneBaseChatMessageControllerChatMessage: (data: ChatMessage, params: RequestParams = {}) =>
      this.request<CreateOneBaseChatMessageControllerChatMessageData, any>({
        path: `/chat-message`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseChatMessageControllerChatMessage
     * @summary Create multiple ChatMessages
     * @request POST:/chat-message/bulk
     * @response `201` `CreateManyBaseChatMessageControllerChatMessageData` Get create many base response
     */
    createManyBaseChatMessageControllerChatMessage: (data: CreateManyChatMessageDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseChatMessageControllerChatMessageData, any>({
        path: `/chat-message/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  venues = {
    /**
     * No description
     *
     * @name GetOneBaseVenueControllerVenue
     * @summary Retrieve a single Venue
     * @request GET:/venues/{id}
     * @response `200` `GetOneBaseVenueControllerVenueData` Get one base response
     */
    getOneBaseVenueControllerVenue: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseVenueControllerVenueData, any>({
        path: `/venues/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseVenueControllerVenue
     * @summary Update a single Venue
     * @request PATCH:/venues/{id}
     * @response `200` `UpdateOneBaseVenueControllerVenueData` Response
     */
    updateOneBaseVenueControllerVenue: (id: number, data: Venue, params: RequestParams = {}) =>
      this.request<UpdateOneBaseVenueControllerVenueData, any>({
        path: `/venues/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseVenueControllerVenue
     * @summary Replace a single Venue
     * @request PUT:/venues/{id}
     * @response `200` `ReplaceOneBaseVenueControllerVenueData` Response
     */
    replaceOneBaseVenueControllerVenue: (id: number, data: Venue, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseVenueControllerVenueData, any>({
        path: `/venues/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseVenueControllerVenue
     * @summary Delete a single Venue
     * @request DELETE:/venues/{id}
     * @response `200` `DeleteOneBaseVenueControllerVenueData` Delete one base response
     */
    deleteOneBaseVenueControllerVenue: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseVenueControllerVenueData, any>({
        path: `/venues/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseVenueControllerVenue
     * @summary Retrieve multiple Venues
     * @request GET:/venues
     * @response `200` `GetManyBaseVenueControllerVenueData` Get many base response
     */
    getManyBaseVenueControllerVenue: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseVenueControllerVenueData, any>({
        path: `/venues`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseVenueControllerVenue
     * @summary Create a single Venue
     * @request POST:/venues
     * @response `201` `CreateOneBaseVenueControllerVenueData` Get create one base response
     */
    createOneBaseVenueControllerVenue: (data: Venue, params: RequestParams = {}) =>
      this.request<CreateOneBaseVenueControllerVenueData, any>({
        path: `/venues`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseVenueControllerVenue
     * @summary Create multiple Venues
     * @request POST:/venues/bulk
     * @response `201` `CreateManyBaseVenueControllerVenueData` Get create many base response
     */
    createManyBaseVenueControllerVenue: (data: CreateManyVenueDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseVenueControllerVenueData, any>({
        path: `/venues/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  chatMessageClassification = {
    /**
     * No description
     *
     * @name GetOneBaseChatMessageClassificationControllerChatMessageClassification
     * @summary Retrieve a single ChatMessageClassification
     * @request GET:/chat-message-classification/{id}
     * @response `200` `GetOneBaseChatMessageClassificationControllerChatMessageClassificationData` Get one base response
     */
    getOneBaseChatMessageClassificationControllerChatMessageClassification: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseChatMessageClassificationControllerChatMessageClassificationData, any>({
        path: `/chat-message-classification/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseChatMessageClassificationControllerChatMessageClassification
     * @summary Update a single ChatMessageClassification
     * @request PATCH:/chat-message-classification/{id}
     * @response `200` `UpdateOneBaseChatMessageClassificationControllerChatMessageClassificationData` Response
     */
    updateOneBaseChatMessageClassificationControllerChatMessageClassification: (
      id: number,
      data: ChatMessageClassification,
      params: RequestParams = {},
    ) =>
      this.request<UpdateOneBaseChatMessageClassificationControllerChatMessageClassificationData, any>({
        path: `/chat-message-classification/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseChatMessageClassificationControllerChatMessageClassification
     * @summary Replace a single ChatMessageClassification
     * @request PUT:/chat-message-classification/{id}
     * @response `200` `ReplaceOneBaseChatMessageClassificationControllerChatMessageClassificationData` Response
     */
    replaceOneBaseChatMessageClassificationControllerChatMessageClassification: (
      id: number,
      data: ChatMessageClassification,
      params: RequestParams = {},
    ) =>
      this.request<ReplaceOneBaseChatMessageClassificationControllerChatMessageClassificationData, any>({
        path: `/chat-message-classification/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseChatMessageClassificationControllerChatMessageClassification
     * @summary Delete a single ChatMessageClassification
     * @request DELETE:/chat-message-classification/{id}
     * @response `200` `DeleteOneBaseChatMessageClassificationControllerChatMessageClassificationData` Delete one base response
     */
    deleteOneBaseChatMessageClassificationControllerChatMessageClassification: (
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<DeleteOneBaseChatMessageClassificationControllerChatMessageClassificationData, any>({
        path: `/chat-message-classification/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseChatMessageClassificationControllerChatMessageClassification
     * @summary Retrieve multiple ChatMessageClassifications
     * @request GET:/chat-message-classification
     * @response `200` `GetManyBaseChatMessageClassificationControllerChatMessageClassificationData` Get many base response
     */
    getManyBaseChatMessageClassificationControllerChatMessageClassification: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseChatMessageClassificationControllerChatMessageClassificationData, any>({
        path: `/chat-message-classification`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseChatMessageClassificationControllerChatMessageClassification
     * @summary Create a single ChatMessageClassification
     * @request POST:/chat-message-classification
     * @response `201` `CreateOneBaseChatMessageClassificationControllerChatMessageClassificationData` Get create one base response
     */
    createOneBaseChatMessageClassificationControllerChatMessageClassification: (
      data: ChatMessageClassification,
      params: RequestParams = {},
    ) =>
      this.request<CreateOneBaseChatMessageClassificationControllerChatMessageClassificationData, any>({
        path: `/chat-message-classification`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseChatMessageClassificationControllerChatMessageClassification
     * @summary Create multiple ChatMessageClassifications
     * @request POST:/chat-message-classification/bulk
     * @response `201` `CreateManyBaseChatMessageClassificationControllerChatMessageClassificationData` Get create many base response
     */
    createManyBaseChatMessageClassificationControllerChatMessageClassification: (
      data: CreateManyChatMessageClassificationDto,
      params: RequestParams = {},
    ) =>
      this.request<CreateManyBaseChatMessageClassificationControllerChatMessageClassificationData, any>({
        path: `/chat-message-classification/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @name GetOneBaseDashboardControllerDashboardView
     * @summary Retrieve a single DashboardView
     * @request GET:/dashboard/{id}
     * @response `200` `GetOneBaseDashboardControllerDashboardViewData` Get one base response
     */
    getOneBaseDashboardControllerDashboardView: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseDashboardControllerDashboardViewData, any>({
        path: `/dashboard/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseDashboardControllerDashboardView
     * @summary Update a single DashboardView
     * @request PATCH:/dashboard/{id}
     * @response `200` `UpdateOneBaseDashboardControllerDashboardViewData` Response
     */
    updateOneBaseDashboardControllerDashboardView: (id: number, data: DashboardView, params: RequestParams = {}) =>
      this.request<UpdateOneBaseDashboardControllerDashboardViewData, any>({
        path: `/dashboard/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseDashboardControllerDashboardView
     * @summary Replace a single DashboardView
     * @request PUT:/dashboard/{id}
     * @response `200` `ReplaceOneBaseDashboardControllerDashboardViewData` Response
     */
    replaceOneBaseDashboardControllerDashboardView: (id: number, data: DashboardView, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseDashboardControllerDashboardViewData, any>({
        path: `/dashboard/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseDashboardControllerDashboardView
     * @summary Delete a single DashboardView
     * @request DELETE:/dashboard/{id}
     * @response `200` `DeleteOneBaseDashboardControllerDashboardViewData` Delete one base response
     */
    deleteOneBaseDashboardControllerDashboardView: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseDashboardControllerDashboardViewData, any>({
        path: `/dashboard/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseDashboardControllerDashboardView
     * @summary Retrieve multiple DashboardViews
     * @request GET:/dashboard
     * @response `200` `GetManyBaseDashboardControllerDashboardViewData` Get many base response
     */
    getManyBaseDashboardControllerDashboardView: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseDashboardControllerDashboardViewData, any>({
        path: `/dashboard`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseDashboardControllerDashboardView
     * @summary Create a single DashboardView
     * @request POST:/dashboard
     * @response `201` `CreateOneBaseDashboardControllerDashboardViewData` Get create one base response
     */
    createOneBaseDashboardControllerDashboardView: (data: DashboardView, params: RequestParams = {}) =>
      this.request<CreateOneBaseDashboardControllerDashboardViewData, any>({
        path: `/dashboard`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseDashboardControllerDashboardView
     * @summary Create multiple DashboardViews
     * @request POST:/dashboard/bulk
     * @response `201` `CreateManyBaseDashboardControllerDashboardViewData` Get create many base response
     */
    createManyBaseDashboardControllerDashboardView: (data: CreateManyDashboardViewDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseDashboardControllerDashboardViewData, any>({
        path: `/dashboard/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  invoice = {
    /**
     * No description
     *
     * @name GetOneBaseInvoiceControllerInvoice
     * @summary Retrieve a single Invoice
     * @request GET:/invoice/{id}
     * @response `200` `GetOneBaseInvoiceControllerInvoiceData` Get one base response
     */
    getOneBaseInvoiceControllerInvoice: (
      id: string,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseInvoiceControllerInvoiceData, any>({
        path: `/invoice/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseInvoiceControllerInvoice
     * @summary Update a single Invoice
     * @request PATCH:/invoice/{id}
     * @response `200` `UpdateOneBaseInvoiceControllerInvoiceData` Response
     */
    updateOneBaseInvoiceControllerInvoice: (id: string, data: Invoice, params: RequestParams = {}) =>
      this.request<UpdateOneBaseInvoiceControllerInvoiceData, any>({
        path: `/invoice/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseInvoiceControllerInvoice
     * @summary Replace a single Invoice
     * @request PUT:/invoice/{id}
     * @response `200` `ReplaceOneBaseInvoiceControllerInvoiceData` Response
     */
    replaceOneBaseInvoiceControllerInvoice: (id: string, data: Invoice, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseInvoiceControllerInvoiceData, any>({
        path: `/invoice/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseInvoiceControllerInvoice
     * @summary Delete a single Invoice
     * @request DELETE:/invoice/{id}
     * @response `200` `DeleteOneBaseInvoiceControllerInvoiceData` Delete one base response
     */
    deleteOneBaseInvoiceControllerInvoice: (id: string, params: RequestParams = {}) =>
      this.request<DeleteOneBaseInvoiceControllerInvoiceData, any>({
        path: `/invoice/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseInvoiceControllerInvoice
     * @summary Retrieve multiple Invoices
     * @request GET:/invoice
     * @response `200` `GetManyBaseInvoiceControllerInvoiceData` Get many base response
     */
    getManyBaseInvoiceControllerInvoice: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseInvoiceControllerInvoiceData, any>({
        path: `/invoice`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseInvoiceControllerInvoice
     * @summary Create a single Invoice
     * @request POST:/invoice
     * @response `201` `CreateOneBaseInvoiceControllerInvoiceData` Get create one base response
     */
    createOneBaseInvoiceControllerInvoice: (data: Invoice, params: RequestParams = {}) =>
      this.request<CreateOneBaseInvoiceControllerInvoiceData, any>({
        path: `/invoice`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseInvoiceControllerInvoice
     * @summary Create multiple Invoices
     * @request POST:/invoice/bulk
     * @response `201` `CreateManyBaseInvoiceControllerInvoiceData` Get create many base response
     */
    createManyBaseInvoiceControllerInvoice: (data: CreateManyInvoiceDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseInvoiceControllerInvoiceData, any>({
        path: `/invoice/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  invoiceLineItem = {
    /**
     * No description
     *
     * @name GetOneBaseInvoiceLineItemControllerInvoiceLineItem
     * @summary Retrieve a single InvoiceLineItem
     * @request GET:/invoice-line-item/{id}
     * @response `200` `GetOneBaseInvoiceLineItemControllerInvoiceLineItemData` Get one base response
     */
    getOneBaseInvoiceLineItemControllerInvoiceLineItem: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseInvoiceLineItemControllerInvoiceLineItemData, any>({
        path: `/invoice-line-item/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseInvoiceLineItemControllerInvoiceLineItem
     * @summary Update a single InvoiceLineItem
     * @request PATCH:/invoice-line-item/{id}
     * @response `200` `UpdateOneBaseInvoiceLineItemControllerInvoiceLineItemData` Response
     */
    updateOneBaseInvoiceLineItemControllerInvoiceLineItem: (
      id: number,
      data: InvoiceLineItem,
      params: RequestParams = {},
    ) =>
      this.request<UpdateOneBaseInvoiceLineItemControllerInvoiceLineItemData, any>({
        path: `/invoice-line-item/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseInvoiceLineItemControllerInvoiceLineItem
     * @summary Replace a single InvoiceLineItem
     * @request PUT:/invoice-line-item/{id}
     * @response `200` `ReplaceOneBaseInvoiceLineItemControllerInvoiceLineItemData` Response
     */
    replaceOneBaseInvoiceLineItemControllerInvoiceLineItem: (
      id: number,
      data: InvoiceLineItem,
      params: RequestParams = {},
    ) =>
      this.request<ReplaceOneBaseInvoiceLineItemControllerInvoiceLineItemData, any>({
        path: `/invoice-line-item/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseInvoiceLineItemControllerInvoiceLineItem
     * @summary Delete a single InvoiceLineItem
     * @request DELETE:/invoice-line-item/{id}
     * @response `200` `DeleteOneBaseInvoiceLineItemControllerInvoiceLineItemData` Delete one base response
     */
    deleteOneBaseInvoiceLineItemControllerInvoiceLineItem: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseInvoiceLineItemControllerInvoiceLineItemData, any>({
        path: `/invoice-line-item/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseInvoiceLineItemControllerInvoiceLineItem
     * @summary Retrieve multiple InvoiceLineItems
     * @request GET:/invoice-line-item
     * @response `200` `GetManyBaseInvoiceLineItemControllerInvoiceLineItemData` Get many base response
     */
    getManyBaseInvoiceLineItemControllerInvoiceLineItem: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseInvoiceLineItemControllerInvoiceLineItemData, any>({
        path: `/invoice-line-item`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseInvoiceLineItemControllerInvoiceLineItem
     * @summary Create a single InvoiceLineItem
     * @request POST:/invoice-line-item
     * @response `201` `CreateOneBaseInvoiceLineItemControllerInvoiceLineItemData` Get create one base response
     */
    createOneBaseInvoiceLineItemControllerInvoiceLineItem: (data: InvoiceLineItem, params: RequestParams = {}) =>
      this.request<CreateOneBaseInvoiceLineItemControllerInvoiceLineItemData, any>({
        path: `/invoice-line-item`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseInvoiceLineItemControllerInvoiceLineItem
     * @summary Create multiple InvoiceLineItems
     * @request POST:/invoice-line-item/bulk
     * @response `201` `CreateManyBaseInvoiceLineItemControllerInvoiceLineItemData` Get create many base response
     */
    createManyBaseInvoiceLineItemControllerInvoiceLineItem: (
      data: CreateManyInvoiceLineItemDto,
      params: RequestParams = {},
    ) =>
      this.request<CreateManyBaseInvoiceLineItemControllerInvoiceLineItemData, any>({
        path: `/invoice-line-item/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  customer = {
    /**
     * No description
     *
     * @name GetOneBaseCustomerControllerCustomer
     * @summary Retrieve a single Customer
     * @request GET:/customer/{id}
     * @response `200` `GetOneBaseCustomerControllerCustomerData` Get one base response
     */
    getOneBaseCustomerControllerCustomer: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseCustomerControllerCustomerData, any>({
        path: `/customer/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseCustomerControllerCustomer
     * @summary Update a single Customer
     * @request PATCH:/customer/{id}
     * @response `200` `UpdateOneBaseCustomerControllerCustomerData` Response
     */
    updateOneBaseCustomerControllerCustomer: (id: number, data: Customer, params: RequestParams = {}) =>
      this.request<UpdateOneBaseCustomerControllerCustomerData, any>({
        path: `/customer/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseCustomerControllerCustomer
     * @summary Replace a single Customer
     * @request PUT:/customer/{id}
     * @response `200` `ReplaceOneBaseCustomerControllerCustomerData` Response
     */
    replaceOneBaseCustomerControllerCustomer: (id: number, data: Customer, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseCustomerControllerCustomerData, any>({
        path: `/customer/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseCustomerControllerCustomer
     * @summary Delete a single Customer
     * @request DELETE:/customer/{id}
     * @response `200` `DeleteOneBaseCustomerControllerCustomerData` Delete one base response
     */
    deleteOneBaseCustomerControllerCustomer: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseCustomerControllerCustomerData, any>({
        path: `/customer/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseCustomerControllerCustomer
     * @summary Retrieve multiple Customers
     * @request GET:/customer
     * @response `200` `GetManyBaseCustomerControllerCustomerData` Get many base response
     */
    getManyBaseCustomerControllerCustomer: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseCustomerControllerCustomerData, any>({
        path: `/customer`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseCustomerControllerCustomer
     * @summary Create a single Customer
     * @request POST:/customer
     * @response `201` `CreateOneBaseCustomerControllerCustomerData` Get create one base response
     */
    createOneBaseCustomerControllerCustomer: (data: Customer, params: RequestParams = {}) =>
      this.request<CreateOneBaseCustomerControllerCustomerData, any>({
        path: `/customer`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseCustomerControllerCustomer
     * @summary Create multiple Customers
     * @request POST:/customer/bulk
     * @response `201` `CreateManyBaseCustomerControllerCustomerData` Get create many base response
     */
    createManyBaseCustomerControllerCustomer: (data: CreateManyCustomerDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseCustomerControllerCustomerData, any>({
        path: `/customer/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  invoiceContent = {
    /**
     * No description
     *
     * @name GetOneBaseInvoiceContentControllerInvoiceContent
     * @summary Retrieve a single InvoiceContent
     * @request GET:/invoice-content/{id}
     * @response `200` `GetOneBaseInvoiceContentControllerInvoiceContentData` Get one base response
     */
    getOneBaseInvoiceContentControllerInvoiceContent: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseInvoiceContentControllerInvoiceContentData, any>({
        path: `/invoice-content/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseInvoiceContentControllerInvoiceContent
     * @summary Update a single InvoiceContent
     * @request PATCH:/invoice-content/{id}
     * @response `200` `UpdateOneBaseInvoiceContentControllerInvoiceContentData` Response
     */
    updateOneBaseInvoiceContentControllerInvoiceContent: (
      id: number,
      data: InvoiceContent,
      params: RequestParams = {},
    ) =>
      this.request<UpdateOneBaseInvoiceContentControllerInvoiceContentData, any>({
        path: `/invoice-content/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseInvoiceContentControllerInvoiceContent
     * @summary Replace a single InvoiceContent
     * @request PUT:/invoice-content/{id}
     * @response `200` `ReplaceOneBaseInvoiceContentControllerInvoiceContentData` Response
     */
    replaceOneBaseInvoiceContentControllerInvoiceContent: (
      id: number,
      data: InvoiceContent,
      params: RequestParams = {},
    ) =>
      this.request<ReplaceOneBaseInvoiceContentControllerInvoiceContentData, any>({
        path: `/invoice-content/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseInvoiceContentControllerInvoiceContent
     * @summary Delete a single InvoiceContent
     * @request DELETE:/invoice-content/{id}
     * @response `200` `DeleteOneBaseInvoiceContentControllerInvoiceContentData` Delete one base response
     */
    deleteOneBaseInvoiceContentControllerInvoiceContent: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseInvoiceContentControllerInvoiceContentData, any>({
        path: `/invoice-content/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseInvoiceContentControllerInvoiceContent
     * @summary Retrieve multiple InvoiceContents
     * @request GET:/invoice-content
     * @response `200` `GetManyBaseInvoiceContentControllerInvoiceContentData` Get many base response
     */
    getManyBaseInvoiceContentControllerInvoiceContent: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseInvoiceContentControllerInvoiceContentData, any>({
        path: `/invoice-content`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseInvoiceContentControllerInvoiceContent
     * @summary Create a single InvoiceContent
     * @request POST:/invoice-content
     * @response `201` `CreateOneBaseInvoiceContentControllerInvoiceContentData` Get create one base response
     */
    createOneBaseInvoiceContentControllerInvoiceContent: (data: InvoiceContent, params: RequestParams = {}) =>
      this.request<CreateOneBaseInvoiceContentControllerInvoiceContentData, any>({
        path: `/invoice-content`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseInvoiceContentControllerInvoiceContent
     * @summary Create multiple InvoiceContents
     * @request POST:/invoice-content/bulk
     * @response `201` `CreateManyBaseInvoiceContentControllerInvoiceContentData` Get create many base response
     */
    createManyBaseInvoiceContentControllerInvoiceContent: (
      data: CreateManyInvoiceContentDto,
      params: RequestParams = {},
    ) =>
      this.request<CreateManyBaseInvoiceContentControllerInvoiceContentData, any>({
        path: `/invoice-content/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  repertoire = {
    /**
     * No description
     *
     * @name GetOneBaseRepertoireControllerRepertoireView
     * @summary Retrieve a single RepertoireView
     * @request GET:/repertoire/{id}
     * @response `200` `GetOneBaseRepertoireControllerRepertoireViewData` Get one base response
     */
    getOneBaseRepertoireControllerRepertoireView: (
      id: number,
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOneBaseRepertoireControllerRepertoireViewData, any>({
        path: `/repertoire/${id}`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateOneBaseRepertoireControllerRepertoireView
     * @summary Update a single RepertoireView
     * @request PATCH:/repertoire/{id}
     * @response `200` `UpdateOneBaseRepertoireControllerRepertoireViewData` Response
     */
    updateOneBaseRepertoireControllerRepertoireView: (id: number, data: RepertoireView, params: RequestParams = {}) =>
      this.request<UpdateOneBaseRepertoireControllerRepertoireViewData, any>({
        path: `/repertoire/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReplaceOneBaseRepertoireControllerRepertoireView
     * @summary Replace a single RepertoireView
     * @request PUT:/repertoire/{id}
     * @response `200` `ReplaceOneBaseRepertoireControllerRepertoireViewData` Response
     */
    replaceOneBaseRepertoireControllerRepertoireView: (id: number, data: RepertoireView, params: RequestParams = {}) =>
      this.request<ReplaceOneBaseRepertoireControllerRepertoireViewData, any>({
        path: `/repertoire/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteOneBaseRepertoireControllerRepertoireView
     * @summary Delete a single RepertoireView
     * @request DELETE:/repertoire/{id}
     * @response `200` `DeleteOneBaseRepertoireControllerRepertoireViewData` Delete one base response
     */
    deleteOneBaseRepertoireControllerRepertoireView: (id: number, params: RequestParams = {}) =>
      this.request<DeleteOneBaseRepertoireControllerRepertoireViewData, any>({
        path: `/repertoire/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManyBaseRepertoireControllerRepertoireView
     * @summary Retrieve multiple RepertoireViews
     * @request GET:/repertoire
     * @response `200` `GetManyBaseRepertoireControllerRepertoireViewData` Get many base response
     */
    getManyBaseRepertoireControllerRepertoireView: (
      query?: {
        /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
        fields?: string[];
        /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
        s?: string;
        /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
        filter?: string[];
        /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
        or?: string[];
        /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
        sort?: string[];
        /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
        join?: string[];
        /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
        limit?: number;
        /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
        offset?: number;
        /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
        page?: number;
        /**
         * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
         * @min 0
         * @max 1
         */
        cache?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetManyBaseRepertoireControllerRepertoireViewData, any>({
        path: `/repertoire`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateOneBaseRepertoireControllerRepertoireView
     * @summary Create a single RepertoireView
     * @request POST:/repertoire
     * @response `201` `CreateOneBaseRepertoireControllerRepertoireViewData` Get create one base response
     */
    createOneBaseRepertoireControllerRepertoireView: (data: RepertoireView, params: RequestParams = {}) =>
      this.request<CreateOneBaseRepertoireControllerRepertoireViewData, any>({
        path: `/repertoire`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateManyBaseRepertoireControllerRepertoireView
     * @summary Create multiple RepertoireViews
     * @request POST:/repertoire/bulk
     * @response `201` `CreateManyBaseRepertoireControllerRepertoireViewData` Get create many base response
     */
    createManyBaseRepertoireControllerRepertoireView: (data: CreateManyRepertoireViewDto, params: RequestParams = {}) =>
      this.request<CreateManyBaseRepertoireControllerRepertoireViewData, any>({
        path: `/repertoire/bulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
