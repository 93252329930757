import { Todo } from '../../Api';
import { LOGOUT_SUCCESS } from '../auth/actions';
import * as actions from './actions';
const initialState: {
    todos: Todo[],
    loading: boolean,
    error: any

} = {
    todos: [],
    loading: false,
    error: null,

}

function todoReducer (state = initialState, action){
    switch (action.type) {
        case LOGOUT_SUCCESS:
            return initialState;
        case actions.SET_TODOS: {
            // Create a map of new todos for quick access
            const newTodosMap = new Map(action.payload.map(todo => [todo.id, todo]));

            // Replace existing todos and add new ones
            const updatedTodos = state.todos.map(todo => newTodosMap.get(todo.id) || todo);

            // Add todos that are in the new list but not in the existing state
            action.payload.forEach(todo => {
                if (!state.todos.find(t => t.id === todo.id)) {
                    updatedTodos.push(todo);
                }
            });

            return {
                ...state,
                todos: updatedTodos,
            };
        }
        case actions.DELETE_TODO:
        case actions.UPDATE_TODO:
        case actions.GET_TODOS:
        case actions.GET_TODO:
            return {
                ...state,
                loading: true,
            };
        case actions.UPDATE_TODO_SUCCESS: {
            const updatedTodos = state.todos.map(todo => todo.id === action.payload.id ? action.payload : todo);
            return {
                ...state,
                loading: false,
                todos: updatedTodos,
            };
        }
        case actions.CREATE_TODO_SUCCESS:
            return {
                ...state,
                loading: false,
                todos: [...state.todos, action.payload],
            };
        case actions.DELETE_TODO_SUCCESS:
            return {
                ...state,
                loading: false,
                todos: [...state.todos.filter((todo:any) => todo.id !== action.payload)],
            };
        case actions.GET_TODOS_SUCCESS:
            return {
                ...state,
                loading: false,
                todos: action.payload,
            };
        case actions.GET_TODO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
        }

}


export default todoReducer;