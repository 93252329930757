import React from "react";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";

import Home from "./Home/Home";
import { SetLists } from "./SetLists/SetLists";
import { SetListBuilder } from "./SetLists/SetListBuilder";
import { AuthenticatedView } from "../components/AuthenticatedView/AuthenticatedView";
import { Account } from "../components/Account/Account";
import { Bands } from "./Bands/Bands";
import { PersonalCalendar } from "./Calendar/PersonalCalendar";
import { BandCalendar } from "./Calendar/BandCalendar";
import { ComingSoon } from "../components/ComingSoon/ComingSoon";
import { Todo } from "./Todo/Todo";
import { OAuthSpotify } from "./Auth/OAuth/OAuthSpotify";
import { Finances } from "./Finances/Finances";
import { InvoiceView } from "./Finances/Invoice";
import { history } from "../redux";
import { Repertoire } from "./Repertoire/Repertoire";
import { GigOverview } from "./Gigs/GigOverview";
import { ConfirmAccount } from "./Auth/ConfirmAccount";

export class AppRouter extends React.Component {
  render() {
    return (
      <HistoryRouter history={history as any} basename={process.env.PUBLIC_URL}>
        
        <Routes> 

          <Route
            path="/confirm-account/:token"
            element={<ConfirmAccount />}
            />
            
          <Route
            path="/account"
            element={
              <AuthenticatedView>
                <Account />
              </AuthenticatedView>
            }
          />
         

          <Route
            path="/"
            element={
              <AuthenticatedView>
                <Home />
              </AuthenticatedView>
            }
          />
          <Route
            path="/set-lists"
            element={
              <AuthenticatedView>
                <SetLists />
              </AuthenticatedView>
            }
          />
          <Route
            path="/set-list/:setListId"
            element={
              <AuthenticatedView>
                <SetListBuilder />
              </AuthenticatedView>
            }
          />
          <Route
            path="/bands"
            element={
              <AuthenticatedView>
                <Bands></Bands>
              </AuthenticatedView>
            }
          />
          

            <Route
            path="/personal-calendar"
            element={<AuthenticatedView><PersonalCalendar/></AuthenticatedView>}
            />
            <Route
            path="/calendar"
            element={<AuthenticatedView><BandCalendar/></AuthenticatedView>}
            />
            <Route
            path="/gigs/:gigId"
            element={<AuthenticatedView><GigOverview/></AuthenticatedView>}
            />
            
             <Route
            path="/contacts"
            element={<AuthenticatedView><ComingSoon feature="CONTACTS"/></AuthenticatedView>}
            />
            <Route
            path="/drive"
            element={<AuthenticatedView><ComingSoon feature="DRIVE"/></AuthenticatedView>}
/>
<Route
            path="/inventory"
            element={<AuthenticatedView><ComingSoon feature="INVENTORY"/></AuthenticatedView>}
/>  <Route
            path="/todos"
            element={<AuthenticatedView><Todo/></AuthenticatedView>}
/>  <Route
            path="/finances"
            element={<AuthenticatedView><Finances/></AuthenticatedView>}
/>
<Route
path="/invoice/:id"
element={<AuthenticatedView><InvoiceView/></AuthenticatedView>}
/>
<Route
            path="/repertoire"
            element={<AuthenticatedView><Repertoire/></AuthenticatedView>}
            
/>
<Route
            path="/marketing"
            element={<AuthenticatedView><ComingSoon feature="MARKETING"/></AuthenticatedView>}
/>
<Route
  path="/oauth/spotify"
  element={<OAuthSpotify/>}/>

        </Routes>
      </HistoryRouter>
    );
  }
}
