import {
  UnstyledButton,
  Text,
  Group,
  rem,
  Combobox,
  useCombobox,
  InputBase,
  Input,
  LoadingOverlay,
  Avatar,
  Indicator,
  Flex,
} from "@mantine/core";
import {
  IconGuitarPickFilled,
} from "@tabler/icons-react";
//   import { UserButton } from '../UserButton/UserButton';
import classes from "./Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import { RootState } from "../../redux";
import { changeProfile, getProfiles } from "../../redux/profile/actions";

const links = [
  {
    icon: IconGuitarPickFilled,
    color: "red",
    label: "My Bands",
    link: "/bands",
  },
  // { icon: IconCalendarFilled, label: "Personal Calendar", notifications: 4, link: "/personal-calendar" },
  // { icon: IconUser, label: "Contacts", link: "/contacts" },
];

const collections = [
  { emoji: "🏡", label: "Home", link: "/" },
  { emoji: "📖", label: "Set Lists", link: "/set-lists" },
  // { emoji: "📅", label: "Band Calendar", link: "/calendar"},
  // { emoji: '💾', label: 'Drive', link: '/drive'},
  // { emoji: '🎸', label: 'Inventory', link: '/inventory'},
  // { emoji: '✅', label: 'Todo', link: '/todos'},
  // { emoji: '📈', label: 'Finances', link: '/finances'},
  // { emoji: '🎼', label: 'Repertoire', link: '/repertoire'},
  // { emoji: '📣', label: 'Marketing', link: '/marketing'}

  // { emoji: '💸', label: 'Discounts' },
  // { emoji: '💰', label: 'Profits' },
  // { emoji: '✨', label: 'Reports' },
  // { emoji: '🛒', label: 'Orders' },
  // { emoji: '📅', label: 'Events' },
  // { emoji: '🙈', label: 'Debts' },
  // { emoji: '💁‍♀️', label: 'Customers' },
];

export function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profiles = useSelector((state: RootState) => state.profile.profiles);
  const loading = useSelector((state: RootState) => state.profile.loading);
  const profile = useSelector((state: RootState) => state.profile.profile);
  const presence = useSelector((state: RootState) => state.profile.presence);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen() {
      dispatch(getProfiles())
    },
  });

  const options = profiles.map((profile: any) => (
    <Combobox.Option value={profile.id} key={profile.id}>
      {profile.name}
    </Combobox.Option>
  ));
  const mainLinks = links.map((link) => (
    <UnstyledButton
      onClick={() => navigate(link.link)}
      key={link.label}
      className={classes.mainLink}
    >
      <div className={classes.mainLinkInner}>
        <link.icon
          size={20}
          color={link.color}
          className={classes.mainLinkIcon}
          stroke={1.5}
        />
        <span>{link.label}</span>
      </div>
      {/* {link.notifications && (
        <Badge size="sm" variant="filled" className={classes.mainLinkBadge}>
          {link.notifications}
        </Badge>
      )} */}
    </UnstyledButton>
  ));

  const collectionLinks = collections.map((collection) => (
    <Link
      to={collection.link || "#"}
      key={collection.label}
      className={classes.collectionLink}
    >
      <span style={{ marginRight: rem(9), fontSize: rem(16) }}>
        {collection.emoji}
      </span>{" "}
      {collection.label}
    </Link>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.section}>
        {/* <OrganizationSwitcher hidePersonal={true} afterSelectOrganizationUrl={'/'}/> */}

        <Combobox
          store={combobox}
          onOptionSubmit={async (val) => {
            combobox.closeDropdown();
            dispatch(changeProfile(val))
          }}
        >
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />

          <Combobox.Target>
            <InputBase
              component="button"
              pointer
              rightSection={<Combobox.Chevron />}
              onClick={() => combobox.toggleDropdown()}
            >
              {profile?.name || (
                <Input.Placeholder>Pick value</Input.Placeholder>
              )}
            </InputBase>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options>{options}</Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </div>
      {/* <TextInput
        placeholder="Search"
        size="xs"
        leftSection={
          <IconSearch
            style={{ width: rem(12), height: rem(12) }}
            stroke={1.5}d
          />
        }
        rightSectionWidth={70}
        rightSection={<Code className={classes.searchCode}>Ctrl + K</Code>}
        styles={{ section: { pointerEvents: "none" } }}
        mb="sm"
      /> */}

      <div className={classes.section}>
      <Text size="xs" fw={500} c="dimmed">
            Personal
          </Text>
        <div className={classes.mainLinks}>{mainLinks}</div>
      </div>

      <div className={classes.section}>
        <Group className={classes.collectionsHeader} justify="space-between">
          <Text size="xs" fw={500} c="dimmed">
            Tools
          </Text>
        </Group>
        <div className={classes.collections}>{collectionLinks}</div>
      </div>
      <div className={classes.section}>
        <Group className={classes.collectionsHeader} justify="space-between">
          <Text size="xs" fw={500} c="dimmed">
            Online Members
          </Text>
       
          </Group>
          {presence.length > 0 ? <div>
            {presence.map((user: any) => (
              <div key={user.id}>
                <Flex pt={8} align="center">
                <Indicator mr={8} color="green">
                  <Avatar />
                  </Indicator>
                  <div>  {user.firstName} {user.lastName}</div>
                  </Flex>
                </div>
            ))}
          </div>: <div>Loading...</div>}
          </div>
      
    </nav>
  );
}
