import { call, put, take, fork, cancelled, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { Socket, io } from 'socket.io-client';
import { getWsApiUrl } from '../../utils/getApiUrl';
import {
  SEND_WEBSOCKET_MESSAGE,
  websocketConnected,
  websocketDisconnected,
  // Other action creators you may have
} from './actions'; // Import your action creators
import { websocketHandlers } from './websocket-handlers';
let socket: Socket | null = null;

function initWebSocket() {

  return eventChannel(emit => {
    
    const path = process.env.NODE_ENV === 'production' ? '/ws' : '/api/ws';
    console.log({
      wsUrl: getWsApiUrl(),
      path
    });
    socket = io(getWsApiUrl(), {
      withCredentials: true,
      path
    });
    console.log('got socket', socket)
    socket.on('connect', () => {
      console.log('socket connected', socket)
      emit(websocketConnected());
    });

    socket.on('disconnect', () => {
      emit(websocketDisconnected());
    });
    websocketHandlers(socket, emit);

    // Add other event handlers as needed

    return () => {
      socket!.disconnect();
      socket = null;
    };
  });
}

// WebSocket root saga
export function* watchWebSocket() {
  console.log('initting websocket')
  const channel = yield call(initWebSocket);
  console.log('got channel', channel)

  try {
    while (true) {
      const action = yield take(channel);
      console.log('got action', action)
      yield put(action);
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}
export function* sendWebsocketMessage(action){
  console.log('sending message', action.payload);
  yield socket!.emit(action.payload.key, action.payload.message);
}




// Combining all WebSocket-related sagas
export default function* rootWebSocketSaga() {
  yield fork(watchWebSocket);
  yield takeEvery(SEND_WEBSOCKET_MESSAGE, sendWebsocketMessage);
  // Add other WebSocket-related sagas here, e.g., takeEvery for actions that send messages
}
