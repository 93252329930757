import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createReduxHistoryContext } from 'redux-first-history';
import rootSaga from './root-saga';
import { Api } from '../Api';
import { getApiUrl } from '../utils/getApiUrl';
import { createBrowserHistory } from 'history';
import authReducer from './auth/reducer';
import spotifyReducer from './spotify/reducers';
import audioPlayerReducer from './audio-player/reducer';
import websocketReducer from './websocket/reducer';
import setListReducer from './set-list/reducer';
import chatReducer from './chat/reducer';
import dashboardReducer from './dashboard/reducer';
import invoiceReducer from './invoice/reducer';
import calendarReducer from './calendar/reducer';
import todoReducer from './todos/reducer';
import profileReducer from './profile/reducer';
import { logout } from './auth/actions';
import repertoireReducer from './repertoire/reducer';

const api = new Api({
    baseURL: getApiUrl(),
    withCredentials: true,
});



// Create Redux-First-History Context
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: true,
});

// Create saga middleware
const sagaMiddleware = createSagaMiddleware();
const reducers = combineReducers({
  router: routerReducer, // redux-first-history router reducer
  auth: authReducer,
  spotify: spotifyReducer,
  audioPlayer: audioPlayerReducer,
  websockets: websocketReducer,
  setList: setListReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  dashboard: dashboardReducer,
  invoice: invoiceReducer,
  todo: todoReducer,
  profile: profileReducer,
  repertoire: repertoireReducer,
});
// Create the Redux store with combined reducers and middleware
const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware, // redux-first-history middleware
      // other middleware if needed
    )
  )
);

// Run the root saga
sagaMiddleware.run(rootSaga, api); // Assuming 'api' is defined in your saga file or context

// Create and export history object
export const history = createReduxHistory(store);
api.instance.interceptors.response.use(response => response, async (error) => {
  if(error.response.status !== 401) {
    return Promise.reject(error);
  }

  const originalRequest = error.config;
  if (originalRequest.url !== '/users/refresh' && !originalRequest._retry) {
    originalRequest._retry = true; // Marking that we already tried to refresh the token
    try {
      await api.instance.post("/users/refresh");
      return api.instance(originalRequest); // Retrying the original request with the new token
    } catch (refreshError) {
      console.log('loggout in refresh error')
      store.dispatch(logout());
      return Promise.reject(refreshError);
    }
  }
  console.log('loggout in timeout error')
  store.dispatch(logout());
  return Promise.reject(error);
});

// Final export of the store
export default store;
export type RootState = ReturnType<typeof reducers>;
