import { Box, Text, Avatar, Table, UnstyledButton } from '@mantine/core';

export const ChatList = ({ chats, onSelect }:any) => {

  return (
    <Box title='Conversations'>
         <Text size="xs" fw={500} c="dimmed">
            Conversations
          </Text>

          <Table>
            {chats.map((chat: any) => (
                          <Table.Tr key={chat.id}>

                        <Table.Td >
                            <UnstyledButton style={{display:'flex', alignItems:'center'}} onClick={() => onSelect(chat)}>
                                <Avatar />
                                <Text >{chat.profile.name}</Text>
                            </UnstyledButton>
                        </Table.Td>
                        </Table.Tr>

                    ))}
              
                   
            </Table>
  
    </Box>
  );
}

