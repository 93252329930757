// chatWebSocketSaga.js
import { takeEvery, put, call } from 'redux-saga/effects';
import {
  DASHBOARD_LOADED,
  LOAD_DASHBOARD,
} from './actions';
import { Api } from '../../Api';

function* loadDashboard(api: Api<any>) {
  const response  = yield call(api.dashboard.getManyBaseDashboardControllerDashboardView);
  yield put({ type: DASHBOARD_LOADED, payload: response.data[0] });
}



export default function* dashboardSaga(api: Api<any>) {
  yield takeEvery(LOAD_DASHBOARD, loadDashboard, api);
}
