import { call, put, select, takeLatest } from 'redux-saga/effects';
import Spotify from 'spotify-web-api-js';
import { 
  CONNECT_SPOTIFY_ACCOUNT,
  FETCH_SPOTIFY_PLAYLISTS,
  GET_SPOTIFY_RECOMMENDATIONS,
  GET_SPOTIFY_RECOMMENDATIONS_FAILURE,
  GET_SPOTIFY_RECOMMENDATIONS_SUCCESS,
  IMPORT_SPOTIFY_PLAYLIST,
  LOAD_SONG_META, 
  SEARCH_SPOTIFY_SONGS, 
  SET_SONG_META, 
  SET_SONG_META_LOADING, 
  SET_SPOTIFY_PLAYLISTS, 
  SET_SPOTIFY_SEARCH_RESULTS, 
  clearSpotifyRecommendations, 
} from './actions';
import { SEND_SPOTIFY_CODE, SPOTIFY_LOGIN_REDIRECT } from './actions';
import { SetListSong, type Api } from '../../Api';
import { RootState } from '..';
import { setUser } from '../auth/actions';
import { sendWebsocketMessage } from '../websocket/actions';
import { updateSetListGroup } from '../set-list/actions';

const SPOTIFY_CLIENT_ID = '98f341b093ea462faedcdda00842139f';
const REDIRECT_URI = 'https://local.app.bandhouse.io/portal/oauth/spotify';
const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
const SCOPES = [
  'playlist-read-private',
  'playlist-read-collaborative',
  'playlist-modify-private',
  'playlist-modify-public',
  'user-read-email'
];

let spotifyApi:  any | null = null;
function* initSpotifyApiSaga(api: Api<any>) {
  if(!spotifyApi){
    spotifyApi = new Spotify();
  }
  const response = yield call(api.users.userControllerRefreshSpotifyToken)
  yield put(setUser(response.data));
  console.log('setting access token', response.data.spotifyAccessToken)
  spotifyApi.setAccessToken(response.data.spotifyAccessToken);
  return spotifyApi;

}


function getSpotifyAuthURL(state = {}) {
  const params = new URLSearchParams({
    client_id: SPOTIFY_CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    scope: SCOPES.join(' '),
    response_type: 'code',
    state: JSON.stringify(state)
  });

  return `${AUTH_ENDPOINT}?${params.toString()}`;
}
function* sendSpotifyCodeSaga(api: Api<any>, action) {
    try {
      const { code, redirectUrl } = action.payload;
      console.log({code, redirectUrl})
      const response = yield call(api.users.userControllerConnectSpotify, {code});
      yield put(setUser(response.data));
      console.log('redirecting', redirectUrl)
      if(!redirectUrl) {
        const mainUrl = new URL(window.location.origin);
        mainUrl.searchParams.set('authSuccess', 'true');
        window.location.href = mainUrl.toString();
        return;
      }
      const url = new URL(redirectUrl, window.location.origin);
      url.searchParams.set('oauthSuccess', 'true');
      window.location.href = url.toString();
    } catch (error) {
      console.error('Error sending code to backend', error);
    }
  }
  function* fetchSpotifyPlaylistsSaga(api: Api<any>) {
    try {
      console.log('calling fetch')
      const sapi = yield initSpotifyApiSaga(api);
      const response = yield call(sapi.getUserPlaylists);
      yield put({ type: SET_SPOTIFY_PLAYLISTS, payload: response.items });
    } catch (error) {
      console.log(error);
      // Handle errors, such as refreshing the token if needed
    }
  }
function* spotifyLoginRedirectSaga(action) {
    const authUrl = yield getSpotifyAuthURL(action.payload); // Assuming getSpotifyAuthURL is imported or defined
    window.location.href = authUrl;
  }
function* searchSpotifySongsSaga(api: Api<any>, action) {
  const results = yield call([api, api.song.songControllerSearchTrack], {q: action.payload});
  console.log({results});
  yield put({ type: SET_SPOTIFY_SEARCH_RESULTS, payload: results.data });
}

function* getSongMeta(api: Api<any>, action) {
  const { isrc, spotifyId } = action.payload;
  yield put({ type: SET_SONG_META_LOADING, payload: true })
  const response = yield call([api.song, api.song.songControllerLoadMetaData], { isrc, spotifyId });
  yield put({ type: SET_SONG_META, payload: response.data });
}
function* connectSpotifyAccount(){
  const authUrl = yield getSpotifyAuthURL({ latterRedirect: window.location.href });
  window.location.href = authUrl;
}
function* getRecommendations(api: Api<any>, action) {
  yield put(clearSpotifyRecommendations());
  const { setListGroupId, index } = action.payload;

  const songs = (yield select((state: RootState) => state.setList.setListSongs))
    .filter((song: SetListSong) => song.setListGroupId === setListGroupId && song.spotifyId !== undefined);

  // Calculate the effective index if index is undefined
  let effectiveIndex = index;
  if (typeof effectiveIndex === 'undefined' || effectiveIndex === null) {
    // Default to the first index if all else fails
    effectiveIndex = 0;
  }

  // Collecting Spotify IDs surrounding the index
  const songIds:string[] = [];
  for (let i = -2; i <= 2; i++) {
    if (i === 0) continue;
    const currentIndex = effectiveIndex + i;
    if (currentIndex >= 0 && currentIndex < songs.length) {
      const spotifyId = songs[currentIndex].spotifyId;
      if (spotifyId) {
        songIds.push(spotifyId);
      }
    }
  }
  try {
    const response = yield call([api.song, api.song.songControllerGetRecommendations], { songIds });
    yield put({ type: GET_SPOTIFY_RECOMMENDATIONS_SUCCESS, payload: response.data });
  } catch (error: any) {
    yield put({ type: GET_SPOTIFY_RECOMMENDATIONS_FAILURE, payload: error.message });
  }
}

function* importSpotifyPlaylist(action) {
  yield put(updateSetListGroup(action.payload.setListGroupId, { importStatus: 'SPOTIFY_IMPORT' }));
  
  yield put(sendWebsocketMessage('@SETLIST/IMPORT_SPOTIFY_PLAYLIST', action.payload));
}




export default function* spotifySaga(api: Api<any>) {
  yield takeLatest(SEND_SPOTIFY_CODE, sendSpotifyCodeSaga, api);
  yield takeLatest(SPOTIFY_LOGIN_REDIRECT, spotifyLoginRedirectSaga);
  yield takeLatest(FETCH_SPOTIFY_PLAYLISTS, fetchSpotifyPlaylistsSaga, api);
  yield takeLatest(SEARCH_SPOTIFY_SONGS, searchSpotifySongsSaga, api);
  yield takeLatest(LOAD_SONG_META, getSongMeta, api)
  yield takeLatest(CONNECT_SPOTIFY_ACCOUNT, connectSpotifyAccount);
  yield takeLatest(GET_SPOTIFY_RECOMMENDATIONS, getRecommendations, api);
  yield takeLatest(IMPORT_SPOTIFY_PLAYLIST, importSpotifyPlaylist);
}
