// websocketReducer.js

import { LOGOUT_SUCCESS } from '../auth/actions';
import {
    INITIALIZE_WEBSOCKET,
    WEBSOCKET_CONNECTED,
    WEBSOCKET_DISCONNECTED,
    WEBSOCKET_ERROR
  } from './actions';
  
  const initialState = {
    isConnected: false,
    error: null
  };
  
  const websocketReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGOUT_SUCCESS:
        return initialState;
      case INITIALIZE_WEBSOCKET:
        return {
          ...state,
          isConnected: false,
          error: null
        };
      case WEBSOCKET_CONNECTED:
        return {
          ...state,
          isConnected: true,
          error: null
        };
      case WEBSOCKET_DISCONNECTED:
        return {
          ...state,
          isConnected: false
        };
      case WEBSOCKET_ERROR:
        return {
          ...state,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default websocketReducer;
  