import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { sendSpotifyCode } from "../../../redux/spotify/actions";

export const OAuthSpotify = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const search = location.search;
    const hasCode = search.includes("?code=");
    if (hasCode) {
      const params = new URLSearchParams(search);
      console.log(params);
      const newCode = params.get('code');
      const state = JSON.parse(params.get('state')!);
      const redirect = state?.redirectState?.latterRedirect || '/';
      dispatch(sendSpotifyCode(newCode, redirect));
    }
  }, [location, dispatch]);

  return <div>Loading</div>;
};
