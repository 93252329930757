import { Socket } from "socket.io-client";
import { confirmSetListSongCreate, setSetListData, setSetListGroupData, setSetListSongOrder, setSetlistSongData, updateSetListGroup } from "../set-list/actions";
import { receiveChatMessage, receiveThreadList, receiveThreadMessages } from "../chat/actions";
import { setUserLocation, setUserPresence } from "../profile/actions";
import { setTodos } from "../todos/actions";


export function websocketHandlers (socket: Socket, emit) {
    socket.on('@SETLIST/LOAD_SETLIST', function (data) {
        const setList = data.setList;
        const groups = setList.groups;
        delete setList.groups;
        const songs = groups.flatMap(group => group.setListSongs);
        for(const group of groups){
            delete group.setListSongs;
        }
        emit(setSetListData([setList]))
        emit(setSetListGroupData(groups))
        emit(setSetlistSongData(songs))

    });
    socket.on('@SETLIST/UPDATE_SETLIST', function(data){
        emit(setSetListData([data]))
    })

    socket.on('@SETLIST/IMPORT_SPOTIFY_PLAYLIST', function(data){
        if(data.length > 0){
            emit(updateSetListGroup(data[0].setListGroupId, { importStatus: 'NONE' }));
            emit(setSetlistSongData(data));
        }
    });

    socket.on('@SETLIST/REORDER_SONGS', function(data){
        console.log('emitting', data);
        emit(setSetListSongOrder(data))
    });
    socket.on('@SETLIST/CREATE_SONG', function(data){
        emit(confirmSetListSongCreate(data))
    });
    socket.on('@SETLIST/UPDATE_SONG', function(data){
        emit(setSetlistSongData([data]))
    });
    socket.on('@SETLIST/UPDATE_SETLIST_GROUP', function(data){
        emit(setSetListGroupData([data.setListGroup]))
    });
    socket.on('@SETLIST/CREATE_SETLIST_GROUP', function(data){
        emit(setSetListGroupData([data.setListGroup]))
    });
    socket.on('@CHAT/LIST_THREADS', (threads) => {
        emit(receiveThreadList(threads));
    });
    socket.on('@CHAT/LOAD_THREAD', (messages) => {
        emit(receiveThreadMessages(messages));
    });
    socket.on('@CHAT/SEND_MESSAGE', (message) => {
        emit(receiveChatMessage(message));
    });
    socket.on('@USER/GET_USER_PRESENCE', (messages) => {
        emit(setUserPresence(messages));
    });
    socket.on('@USER/UPDATE_LOCATION', (message) => {
        emit(setUserLocation(message));
    });
    socket.on('@TODOS/CREATE_MANY', (message) => {
        emit(setTodos(message));
    });
}
