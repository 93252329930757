import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider, createTheme } from "@mantine/core";
import '@mantine/dates/styles.css';
import { Analytics } from '@vercel/analytics/react';
import { Provider } from "react-redux";
import store from "./redux";
const theme = createTheme({
  fontFamily: 'Montserrat, sans-serif',
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <MantineProvider theme={theme}>
              <Provider store={store}>


            <App />

      <Analytics />
      </Provider>
    </MantineProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
