import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadRepertoire } from "../../redux/repertoire/actions";
import { RootState } from "../../redux";
import { RepertoireView } from "../../Api";
import { Card, Table } from "@mantine/core";
import AudioPreview from "../../components/AudioPreview/AudioPreview";

export const Repertoire = () => {
    const dispatch = useDispatch();
    const profileId = useSelector((state: RootState) => state.auth.user.activeProfileId);
    const repertoire = useSelector((state: RootState) => state.repertoire.repertoire).filter((song: RepertoireView) => song.profileId === profileId);
    console.log(repertoire);
    
    useEffect(() => {
        dispatch(loadRepertoire());
    }, [profileId]);
    // use mantine UI tables to render repertoire
   
    return (
        <Card>
            <h1>Repertoire</h1>
            <p>We&apos;re working on making this page more interactive, for now you can just see a list of all of the sings included in your set lists.</p>
            <p>Here&apos;s your current repertoire:</p>
            <Table>
        <Table.Thead>
            <Table.Tr>
                <Table.Th></Table.Th>
                        <Table.Th>Song</Table.Th>
                        <Table.Th>Artist</Table.Th>
                        <Table.Th>Usages</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {repertoire.map((song: RepertoireView) => (
                        <Table.Tr key={song.title + song.artistName + song.spotifyId}>
                            <Table.Td><AudioPreview previewUrl={song.previewUrl} graphicUrl={song.graphicUrl}/></Table.Td>
                            <Table.Td>{song.title}</Table.Td>
                            <Table.Td>{song.artistName}</Table.Td>
                            <Table.Td>{song.songCount}</Table.Td>

                        </Table.Tr>
                    ))}
                </Table.Tbody>
            </Table>
        </Card>
    )
};