import React, { useState } from 'react';
import { Avatar, Text, Group, Select } from '@mantine/core';

export const AssigneeAvatar = ({ assignee, assignees, onAssigneeChange }: any) => {
    console.log({assignee})
  const [editMode, setEditMode] = useState(false);

  const handleAssigneeChange = (value) => {
    onAssigneeChange(value);
    setEditMode(false);
  };
  const assigneeData = assignees.find((item) => item.value === assignee);
  const assigneesWithUnassign = [
    { value: '', label: 'Unassigned' },
    ...assignees
  ]
  return (
    <Group onClick={() => setEditMode(true)} style={{ cursor: 'pointer' }}>
      {editMode ? (
        <Select
          data={assigneesWithUnassign}
          value={assignee}
          onChange={handleAssigneeChange}
          onBlur={() => setEditMode(false)}
          autoFocus
        />
      ) : (
        <>
          <Avatar src={assignee?.avatarUrl} alt={assigneeData?.label} />
          <Text size="sm">{assigneeData?.label}</Text>
        </>
      )}
    </Group>

  );
};