import { LOGOUT_SUCCESS } from "../auth/actions";
import { CLASSIFY_MESSAGE, RECEIVE_CHAT_MESSAGE, RECEIVE_THREAD_LIST, RECEIVE_THREAD_MESSAGES } from "./actions";

  
  const initialState = {
    threads: [],
    messages: []
  };
  
  export default function chatReducer(state = initialState, action) {
    switch (action.type) {
      case LOGOUT_SUCCESS:
        return initialState;
      case RECEIVE_THREAD_LIST:
        return { ...state, threads: action.payload };
      case RECEIVE_THREAD_MESSAGES:
        return { ...state, messages: action.payload };
      case RECEIVE_CHAT_MESSAGE:
        // if message already exists, replace, otherwise add
        const existingMessage = state.messages.find((msg:any) => msg.id === action.payload.id);
        if(existingMessage){
          return {
            ...state,
            messages: state.messages.map((msg:any) => msg.id === action.payload.id ? action.payload : msg)
          };
        }
        return {
          ...state,
          messages: [...state.messages, action.payload]
        };
      case CLASSIFY_MESSAGE:
        return {
            ...state,
            messages: state.messages.map((msg:any) => msg.id === action.payload.chatMessageId ? { ...msg, classification: action.payload } : msg)
        };
      default:
        return state;
    }
  }