import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { ActionIcon, Button, Fieldset, Flex, LoadingOverlay, Menu, Table, Tabs, UnstyledButton } from "@mantine/core";
import { IconBrandSpotify, IconDotsVertical, IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import styles from '../SetListBuilder.module.css';
import OpenInSpotifyButton from "../../../components/AudioPlayer/OpenInSpotifyButton";
import AudioPreview from "../../../components/AudioPreview/AudioPreview";
import cx from "clsx";
import { reorderSetListSongs } from "../../../redux/set-list/actions";
import { useDispatch } from "react-redux";
import { SetListAddSongDrawer } from "./SetListAddSongDrawer";
import { spotifyLoginRedirect } from "../../../redux/spotify/actions";
import { useDisclosure } from "@mantine/hooks";
import { SetListAddGroupModal } from "./SetListAddGroupModal";
import { SetListGroup } from "../../../Api";
import { SetListPrintModal } from "./SetListPrintModal";

export const SetListEditor = ({ setSetListSongDetails, showAddSong, addSongActions, currentGroup, setCurrentGroup, setList, setListSongs, filteredSetListSongs, setListGroups}: any) => {
  console.log({setListGroups})
    const dispatch = useDispatch();
    const [showPrintModal , showPrintModalActions] = useDisclosure(false);
    const [dragId, setDragId] = useState<null | string>(null);
    const [addSongIndex, setAddSongIndex] = useState<number | null>(null);
    const [editModalContent, setEditModalContent] = useState<SetListGroup | null>(null);
    const [addGroupModal, addGroupModalActions] = useDisclosure(false);
  
    const handleGroupDoubleClick = (setListGroup: SetListGroup) => {
      setEditModalContent(setListGroup);
      addGroupModalActions.open();
    };

    const openAddSongDrawer = (index: number | null) => {
        setAddSongIndex(index !== null ? index  : filteredSetListSongs.length + 1);
        addSongActions.open();
      };
    const group = setListGroups.find((group: any) => group.id === currentGroup);
    console.log({filteredSetListSongs})
    const items = filteredSetListSongs.map((item: any, index: number) => {
        const id = item.optimisticId || item.id;
        return (
          <Draggable key={id} index={index} draggableId={id}>
            {(provided, snapshot) => {
              const [createHover, setCreateHover] = useState(false);
              const hoverStyles:any ={
    
              }
              if(createHover){
                hoverStyles['backgroundColor'] = 'rgb(34, 139, 230)';
              }
              
              return <>
    <tr style={{ width: '100%', maxHeight: '10px', textAlign: 'center' }}
        onMouseOver={() => !dragId && setCreateHover(true)}
        onMouseLeave={() => setCreateHover(false)}>
      <td colSpan={5}>
        {createHover && (
          <div style={{ position: 'relative', height: 0 }}>
            <Button style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000 // Ensure it's above other items
            }} justify="center" size="xs" onClick={() => openAddSongDrawer(index)}>
              Add Song
            </Button>
          </div>
        )}
      </td>
    </tr>          <Table.Tr
                className={cx(styles.item, {
                  [styles.itemDragging]: snapshot.isDragging,
                })}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                key={`${item.optimisticId || item.id}${item.order}`}
                onDoubleClick={() => {
                  setSetListSongDetails(item);
                }}
              >
                <Table.Td>
                  {item.song && <AudioPreview
                    graphicUrl={item.song.graphicUrl}
                    previewUrl={item.song.previewUrl}
                  />}
                </Table.Td>
                <Table.Td>
                {item.song &&<OpenInSpotifyButton spotifyId={item.spotifyId}/>
            }
                </Table.Td>
                <Table.Td>{item.title}</Table.Td>
                <Table.Td>{item.artistName}</Table.Td>
                <Table.Td>
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <ActionIcon size={42} variant="default">
                        <IconDotsVertical />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Label>Actions</Menu.Label>
                      <Menu.Item>
                        <UnstyledButton>
                          Delete
                        </UnstyledButton>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Table.Td>
              </Table.Tr>
              </>
            }}
          </Draggable>
        );
      });

    const onDragStart = (event) => {
      console.log('drag start', event);
      setDragId(event.draggableId);
    }
    const onDragEnd = (result: any) => {
        setDragId(null);
        const { source, destination } = result;
    
        // Dropped outside the droppable container or at the same position
        if (
          !destination ||
          (source.droppableId === destination.droppableId &&
            source.index === destination.index)
        ) {
          return;
        }
    
        // Create a copy of the current state and reorder optimistically
        const reorderedState = Array.from(filteredSetListSongs);
        const [movedItem] = reorderedState.splice(source.index, 1);
        reorderedState.splice(destination.index, 0, movedItem);
    
        // Create a list of reordered item ids with their new order
        const songs = reorderedState.map((item: any, index: number) => ({
          id: item.id,
          order: index + 1,
        }));
        // Update the UI optimistically
        dispatch(reorderSetListSongs(setList.id ,currentGroup, songs));
      };
      const authSpotify = () => {
        // Parse the current URL
      const currentUrl = new URL(window.location.href);

      // Get existing query parameters
      const searchParams = currentUrl.searchParams;

      searchParams.set('setListGroupId', currentGroup);

      // Construct the new URL
      const latterRedirect = `${currentUrl.pathname}?${searchParams.toString()}`;
        dispatch(spotifyLoginRedirect({latterRedirect}))
      };
    
    return <>
                  <SetListAddGroupModal setListId={setList?.id} setSetListGroup={setEditModalContent} setListGroup={editModalContent} pageCount={setListGroups.length} opened={addGroupModal} actions={addGroupModalActions} />

    <Fieldset legend="Set List">
    <Flex
    p={8}
      gap="md"
      justify="space-between"
      direction="row"
      wrap="wrap"
    >
        <LoadingOverlay
          visible={group?.importStatus === 'SPOTIFY_IMPORT'}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
          loaderProps={{ color: 'green', type: 'bars' }}
        />        
        <Tabs onChange={(setCurrentGroup)} color="teal" value={currentGroup}>
          <Tabs.List>
            {setListGroups.map((group: SetListGroup) => (
              <Tabs.Tab onDoubleClick={() => handleGroupDoubleClick(group)} key={group.id} value={group.id}>{group.title}</Tabs.Tab>
            ))}
            <ActionIcon onClick={() => addGroupModalActions.open()}>
              <IconPlus  />
            </ActionIcon>
          </Tabs.List>
        </Tabs>
        <Button size="xs" color="#1DB954" onClick={authSpotify}><IconBrandSpotify/>Import From Spotify</Button>
        <SetListPrintModal show={showPrintModal} actions={showPrintModalActions} setList={setList} setListSongs={setListSongs} setListGroups={setListGroups} />
        <Button onClick={showPrintModalActions.open} size="xs">Print</Button>
        </Flex>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="dnd-list" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Table>
                  <Table.Tbody>{items}</Table.Tbody>
                </Table>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button onClick={() => openAddSongDrawer(null)}>Add Song</Button>
     
    </Fieldset>
    <SetListAddSongDrawer currentGroup={currentGroup} addSongIndex={addSongIndex} show={showAddSong} actions={addSongActions} />
    </>
};