import {
  LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE,
  LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE,
  SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE,
  UPDATE_ACCOUNT, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAILURE, INITIALIZE_AUTH, INITIALIZE_AUTH_SUCCESS, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, SET_USER, CONFIRM_ACCOUNT
} from './actions';
import { User } from '../../Api';

const initialState: {
  user: User | null,
  authenticated: boolean,
  loading: boolean,
  confirmAccountSuccess: boolean,
  error: any
} = {
  user: null,
  authenticated: false,
  loading: true,
  error: null,
  confirmAccountSuccess: false
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
      
    case LOGIN:
    case LOGOUT:
    case SIGNUP:
    case UPDATE_ACCOUNT:
    case CONFIRM_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: action.payload,
        error: null
      };
    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
    case SIGNUP_FAILURE:
    case UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case INITIALIZE_AUTH:
      return {
        ...state,
        loading: true
      };
    case INITIALIZE_AUTH_SUCCESS:
      return {
        ...state,
        user: action.payload,
        authenticated: !!action.payload,
        loading: false
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        // Optionally update state to reflect the password change
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        authenticated: !!action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default authReducer;
