import { put, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { LOAD_REPERTOIRE, loadRepertoireFailure, loadRepertoireSuccess } from "./actions";


function* loadRepertoire(api: Api<any>) {
    try {
        const response = yield api.repertoire.getManyBaseRepertoireControllerRepertoireView();
        yield put(loadRepertoireSuccess(response.data));
    } catch (error) {
        yield put(loadRepertoireFailure(error));
    }
}

export default function* repertoireSaga(api: Api<any>) {
    yield takeEvery(LOAD_REPERTOIRE, loadRepertoire, api);
}