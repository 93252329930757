import { ActionIcon, Avatar, Flex, Group, Paper, Text } from "@mantine/core";
import { IconCalendar, IconCheck, IconMicrophone, IconMusic } from "@tabler/icons-react";
const getMessageData = (message ) => {
  let intent: string | null = null;
    if(message.classifications && message.classifications.length > 0){
      intent = message.classifications[0].classification;
    }
    let messageData: any = {message: message.message};
    switch (intent) {
      case 'availability_confirmation':
        messageData = {
          intentIcon:<IconCheck />, 
          message: message.message,
          intentMessage: 'Do you want to confirm your availability for this date?', 
          intentAction: () => {}
        };
        break;
      case 'gig_inquiry':
        messageData = {
          intentIcon: <IconMicrophone />, 
          message: message.message,
          intentMessage: 'Would you like to create a gig?',
          intentAction: () => {}
        }
        break; 
      case 'meeting_inquiry':
        messageData = {
          intentIcon: <IconCalendar />, 
          message: message.message,
          intentMessage: 'Would you like to book a band meeting?',
          intentAction: () => {}
        }
        break; 
      case 'recording_inquiry':
        messageData = {
          intentIcon: <IconMusic />, 
          message: message.message,
          intentMessage: 'Would you like to book a recording session?',
          intentAction: () => {}
        };
        break; 
      case 'gig_confirmation':
        messageData = {
          intentIcon: <IconMicrophone />,
           message: message.message,
          intentMessage: 'Would you like to confirm this gig booking?',
        intentAction: () => {}
        }
        break; 
      case 'meeting_confirmation':
        messageData = {
          intentIcon: <IconCalendar />,
           message: message.message,
          intentMessage: 'Would you like to confirm your attendance at this meeting?',
          intentAction: () => {}
        }
        break; 
      case 'song_suggestion':
        messageData = {
          intentIcon: <IconMusic />,
           message: message.message,
          intentMessage: 'Would you like to add a song to the set list?',
          intentAction: () => {}
        }
        break; 


      default:
        break;
    }
    return messageData;
  };
export const ChatMessage = ({ message }: any) => {
 
  const messageData = getMessageData(message);
    return (
        <Paper withBorder shadow="xs" p="md">
          <Group>
            <Avatar src="path-to-user-image.jpg" />
            <div>
              {/* <Text>{message.userName}</Text> */}
              <Text size="sm">{messageData.message}</Text>
            </div>
           
          </Group>
          
          {messageData.intentAction && <Group><Flex direction="row"><ActionIcon>{messageData.intentIcon}</ActionIcon><Text>{messageData.intentMessage}</Text></Flex></Group>}

        </Paper>
      );
}
