
import { takeLatest, call, put } from 'redux-saga/effects';
import { CREATE_INVOICE, CREATE_INVOICE_FAILURE, CREATE_INVOICE_SUCCESS, LOAD_INVOICE, LOAD_INVOICES, LOAD_INVOICES_FAILURE, LOAD_INVOICES_SUCCESS, LOAD_INVOICE_FAILURE, LOAD_INVOICE_SUCCESS, UPDATE_INVOICE, UPDATE_INVOICE_FAILURE, UPDATE_INVOICE_SUCCESS } from './actions';
import { Api } from '../../Api';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

// Define your saga functions here

function* fetchInvoice(api: Api<any>, action) {
  try {
    const requestParams = RequestQueryBuilder.create();
    requestParams.setJoin({ field: 'lineItems' });
    requestParams.setJoin({ field: 'customer' });
    requestParams.setJoin({ field: 'content' });
    // Call the API to fetch the invoice
    const response = yield call(api.invoice.getOneBaseInvoiceControllerInvoice, action.payload, requestParams.queryObject);

    yield put({ type: LOAD_INVOICE_SUCCESS, payload: response.data });
  } catch (error:any ) {
    yield put({ type: LOAD_INVOICE_FAILURE, payload: error.message });
  }
}

function* fetchInvoices(api: Api<any>, action) {
    try {
        const payload = action.payload || {};
        // Call the API to fetch the invoices
        const invoices = yield call(api.invoice.getManyBaseInvoiceControllerInvoice, payload);
    
        // Dispatch the success action with the fetched invoices
        yield put({ type: LOAD_INVOICES_SUCCESS, payload: invoices.data });
    } catch (error:any ) {
        // Dispatch the failure action with the error message
        yield put({ type: LOAD_INVOICES_FAILURE, payload: error.message });
    }
    }

function* createInvoice(api: Api<any>, action) {
  try {
    // Call the API to create the invoice
    const createdInvoice = yield call(api.invoice.createOneBaseInvoiceControllerInvoice, action.payload);

    // Dispatch the success action with the created invoice
    yield put({ type: CREATE_INVOICE_SUCCESS, payload: createdInvoice });
  } catch (error: any) {
    // Dispatch the failure action with the error message
    yield put({ type: CREATE_INVOICE_FAILURE, payload: error.message });
  }
}

function* updateInvoice(api: Api<any>, action) {
    try {
        // Call the API to update the invoice
        const updatedInvoice = yield call(api.invoice.updateOneBaseInvoiceControllerInvoice, action.payload.id, action.payload.data);
    
        // Dispatch the success action with the updated invoice
        yield put({ type: UPDATE_INVOICE_SUCCESS, payload: updatedInvoice });
    } catch (error: any) {
        // Dispatch the failure action with the error message
        yield put({ type: UPDATE_INVOICE_FAILURE, payload: error.message });
    }
    }

// Watcher saga
export default function* invoiceSaga(api: Api<any>) {
  yield takeLatest(LOAD_INVOICE, fetchInvoice, api);
  yield takeLatest(CREATE_INVOICE, createInvoice, api);
  yield takeLatest(LOAD_INVOICES, fetchInvoices, api);
  yield takeLatest(UPDATE_INVOICE, updateInvoice, api);
}
