// GigForm.js
import React, { useEffect, useMemo } from 'react';
import { ReactPhotoCollage } from "react-photo-collage";
import { useParams } from 'react-router-dom';
import { formatRelative } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '@mantine/form';

import { RootState } from '../../redux';
import { fetchGig } from '../../redux/calendar/actions';
import { GigDetails } from './GigDetails';
import { Tabs } from '@mantine/core';
import { IconFileInvoice, IconMusicBolt, IconPencil } from '@tabler/icons-react';
export const GigOverview = () => {
  const dispatch = useDispatch();
    const { gigId } = useParams();
    const gig = useSelector((state: RootState) => state.calendar.gigs.find((gig: any) => gig.id === gigId));
    const loading = useSelector((state: RootState) => state.calendar.loading);
    useEffect(() => {
        if(gigId){
            dispatch(fetchGig(gigId));
        }
    }, [gigId]);
    useEffect(() => {
        if(gig){
            form.setValues({
               name:gig.name,
      description:gig.description,
      public: gig.public,
      startDate: new Date(gig.startDate),
      ticketingEnabled:gig.ticketingEnabled,
      ticketCount: gig.ticketCount,
      loadInTime:gig.loadInTime,
      soundCheckTime:gig.soundCheckTime,
      eventStartTime:gig.eventStartTime,
      doorsTime:gig.doorsTime,
      venueId: gig.venueId,
      address: gig.address,
      room:gig.room,
      notes:gig.notes,
      attire:gig.attire,
      food:gig.food,
      backline:gig.backline,
      parking:gig.parking,
      bookingStatus: gig.bookingStatus
            });
        }
    }, [gig]);
  
  const form = useForm({
    initialValues: {
        name:'',
        description:'',
        public: true,
        startDate: new Date(),
        ticketingEnabled:'',
        ticketCount: 0,
        loadInTime:'',
        soundCheckTime:'',
        eventStartTime:'',
        doorsTime:'',
        venueId: '',
        address: '',
        room:'',
        notes:'',
        attire:'',
        food:'',
        backline:'',
        parking:'',
        bookingStatus: '',
    },
  }, );

  const photos = useMemo(() => gig?.venue?.photos?.map((url: string) => {
    return {
      source: url,
    }
  }) || [], [gig?.venue?.photos]);
  const title = useMemo(() => {
    if(!gig){
        return '';
    }
    const today = new Date(); 
    const gigDate = new Date(gig.startDate);
    const dateString = formatRelative(gigDate, today);
    if(gig?.venue){
        return `${dateString} at ${gig.venue.name}`
    }
    return `${dateString} at ${gig.address}`;
  }, [gig]);

  return (
    <>
    {!loading && photos.length > 0 && <ReactPhotoCollage photos={photos} width={'100%'} height={['250px', '170px']} layout={[ 6]} showNumOfRemainingPhotos={true} />}
        <h2>{title}</h2>
        <Tabs defaultValue="details">
      <Tabs.List>
      <Tabs.Tab value="details" leftSection={<IconPencil/>}>
          Details
        </Tabs.Tab>
        <Tabs.Tab value="invoices" leftSection={<IconFileInvoice/>}>
            Invoices
        </Tabs.Tab>
        <Tabs.Tab value="setList" leftSection={<IconMusicBolt/>}>
            Set List
        </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='details'>
        <GigDetails gig={gig}/>
        </Tabs.Panel>
        </Tabs>

        </>
);
};

