import { useParams } from "react-router-dom";
import InvoicePage from "../../components/Invoice/components/InvoicePage";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux";
import { useEffect, useState } from "react";
import { loadInvoice, updateInvoiceContent } from "../../redux/invoice/actions";
import { IInvoiceApp } from "../../components/Invoice/data/types";
import { Invoice, InvoiceLineItem } from "../../Api";
import { Button } from "@mantine/core";
export const InvoiceView = () => {
    const dispatch = useDispatch();
    const [updateDelta, setUpdateDelta] = useState({} as any);
    const { id } = useParams();
    const invoice: Invoice | null = useSelector((state: RootState) => state.invoice.invoices).find((invoice: any) => invoice.id === id);
    useEffect(() => {
        dispatch(loadInvoice(id));
    }, [id])
    if(!invoice) return (<div>loading</div>);
    const invoiceAppMapping: IInvoiceApp = { 
        invoiceTitle: invoice.invoiceNumber || '', 
        logo: invoice.content.logo || '',
        logoWidth: invoice.content.logoWidth || 200,
        title: invoice.content.title || '',
        companyName: invoice.content.companyName || '',
        name: invoice.content.name || '',
        companyAddress: invoice.content.companyAddress || '',
        companyAddress2: invoice.content.companyAddress2 || '',
        companyCountry: invoice.content.companyCountry || '',
      
        billTo: invoice.content.billTo || '',
        clientName: invoice.content.clientName || '',
        clientAddress: invoice.content.clientAddress || '',
        clientAddress2: invoice.content.clientAddress2 || '',
        clientCountry: invoice.content.clientCountry || '',
      
        invoiceTitleLabel: invoice.content.invoiceTitleLabel || '',
        invoiceDateLabel: invoice.content.invoiceDateLabel || '',
        invoiceDate: invoice.content.invoiceDate || '',
        invoiceDueDateLabel: invoice.content.invoiceDueDateLabel || '',
        invoiceDueDate: invoice.content.invoiceDueDate || '',
      
        productLineDescription: invoice.content.productLineDescription || '',
        productLineQuantity: invoice.content.productLineQuantity || '',
        productLineQuantityRate: invoice.content.productLineQuantityRate || '',
        productLineQuantityAmount: invoice.content.productLineQuantityAmount || '',
      
        productLines: invoice.lineItems.map((lineItem: InvoiceLineItem) => ({
            description: lineItem.description || '',
            quantity: `${lineItem.quantity || ''}`,
            rate: `${lineItem.amount || ''}`,
        })),
      
        subTotalLabel: invoice.content.subTotalLabel || '',
        taxLabel: invoice.content.taxLabel || '',
      
        totalLabel: invoice.content.totalLabel || '',
        currency: invoice.content.currency || '',
      
        notesLabel: invoice.content.notesLabel || '',
        notes: invoice.content.notes || '',
        termLabel: invoice.content.termLabel || '',
        term: invoice.content.term || '',

    };
    const updateInvoice = (key: string, data: any) => {
        setUpdateDelta({ ...updateDelta, [key]: data });
    };
    return <>
    <Button disabled={Object.keys(updateDelta).length === 0} onClick={() => {
        dispatch(updateInvoiceContent(invoice.content.id, updateDelta));
        setUpdateDelta({});
    }}>Save</Button>
    <InvoicePage data={invoiceAppMapping} fieldChange={updateInvoice}/>
    </>
};