// GigForm.js
import React, { useEffect, useState } from 'react';
import { TextInput, Textarea, NumberInput, Checkbox, Accordion, Button, Select, Modal, Grid, Group, Combobox, useCombobox, InputBase, Input, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput, TimeInput } from '@mantine/dates';
import LocationSearchInput from '../../components/Input/LocationSearchInput';
import { useDispatch, useSelector } from 'react-redux';
import { clearCreateGigResult, createGig } from '../../redux/calendar/actions';
import { RootState } from '../../redux';
import { getProfiles } from '../../redux/profile/actions';
import { CurrencyInput } from '../../components/Input/CurrencyInput';
import { push } from 'redux-first-history';

export const GigForm = ({ profileId, newGigEventData, open, toggle, personal = false }: any) => {
    const [overrideProfileId, setOverrideProfileId] = useState<string | null>(profileId);
    const [createInvoice, setCreateInvoice] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const combobox = useCombobox();
    const dispatch = useDispatch();
    const profilesLoading = useSelector((state: RootState) => state.profile.loading);
    const profiles = useSelector((state: RootState) => state.profile.profiles);
    const selectedProfile = profiles.find((profile: any) => profile.id === overrideProfileId);
    const createGigResult = useSelector((state: RootState) => state.calendar.createGigResult);
    useEffect(() => {
      if(createGigResult){
        dispatch(clearCreateGigResult());
      }
    }, []);
    useEffect(() => {
        if(!profileId){
            dispatch(getProfiles());
        }
    }, [profileId]);
    useEffect(() => {
        if(newGigEventData){
            form.setValues({
                startDate: newGigEventData.date,
            })
        }
    }, [newGigEventData]);
    useEffect(() => {
      if(createGigResult && submitted){
        if(createInvoice){
          dispatch(push(`/gigs/${createGigResult.id}?view=invoices`))
        }
        setSubmitted(false);
        dispatch(clearCreateGigResult());
        toggle(false);

      }
    }, [submitted, createGigResult]);

    const profileOptions = profiles.map((profile: any) => (
        <Combobox.Option value={profile.id} key={profile.id}>
          {profile.name}
        </Combobox.Option>
      ));
  const form = useForm({
    initialValues: {
      name:'',
      description:'',
      public: true,
      startDate: '',
      ticketingEnabled:'',
      ticketCount: 0,
      loadInTime:'',
      soundCheckTime:'',
      eventStartTime:'',
      fee: '',
      feeCurrency: 'gbp',
      doorsTime:'',
      venueId: '',
      address: '',
      room:'',
      notes:'',
      attire:'',
      food:'',
      backline:'',
      parking:'',
      bookingStatus: 'pending',
    },
  }, );
  
  const submitNewGig = (values) => {
    // Structure the payload as required by your backend
    const payload = {
      name: values.name,
      description: values.description,
      public: values.public,
      startDate: values.startDate,
      ticketingEnabled: values.ticketingEnabled === true,
      ticketCount: values.ticketCount,
      loadInTime: values.loadInTime,
      soundCheckTime: values.soundCheckTime,
      eventStartTime: values.eventStartTime,
      doorsTime: values.doorsTime,
      venueId: values.venueId || undefined,
      address: values.address,
      fee: values.fee === '' ? undefined : Number(values.fee),
      feeCurrency: values.feeCurrency, 
      notes: values.notes,
      attire: values.attire,
      food: values.food,
      backline: values.backline,
      parking: values.parking,
      profileId: overrideProfileId!,
      bookingStatus: values.bookingStatus,
      // Add other fields as necessary
    };
    setSubmitted(true);
    // Post the payload to the backend
    dispatch(createGig(payload, personal));
    form.reset();
  };
  return (<Modal
    opened={open}
    onClose={() => toggle(false)}
    title="Create New Gig"
    fullScreen={true}
  > 
<Grid>
  <Grid.Col span={6}>
  {!profileId && <Combobox
          store={combobox}
          onOptionSubmit={async (val) => {
            setOverrideProfileId(val)
            combobox.closeDropdown();
          }}
          
        >
          <LoadingOverlay
            visible={profilesLoading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />

          <Combobox.Target>
            <InputBase
              component="button"
              pointer
              rightSection={<Combobox.Chevron />}
              onClick={() => combobox.toggleDropdown()}
            >
             
             {selectedProfile?.name || <Input.Placeholder>Pick value</Input.Placeholder>}
            </InputBase>
          </Combobox.Target>

          <Combobox.Dropdown>
            <Combobox.Options>{profileOptions}</Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>}
  <form onSubmit={form.onSubmit((values) => submitNewGig(values))}>

       
  <TextInput
  label="Gig Name"
  placeholder="Gig name"
  required
  {...form.getInputProps('name')}

/>
<Textarea
    autosize
    maxRows={15}
  label="Description"
  placeholder="Describe the gig"
  {...form.getInputProps('description')}

/>
<Checkbox mt={20} mb={20} label="Public?" checked={true}           {...form.getInputProps('public')}
/>
<DateInput
  label="Start"
  placeholder="Pick date"
  {...form.getInputProps('startDate')}
  defaultValue={newGigEventData?.date}
/>

<Select
  label="Booking Status"
  placeholder="Select status"
  data={[{value:'pending', label: "Pending"}, {value: 'confirmed', label: 'Confirmed'}, {value:'cancelled', label: "Cancelled"}]}
  {...form.getInputProps('bookingStatus')}
/>
<CurrencyInput form={form} />
<Checkbox label="Create Invoice? (You will be redirect after creation)"
onChange={(event) => setCreateInvoice(event.currentTarget.checked)}
checked={createInvoice}
mt="5px"
/>
<Group>
<Checkbox label="Ticketed?"
mt="20px"
{...form.getInputProps('ticketingEnabled')}/>
{form.values.ticketingEnabled && 
<NumberInput
  label="Ticket Count"
  placeholder="Number of tickets"
  {...form.getInputProps('ticketCount')}

/>}
</Group>
<Accordion>
  <Accordion.Item key="timings" value="Timings">
    <Accordion.Control>🕒 Timings</Accordion.Control>
  <Accordion.Panel>

    <TimeInput label="Load in" {...form.getInputProps('loadInTime')} />
    <TimeInput label="Sound check" {...form.getInputProps('soundCheckTime')} />
    <TimeInput label="Event start" {...form.getInputProps('eventStartTime')} />
    <TimeInput label="Doors" {...form.getInputProps('doorsTime')} />
    </Accordion.Panel>
  </Accordion.Item>

<Accordion.Item key="location" value="Location">
<Accordion.Control>🌎 Location</Accordion.Control>

<Accordion.Panel>
  <LocationSearchInput  form={form} label="Location" />
  <TextInput label="Room" placeholder="Enter room" {...form.getInputProps('room')} />
  </Accordion.Panel>
</Accordion.Item>

<Accordion.Item key="additional" value="Additional information">
<Accordion.Control>ℹ️ Additional Information</Accordion.Control>

<Accordion.Panel>
  <Textarea     autosize
    maxRows={15} label="Notes" {...form.getInputProps('notes')} />
  <Textarea    autosize
    maxRows={15} label="Attire" {...form.getInputProps('attire')} />
  <Textarea    autosize
    maxRows={15} label="Food" {...form.getInputProps('food')} />
  <Textarea    autosize
    maxRows={15} label="Backline" {...form.getInputProps('backline')} />
  <Textarea    autosize
    maxRows={15} label="Parking" {...form.getInputProps('parking')} />
</Accordion.Panel>
</Accordion.Item>
</Accordion>
<Button type="submit">Submit</Button>
</form>
</Grid.Col>
</Grid>
  </Modal>)
};
