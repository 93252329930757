import { useEffect } from "react";
import { Avatar, Button, Card, Grid, Spoiler, Stack, Text, Title } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { getProfiles } from "../../redux/profile/actions";
import { Profile } from "../../Api";

export const Bands = () => {
  const dispatch = useDispatch();
  const profiles = useSelector((state: any) => state.profile.profiles);

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  return (
    <Stack>
      {profiles.map((profile: Profile) => (
        <Card key={profile.id} shadow="sm" padding="lg" radius="md" withBorder>
          <Grid align="center">
            <Grid.Col span={3}>
              <Avatar size={60} alt={profile.name} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Title order={3}>{profile.name}</Title>
              <Spoiler maxHeight={60} showLabel="Show more" hideLabel="Hide">
                <Text size="sm">{profile.bio}</Text>
              </Spoiler>
            </Grid.Col>
            <Grid.Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outline">Settings</Button>
            </Grid.Col>
          </Grid>
        </Card>
      ))}
    </Stack>
  );
};
