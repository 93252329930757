import { useCallback, useRef } from 'react';

// Hook
function useDebouncedCallback(callback, delay) {
  // Use a ref to store the timeout between renders
  // and prevent changes to the timeout from causing re-renders
  const timeoutRef = useRef();

  // This is the debounced function that will be returned
  const debouncedCallback = useCallback(
    (...args) => {
      // If there's a pending timeout, clear it
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay) as any;
    },
    [callback, delay] // Only recreate the debounced function if these values change
  );

  return debouncedCallback;
}

export default useDebouncedCallback;
