import React, { useEffect, useRef, useState } from 'react';
import { TextInput, Button, UnstyledButton } from '@mantine/core';
import { IconSend, IconCaretLeft } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatMessage } from './ChatMessage';
import { loadChatThread } from '../../redux/chat/actions';

export const ChatThread = ({ onSubmitMessage, currentChat, onExit }: any) => {
    const [message, setMessage] = useState('');
    const messageListRef = useRef(null);
    const dispatch = useDispatch();
    const chatMessages = useSelector((state: any) => state.chat.messages).filter((msg:any) => msg.chatId === currentChat?.id).sort((a:any, b:any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    useEffect(() => {
        // Scroll to bottom on initial load and when new messages are added
        const messageListElement = messageListRef.current as any;
        if (messageListElement) {
            messageListElement.scrollTop = messageListElement.scrollHeight;
        }
    }, [chatMessages.length]);
    useEffect(() => {
        if(currentChat){
            dispatch(loadChatThread(currentChat?.id));
        }
    }, [currentChat?.id]);

    const sendMessage = () => {
        onSubmitMessage(message);
        setMessage('');
    };
    return (
        <div style={{ height: '70vh', display: 'flex', flexDirection: 'column', overflow:'scroll' }}>
            <UnstyledButton onClick={onExit} style={{ display: 'flex', alignItems: 'center' }}>
                <IconCaretLeft /> {currentChat?.profile.name}
            </UnstyledButton>

            <div
                ref={messageListRef}
                style={{
                    flexGrow: 1,
                    overflowY: 'scroll',
                    padding: '10px',
                }}
            >
                {chatMessages.map((msg:any, index) => (
                    <ChatMessage key={index} message={msg} />
                ))}
            </div>

            <div style={{ display: 'flex', padding: '10px' }}>
                <TextInput
                    placeholder="Type a message..."
                    value={message}
                    onChange={(event) => setMessage(event.currentTarget.value)}
                    style={{ flexGrow: 1, marginRight: '10px' }}
                />
                <Button onClick={sendMessage}>
                    <IconSend />
                </Button>
            </div>
        </div>
    );
};