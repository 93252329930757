import { take, put, fork, cancelled, all, call, takeLatest } from 'redux-saga/effects';
import {
  CREATE_SET_LIST_SONG_OPTIMISTICALLY,
  UPDATE_SET_LIST_SONG_OPTIMISTICALLY,
  DELETE_SET_LIST_SONG_OPTIMISTICALLY,
  LOAD_SET_LIST,
  REORDER_SET_LIST_SONGS,
  CREATE_SET_LIST_GROUP,
  IMPORT_SPOTIFY_PLAYLIST,
  UPDATE_SET_LIST,
  UPDATE_SET_LIST_SONG,
  listSetListsSuccess,
  listSetListsFailure,
  LIST_SET_LISTS,
  CREATE_SET_LIST,
  createSetListSuccess,
  createSetListFailure,
  ADD_RECOMMENDED_SONG,
  UPDATE_SET_LIST_GROUP
  // Other imported action types
} from './actions';
import { SEND_WEBSOCKET_MESSAGE } from '../websocket/actions';
import { Api } from '../../Api';
import { push } from 'redux-first-history';



function* watchWebSocketEvents() {
  try {
    while (true) {
      const action = yield take([
        CREATE_SET_LIST_SONG_OPTIMISTICALLY,
        UPDATE_SET_LIST_SONG_OPTIMISTICALLY,
        DELETE_SET_LIST_SONG_OPTIMISTICALLY,
        LOAD_SET_LIST,
        UPDATE_SET_LIST,
        REORDER_SET_LIST_SONGS,
        UPDATE_SET_LIST_SONG,
        ADD_RECOMMENDED_SONG,
        CREATE_SET_LIST_GROUP,
        UPDATE_SET_LIST_GROUP,
        // Add other Redux action types as needed
      ]);
      
      // Based on the action type, emit the corresponding WebSocket event
      switch (action.type) {
        case CREATE_SET_LIST_SONG_OPTIMISTICALLY:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/CREATE_SONG', message: action.payload } })
          break;
        case UPDATE_SET_LIST_SONG_OPTIMISTICALLY:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/UPDATE_SONG', message: action.payload } })
          break;
        case DELETE_SET_LIST_SONG_OPTIMISTICALLY:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/DELETE_SONG', message: action.payload } })
          break;
        case LOAD_SET_LIST:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/LOAD_SETLIST', message: action.payload } })
          break;
        case REORDER_SET_LIST_SONGS:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/REORDER_SONGS', message: action.payload } })
          break;
        case CREATE_SET_LIST_GROUP:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/CREATE_SETLIST_GROUP', message: action.payload } })
          break;
        case UPDATE_SET_LIST_GROUP:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/UPDATE_SETLIST_GROUP', message: {
            id: action.payload.id,
            title: action.payload.data.title,
            description: action.payload.data.description
          
          } } })
          break;
        case IMPORT_SPOTIFY_PLAYLIST:
          yield put({ type: SEND_WEBSOCKET_MESSAGE, payload: { key: '@SETLIST/IMPORT_SPOTIFY_PLAYLIST', message: action.payload } })
          break;
        case UPDATE_SET_LIST:
            yield put ({
              type: UPDATE_SET_LIST,
              payload: action.payload
            })
            yield put({ 
              type: SEND_WEBSOCKET_MESSAGE, 
              payload: { key: '@SETLIST/UPDATE_SETLIST', message: action.payload } 
            });
          break;
        case UPDATE_SET_LIST_SONG:
          yield put({ 
            type: SEND_WEBSOCKET_MESSAGE, 
            payload: { key: '@SETLIST/UPDATE_SONG', message: action.payload } 
          });
          break;
        case ADD_RECOMMENDED_SONG: 
          yield put({ 
            type: SEND_WEBSOCKET_MESSAGE, 
            payload: { key: '@SETLIST/ADD_RECOMMENDED_SONG', message: action.payload } 
          });
          break;
        
      }
    }
  } finally {
    if (yield cancelled()) {
      // Handle cancellation if needed
    }
  }
}
function* listSetlistsSaga(api: Api<any>) {
  try {
    const response = yield call(api.setList.getManyBaseSetListControllerSetList);
    yield put(listSetListsSuccess(response.data));
  } catch (error: any) {
    yield put(listSetListsFailure(error.message));
  }
}
function* createSetlist(api: Api<any>, action) {
  try {
    const response = yield call(api.setList.createOneBaseSetListControllerSetList, action.payload);
    yield put(createSetListSuccess(response.data));
    if(action.payload.navigate) {
      yield put(push(`/set-list/${response.data.id}`));
    }
  } catch (error: any) {
    yield put(createSetListFailure(error.message));
  }
}
export default function* rootSaga(api: Api<any>) {
    yield all([
      fork(watchWebSocketEvents)
    ]);
    yield takeLatest(LIST_SET_LISTS, listSetlistsSaga, api);
    yield takeLatest(CREATE_SET_LIST, createSetlist, api);
  
}