import { NativeSelect, rem, TextInput } from '@mantine/core';

const data = [
  { value: 'eur', label: '🇪🇺 EUR' }, // Euro
  { value: 'usd', label: '🇺🇸 USD' }, // United States Dollar
  { value: 'jpy', label: '🇯🇵 JPY' }, // Japanese Yen
  { value: 'gbp', label: '🇬🇧 GBP' }, // British Pound
  { value: 'aud', label: '🇦🇺 AUD' }, // Australian Dollar
  { value: 'cad', label: '🇨🇦 CAD' }, // Canadian Dollar
  { value: 'chf', label: '🇨🇭 CHF' }, // Swiss Franc
  { value: 'cny', label: '🇨🇳 CNY' }, // Chinese Yuan
  { value: 'sek', label: '🇸🇪 SEK' }, // Swedish Krona
  { value: 'nzd', label: '🇳🇿 NZD' }, // New Zealand Dollar
  { value: 'mxn', label: '🇲🇽 MXN' }, // Mexican Peso
  { value: 'sgd', label: '🇸🇬 SGD' }, // Singapore Dollar
  { value: 'hkd', label: '🇭🇰 HKD' }, // Hong Kong Dollar
  { value: 'nok', label: '🇳🇴 NOK' }, // Norwegian Krone
  { value: 'krw', label: '🇰🇷 KRW' }, // South Korean Won
  { value: 'try', label: '🇹🇷 TRY' }, // Turkish Lira
  { value: 'inr', label: '🇮🇳 INR' }, // Indian Rupee
  { value: 'rub', label: '🇷🇺 RUB' }, // Russian Ruble
  { value: 'brl', label: '🇧🇷 BRL' }, // Brazilian Real
  { value: 'zar', label: '🇿🇦 ZAR' }, // South African Rand
  // Additional currencies
  { value: 'aed', label: '🇦🇪 AED' }, // United Arab Emirates Dirham
  { value: 'afn', label: '🇦🇫 AFN' }, // Afghanistan Afghani
  { value: 'all', label: '🇦🇱 ALL' }, // Albania Lek
  { value: 'amd', label: '🇦🇲 AMD' }, // Armenia Dram
  { value: 'ang', label: '🇳🇱 ANG' }, // Netherlands Antilles Guilder
  { value: 'aoa', label: '🇦🇴 AOA' }, // Angola Kwanza
  { value: 'ars', label: '🇦🇷 ARS' }, // Argentina Peso
  { value: 'awg', label: '🇦🇼 AWG' }, // Aruba Guilder
  { value: 'azn', label: '🇦🇿 AZN' }, // Azerbaijan New Manat
  { value: 'bam', label: '🇧🇦 BAM' }, // Bosnia and Herzegovina Convertible Marka
  { value: 'bbd', label: '🇧🇧 BBD' }, // Barbados Dollar
  { value: 'bdt', label: '🇧🇩 BDT' }, // Bangladesh Taka
  { value: 'bgn', label: '🇧🇬 BGN' }, // Bulgaria Lev
  { value: 'bhd', label: '🇧🇭 BHD' }, // Bahrain Dinar
  { value: 'bif', label: '🇧🇮 BIF' }, // Burundi Franc
  { value: 'bmd', label: '🇧🇲 BMD' }, // Bermuda Dollar
  { value: 'bnd', label: '🇧🇳 BND' }, // Brunei Darussalam Dollar
  { value: 'bob', label: '🇧🇴 BOB' }, // Bolivia Bolíviano
  { value: 'bsd', label: '🇧🇸 BSD' }, // Bahamas Dollar
  { value: 'btn', label: '🇧🇹 BTN' }, // Bhutan Ngultrum
  { value: 'bwp', label: '🇧🇼 BWP' }, // Botswana Pula
  { value: 'byr', label: '🇧🇾 BYR' }, // Belarus Ruble
  { value: 'bzd', label: '🇧🇿 BZD' }, // Belize Dollar
  { value: 'cdf', label: '🇨🇩 CDF' }, // Congo/Kinshasa Franc
  { value: 'clp', label: '🇨🇱 CLP' }, // Chile Peso
];
export function CurrencyInput({form, currencyKey = 'feeCurrency', valueKey= 'fee', label="Fee"}: any) {
  const select = (
    <NativeSelect
      data={data}
      rightSectionWidth={28}
      styles={{
        input: {
          fontWeight: 500,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: rem(92),
          marginRight: rem(-2),
        },
      }}
      {...form.getInputProps(currencyKey)}
    />
  );

  return (
    <TextInput
      type="number"
      placeholder="1000"
      label={label}
      rightSection={select}
      rightSectionWidth={92}
      {...form.getInputProps(valueKey)}

    />
  );
}