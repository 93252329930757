import React, { useState } from "react";
import { Button, Paper, Title, Container, TextInput } from "@mantine/core";
import { useDispatch, useSelector } from 'react-redux';
import { updateAccount } from "../../redux/auth/actions";
import { RootState } from "../../redux";

export const AccountEdit = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);  
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    dispatch(updateAccount(user.id, { firstName, lastName, email }));
  };

  return (
    <Container size={400} style={{ margin: "50px auto" }}>
      <Paper>
        <Title order={4}>Edit Account</Title>
        <form onSubmit={onSubmit}>
          <TextInput
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            label="First Name"
            placeholder="Enter first name"
          />
          <TextInput
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            label="Last Name"
            placeholder="Enter last name"
          />
          <TextInput
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            label="Email"
            placeholder="Enter email"
            type="email"
          />
          <Button style={{ marginTop: 20 }} type="submit">
            Update Account
          </Button>
        </form>
      </Paper>
    </Container>
  );
};
