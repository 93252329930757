import { call, put, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { CREATE_TODO, CREATE_TODO_ERROR, CREATE_TODO_SUCCESS, DELETE_TODO, DELETE_TODO_ERROR, DELETE_TODO_SUCCESS, GET_TODOS, GET_TODOS_ERROR, GET_TODOS_SUCCESS, UPDATE_TODO, UPDATE_TODO_ERROR, UPDATE_TODO_SUCCESS } from "./actions";



function* fetchTodos (api: Api<any> ){
    try {
        const todos = yield call(api.todo.getManyBaseTodoControllerTodo);
        yield put({ type: GET_TODOS_SUCCESS, payload: todos.data });
    } catch (error:any ) {
        yield put({ type: GET_TODOS_ERROR, payload: error.message });
    }
}
function* createTodo (api: Api<any>, action){
    console.log('creating')
    try {
        const todo = yield call(api.todo.createOneBaseTodoControllerTodo, action.payload);
        yield put({ type: CREATE_TODO_SUCCESS, payload: todo.data });
    } catch (error:any ) {
        yield put({ type: CREATE_TODO_ERROR, payload: error.message });
    }
}
function* deleteTodo(api: Api<any>, action) {
    try {
        // Call the API to delete the todo
        yield call(api.todo.deleteOneBaseTodoControllerTodo, action.payload);
    
        // Dispatch the success action with the deleted todo
        yield put({ type: DELETE_TODO_SUCCESS, payload: action.payload });
    } catch (error: any) {
        // Dispatch the failure action with the error message
        yield put({ type: DELETE_TODO_ERROR, payload: error.message });
    }
}

function* updateTodo(api:Api<any>, action) {
    try {
        
        const {data} = yield call(api.todo.updateOneBaseTodoControllerTodo, action.payload.id, action.payload.data);
    
        // Dispatch the success action with the updated todo
        yield put({ type: UPDATE_TODO_SUCCESS, payload: data });
    } catch (error: any) {
        // Dispatch the failure action with the error message
        yield put({ type: UPDATE_TODO_ERROR, payload: error.message });
    }
    
}


export default function* todoSaga(api: Api<any>) {
    yield takeEvery(GET_TODOS, fetchTodos, api); 
    yield takeEvery(CREATE_TODO, createTodo, api);
    yield takeEvery(DELETE_TODO, deleteTodo, api);
    yield takeEvery(UPDATE_TODO, updateTodo, api);
}