import React, { useState } from "react";
import { Avatar, Popover, Menu, Text, UnstyledButton } from "@mantine/core";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
// import { getPublicUrl } from "../../utils/getPublicUrl";
import { logout } from "../../redux/auth/actions";

export const UserButton = () => {
  // State to control the popover visibility
  // const profile = useSelector((state: RootState) => state.profile.profile);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
  };
    return (
    <Popover
      withArrow
      position="bottom"
      opened={isOpen}
      onClose={() => setOpen(false)}
    >
      <Popover.Target>
        <UnstyledButton pr={8} variant="transparent" onClick={() => setOpen(!isOpen)}>
          <Avatar
            size={40}
            src="path_to_user_image.jpg" // Path to the user's image
            alt="User"
          />
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <Menu>
          {/* <Menu.Item onClick={() => window.location.href = `${getPublicUrl()}/artists/${profile.slug}`}>
            <Text>View Profile</Text>
          </Menu.Item> */}
          
          <Menu.Item onClick={() => navigate("/account")}>
            <Text>Account</Text>
          </Menu.Item>
          <Menu.Item onClick={handleLogout}>
            <Text>Logout</Text>
          </Menu.Item>
        </Menu>
      </Popover.Dropdown>
    </Popover>
  );
};

