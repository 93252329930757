import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSpotifyRecommendations } from "../../../redux/spotify/actions";
import { Button, Table } from "@mantine/core";
import AudioPreview from "../../../components/AudioPreview/AudioPreview";
import { addRecommendedSong } from "../../../redux/set-list/actions";

export const SetListRecommendations = ({
    index, 
    setListGroupId
}: any) => {
    const [added, setAdded] = useState<any>([]);
    const dispatch = useDispatch();
    const recommendations = useSelector((state: any) => state.spotify.recommendations);
    useEffect(() => {
        dispatch(getSpotifyRecommendations(setListGroupId, index));
    }, [index, setListGroupId]);
    const addSong = (recommendation) => {
        setAdded([...added, recommendation.id]);
        dispatch(addRecommendedSong(recommendation.id, index, setListGroupId))
    }

    const filteredRecommendations = recommendations ? recommendations.filter((recommendation: any) => !added.includes(recommendation.id)) : [];

    return <Table>
        {recommendations ? filteredRecommendations.map((recommendation: any) => {
            
            return <Table.Tr key={recommendation.id}>
                <Table.Td><AudioPreview previewUrl={recommendation.preview_url} /></Table.Td>
                <Table.Td>{recommendation.name}</Table.Td>
                <Table.Td>{recommendation.artists.map((artist: any) => artist.name).join(', ')}</Table.Td>
                <Table.Td><Button onClick={() => addSong(recommendation)}>Add</Button></Table.Td>
            </Table.Tr>
        }): <div>Loading</div>}
    </Table>
}