import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadInvoices } from "../../redux/invoice/actions";
import { RootState } from "../../redux";

import { DataTable } from "mantine-datatable";
import { ActionIcon, Button, Card, Group } from "@mantine/core";
import { IconEdit, IconTrash, IconEye } from "@tabler/icons-react";

export const Finances = ({ invoices, loading }: any) => {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const user = useSelector((state: RootState) => state.auth.user);  
    const loadedInvoices = invoices ?? useSelector((state: RootState) => state.invoice.invoices).filter((invoice:any) => invoice.profileId === user.activeProfileId);
    const invoicesLoading = typeof loading === 'undefined' ? useSelector((state: RootState) => state.invoice.loading) : loading;
    useEffect(() => {
        if(typeof invoices === 'undefined' && typeof loading === 'undefined'){
            dispatch(loadInvoices());
        }
    }, [invoices, loading]);
   return  <Card><DataTable
   withTableBorder
   minHeight={180}
   columns={[{ accessor: 'name' }, { accessor: 'amount' }, { accessor: 'dueDate' }, { accessor: 'status' },  {
    accessor: 'actions',
    title: "",
    textAlign: 'right',
    
    render: () => (
      <Group gap={4} justify="right" wrap="nowrap">
        <ActionIcon
          size="sm"
          variant="subtle"
          color="green"
        >
          <IconEye size={16} />
        </ActionIcon>
        <ActionIcon
          size="sm"
          variant="subtle"
          color="blue"
        >
          <IconEdit size={16} />
        </ActionIcon>
        <ActionIcon
          size="sm"
          variant="subtle"
          color="red"
        >
          <IconTrash size={16} />
        </ActionIcon>
      </Group>
    ),
  },]}
   records={loadedInvoices}
   fetching={invoicesLoading}
   loaderType="dots"
   loaderSize="xl"
   emptyState={<div><Button>Create Invoice</Button></div>}
 /></Card>
};