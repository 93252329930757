export const CREATE_TODO = "@TODOS/CREATE_TODO";
export const CREATE_TODO_SUCCESS = "@TODOS/CREATE_TODO_SUCCESS";
export const CREATE_TODO_ERROR = "@TODOS/CREATE_TODO_ERROR";
export const DELETE_TODO = "@TODOS/DELETE_TODO";
export const DELETE_TODO_SUCCESS = "@TODOS/DELETE_TODO_SUCCESS";
export const DELETE_TODO_ERROR = "@TODOS/DELETE_TODO_ERROR";
export const UPDATE_TODO = "@TODOS/UPDATE_TODO";
export const UPDATE_TODO_SUCCESS = "@TODOS/UPDATE_TODO_SUCCESS";
export const UPDATE_TODO_ERROR = "@TODOS/UPDATE_TODO_ERROR";
export const GET_TODOS = "@TODOS/GET_TODOS";
export const GET_TODOS_SUCCESS = "@TODOS/GET_TODOS_SUCCESS";
export const GET_TODOS_ERROR = "@TODOS/GET_TODOS_ERROR";
export const GET_TODO = "@TODOS/GET_TODO";
export const GET_TODO_SUCCESS = "@TODOS/GET_TODO_SUCCESS";
export const GET_TODO_ERROR = "@TODOS/GET_TODO_ERROR";
export const SET_TODOS = "@TODOS/SET_TODOS";
export const setTodos = (todos: any) => ({
    type: SET_TODOS,
    payload: todos,
});
export const createTodo = (todo: any) => ({
    type: CREATE_TODO,
    payload: todo,
    });
export const createTodoSuccess = (todo: any) => ({
    type: CREATE_TODO_SUCCESS,
    payload: todo,
    });
export const createTodoError = (error: any) => ({
    type: CREATE_TODO_ERROR,
    payload: error,
    });
export const deleteTodo = (id: string) => ({
    type: DELETE_TODO,
    payload: id,
    });
export const deleteTodoSuccess = (id: string) => ({
    type: DELETE_TODO_SUCCESS,
    payload: id,
    });
export const deleteTodoError = (error: any) => ({
    type: DELETE_TODO_ERROR,
    payload: error,
    });
export const updateTodo = (id: string, data: any) => ({
    type: UPDATE_TODO,
    payload: {id, data},
    });
export const updateTodoSuccess = (todo: any) => ({
    type: UPDATE_TODO_SUCCESS,
    payload: todo,
    });
export const updateTodoError = (error: any) => ({
    type: UPDATE_TODO_ERROR,
    payload: error,
    });

export const getTodos = () => ({
    type: GET_TODOS,
    });
export const getTodosSuccess = (todos: any) => ({
    type: GET_TODOS_SUCCESS,
    payload: todos,
    });
export const getTodosError = (error: any) => ({
    type: GET_TODOS_ERROR,
    payload: error,
    });
export const getTodo = (todo: any) => ({
    type: GET_TODO,
    payload: todo,
    });
export const getTodoSuccess = (todo: any) => ({
    type: GET_TODO_SUCCESS,
    payload: todo,
    });
export const getTodoError = (error: any) => ({
    type: GET_TODO_ERROR,
    payload: error,
    });
    
