
import { LOGOUT_SUCCESS } from '../auth/actions';
import { CLEAR_SONG_META_CACHE, CLEAR_SPOTIFY_RECOMMENDATIONS, CLEAR_SPOTIFY_SEARCH_RESULTS, GET_SPOTIFY_RECOMMENDATIONS, GET_SPOTIFY_RECOMMENDATIONS_FAILURE, GET_SPOTIFY_RECOMMENDATIONS_SUCCESS, SET_SONG_META, SET_SONG_META_LOADING, SET_SPOTIFY_PLAYLISTS, SET_SPOTIFY_SEARCH_RESULTS } from './actions';

const initialState = {
  accessToken: null,
  searchResults: [],
  songMetaCache: null,
  songMetaLoading: false,
  playlists: [],
  recommendations: null,
  error: null,
  loading: false,
  spotifyImporting: null,
};

const spotifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case CLEAR_SPOTIFY_RECOMMENDATIONS: {
      return {
        ...state,
        recommendations: null,
      }
    }
    case GET_SPOTIFY_RECOMMENDATIONS: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_SPOTIFY_RECOMMENDATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        recommendations: action.payload,
      }
    }
    case GET_SPOTIFY_RECOMMENDATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    }
    case SET_SPOTIFY_PLAYLISTS: {
      return {
        ...state,
        playlists: action.payload,
      };
    }
    case SET_SONG_META_LOADING: {
      return {
        ...state,
        songMetaLoading: action.payload,
      };
    }
    case CLEAR_SONG_META_CACHE: {
      return {
        ...state,
        songMetaCache: null,
      };
    }
    case SET_SONG_META: {
      return {
        ...state,
        songMetaCache: action.payload,
        songMetaLoading: false,
      };
    }
    case SET_SPOTIFY_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: action.payload,
      };
    }
    case CLEAR_SPOTIFY_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: [],
      };
    }
    default:
      return state;
  }
};

export default spotifyReducer;
