// Action Types
export const LOGIN = '@AUTH/LOGIN';
export const LOGIN_SUCCESS = '@AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@AUTH/LOGIN_FAILURE';

export const LOGOUT = '@AUTH/LOGOUT';
export const LOGOUT_SUCCESS = '@AUTH/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = '@AUTH/LOGOUT_FAILURE';

export const SIGNUP = '@AUTH/SIGNUP';
export const SIGNUP_SUCCESS = '@AUTH/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = '@AUTH/SIGNUP_FAILURE';

export const UPDATE_ACCOUNT = '@AUTH/UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = '@AUTH/UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = '@AUTH/UPDATE_ACCOUNT_FAILURE';

export const INITIALIZE_AUTH = '@AUTH/INITIALIZE_AUTH';
export const INITIALIZE_AUTH_SUCCESS = '@AUTH/INITIALIZE_AUTH_SUCCESS';

export const CHANGE_PASSWORD = '@AUTH/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = '@AUTH/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = '@AUTH/CHANGE_PASSWORD_FAILURE';

export const SET_USER = '@AUTH/SET_USER';

export const CONFIRM_ACCOUNT = '@AUTH/CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_SUCCESS = '@AUTH/CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_FAILURE = '@AUTH/CONFIRM_ACCOUNT_FAILURE';
export const RESEND_CONFIRMATION_EMAIL = '@AUTH/RESEND_CONFIRMATION_EMAIL';

export const confirmAccount = (token) => ({
  type: CONFIRM_ACCOUNT,
  payload: { token },
});

export const confirmAccountSuccess = () => ({
  type: CONFIRM_ACCOUNT_SUCCESS,
});

export const confirmAccountFailure = (error) => ({
  type: CONFIRM_ACCOUNT_FAILURE,
  payload: error,
});

export const resendConfirmationEmail = (email) => ({
  type: RESEND_CONFIRMATION_EMAIL,
  payload: { email },
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
    });

export const changePassword = (currentPassword, newPassword, newPasswordConfirm) => ({
  type: CHANGE_PASSWORD,
  payload: { currentPassword, newPassword, newPasswordConfirm },
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (error) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: error,
});
export const login = (email, password) => ({
    type: LOGIN,
    payload: { email, password },
});

export const loginSuccess = user => ({
    type: LOGIN_SUCCESS,
    payload: user,
});

export const loginFailure = error => ({
    type: LOGIN_FAILURE,
    payload: error,
});

export const logout = () => ({
    type: LOGOUT,
});

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logoutFailure = error => ({
    type: LOGOUT_FAILURE,
    payload: error,
});

export const signUp = (email, password) => ({
    type: SIGNUP,
    payload: { email, password },
});

export const signUpSuccess = user => ({
    type: SIGNUP_SUCCESS,
    payload: user,
});

export const signUpFailure = error => ({
    type: SIGNUP_FAILURE,
    payload: error,
});

export const updateAccount = (userId, updateData) => ({
    type: UPDATE_ACCOUNT,
    payload: { userId, updateData },
});

export const updateAccountSuccess = user => ({
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: user,
});

export const updateAccountFailure = error => ({
    type: UPDATE_ACCOUNT_FAILURE,
    payload: error,
});

export const initializeAuth = () => ({
    type: INITIALIZE_AUTH,
  });
  
  export const initializeAuthSuccess = (user) => ({
    type: INITIALIZE_AUTH_SUCCESS,
    payload: user,
  });