// GigForm.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@mantine/form';
import { Accordion, Button, Card, Checkbox, Grid, Group, NumberInput, Select, TextInput, Textarea } from '@mantine/core';
import { DateInput, TimeInput } from '@mantine/dates';
import LocationSearchInput from '../../components/Input/LocationSearchInput';
import { updateGig } from '../../redux/calendar/actions';
export const GigDetails = ({gig}: any ) => {
  const dispatch = useDispatch();

    useEffect(() => {
        if(gig){
            form.setValues({
               name:gig.name,
      description:gig.description,
      public: gig.public,
      startDate: new Date(gig.startDate),
      ticketingEnabled:gig.ticketingEnabled,
      ticketCount: gig.ticketCount,
      loadInTime:gig.loadInTime,
      soundCheckTime:gig.soundCheckTime,
      eventStartTime:gig.eventStartTime,
      doorsTime:gig.doorsTime,
      venueId: gig.venueId,
      address: gig.address,
      room:gig.room,
      notes:gig.notes,
      attire:gig.attire,
      food:gig.food,
      backline:gig.backline,
      parking:gig.parking,
      bookingStatus: gig.bookingStatus
            });
        }
    }, [gig]);
  
  const form = useForm({
    initialValues: {
        name:'',
        description:'',
        public: true,
        startDate: new Date(),
        ticketingEnabled:'',
        ticketCount: 0,
        loadInTime:'',
        soundCheckTime:'',
        eventStartTime:'',
        doorsTime:'',
        venueId: '',
        address: '',
        room:'',
        notes:'',
        attire:'',
        food:'',
        backline:'',
        parking:'',
        bookingStatus: '',
    },
  }, );

  const handleUpdateGig = (values:any) => {
    const payload = {
        name: values.name,
        description: values.description,
        public: values.public === true,
        startDate: values.startDate,
        ticketingEnabled: values.ticketingEnabled === true,
        ticketCount: values.ticketCount,
        loadInTime: values.loadInTime,
        soundCheckTime: values.soundCheckTime,
        eventStartTime: values.eventStartTime,
        doorsTime: values.doorsTime,
        venueId: values.venueId,
        address: values.address,
        notes: values.notes,
        attire: values.attire,
        food: values.food,
        backline: values.backline,
        parking: values.parking,
        bookingStatus: values.bookingStatus,
        // Add other fields as necessary
      };
      dispatch(updateGig(payload, gig.id))
  };
  return (

         <form onSubmit={form.onSubmit((values) => handleUpdateGig(values))}>
         <Card shadow="sm" padding="lg" radius="md" withBorder>
<h3>Event Details</h3>
<Grid>

  <Grid.Col span={6}>

       
  <TextInput
  label="Gig Name"
  placeholder="Gig name"
  required
  {...form.getInputProps('name')}

/>
<Textarea
    autosize
    maxRows={15}
  label="Description"
  placeholder="Describe the gig"
  {...form.getInputProps('description')}

/>
<Checkbox mt={20} mb={20} label="Public?" checked={form.values.public} {...form.getInputProps('public')}
/>
<DateInput
  label="Start"
  placeholder="Pick date"
  {...form.getInputProps('startDate')}
/>

<Select
  label="Booking Status"
  placeholder="Select status"
  data={[{value:'pending', label: "Pending"}, {value: 'confirmed', label: 'Confirmed'}, {value:'cancelled', label: "Cancelled"}]}
  {...form.getInputProps('bookingStatus')}
/>
<Group>
<Checkbox label="Ticketed?"
mt="40px"
mb="40px"
checked={form.values.ticketingEnabled} 
{...form.getInputProps('ticketingEnabled')}/>
{form.values.ticketingEnabled && 
<NumberInput
  label="Ticket Count"
  placeholder="Number of tickets"
  {...form.getInputProps('ticketCount')}

/>}
</Group>

<Button type="submit">Update</Button>
</Grid.Col>
<Grid.Col span={6}>
<Accordion>
  <Accordion.Item key="timings" value="Timings">
    <Accordion.Control>🕒 Timings</Accordion.Control>
  <Accordion.Panel>

    <TimeInput label="Load in" {...form.getInputProps('loadInTime')} />
    <TimeInput label="Sound check" {...form.getInputProps('soundCheckTime')} />
    <TimeInput label="Event start" {...form.getInputProps('eventStartTime')} />
    <TimeInput label="Doors" {...form.getInputProps('doorsTime')} />
    </Accordion.Panel>
  </Accordion.Item>

<Accordion.Item key="location" value="Location">
<Accordion.Control>🌎 Location</Accordion.Control>

<Accordion.Panel>
  <LocationSearchInput  form={form} field="address" label="Location" />
  <TextInput label="Room" placeholder="Enter room" {...form.getInputProps('room')} />
  </Accordion.Panel>
</Accordion.Item>

<Accordion.Item key="additional" value="Additional information">
<Accordion.Control>ℹ️ Additional Information</Accordion.Control>

<Accordion.Panel>
  <Textarea     autosize
    maxRows={15} label="Notes" {...form.getInputProps('notes')} />
  <Textarea    autosize
    maxRows={15} label="Attire" {...form.getInputProps('attire')} />
  <Textarea    autosize
    maxRows={15} label="Food" {...form.getInputProps('food')} />
  <Textarea    autosize
    maxRows={15} label="Backline" {...form.getInputProps('backline')} />
  <Textarea    autosize
    maxRows={15} label="Parking" {...form.getInputProps('parking')} />
</Accordion.Panel>
</Accordion.Item>
</Accordion>

</Grid.Col>
</Grid>
</Card>

</form>
);
};

