
import React, { useEffect, useState } from 'react';
import { Paper, Text, TextInput, Button, Group, List, ThemeIcon, Checkbox, Select, Flex } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { createTodo, deleteTodo, getTodos, updateTodo } from '../../redux/todos/actions';
import { RootState } from '../../redux';
import { DatePicker } from '@mantine/dates';
import { AssigneeAvatar } from '../../components/Input/AssigneeAvatar';

export function Todo() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTodos());
  }, []);

 

  const toggleTaskCompletion = (id: string, completed: boolean) => {
   
    dispatch(updateTodo(id, { completed}))
  };

  const deleteTask = (id: string) => {
    if(!id){
        return;
    }
    dispatch(deleteTodo(id));
  };

  const [task, setTask] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [assignee, setAssignee] = useState('');
  const assignees = (useSelector((state: RootState) => state.profile.profile?.profileMembers) || []).map((member) => ({
    value: member.userId,
    label: member.title
  }))
  console.log(assignees)

  
  const addTask = () => {
    if (task.trim() !== '' && assignee) {
      dispatch(createTodo({
        text: task,
        dueDate,
        assigneeId: assignee,
        order: 1
      }));
      setTask('');
      setDueDate(null);
      setAssignee('');
    }
  };

  // Other functions remain the same
  const handleAssigneeChange = (todoId, assigneeId) => {
    console.log({todoId, assigneeId})
    if(assigneeId === ''){
      assigneeId = null;
    }
    dispatch(updateTodo(todoId, { assigneeId }));
  }
  const todos = useSelector((state: RootState) => state.todo.todos);

  return (
    <Paper shadow="xs" p="md" withBorder>
      <Text size="lg">Onboarding Todo List</Text>

      <Group mt="md">
        <TextInput
          value={task}
          onChange={(event) => setTask(event.currentTarget.value)}
          placeholder="Add a task"
          style={{ flex: 1 }}
        />
        <Select
          data={assignees}
          value={assignee}
          // onSelect={setAssignee}
          placeholder="Assignee"
          style={{ width: 200 }}
        />
        <DatePicker
          placeholder="Due date"
          value={dueDate}
          // onChange={setDueDate}
        />
        <Button onClick={addTask} color="blue">Add</Button>
      </Group>

      <List mt="md" spacing="sm" size="sm" center>
        {todos.sort((a, b) => a.order - b.order).map((task) => (
          <List.Item
            key={task.optimisticId || task.id}
            icon={
              <Checkbox
                checked={task.completed}
                onChange={() => toggleTaskCompletion(task.id, !task.completed)}
              />
            }
          >
            <Flex>
            <div>
            <Text
              lineClamp={1}
              style={{
                textDecoration: task.completed ? 'line-through' : undefined,
                marginRight: '10px'
              }}
            >
              {task.text}
            </Text>
            <Text size="sm" color="dimmed">Due: {task.dueDate ? new Date(task.dueDate).toLocaleDateString() : 'N/A'}</Text>
            </div>
            <AssigneeAvatar assignee={task.assigneeId} assignees={assignees} onAssigneeChange={(assigneeId) => handleAssigneeChange(task.id, assigneeId)} />
            <ThemeIcon
              color="red"
              variant="light"
              onClick={() => deleteTask(task.id)}
              size={18}
              radius="xl"
              style={{ cursor: 'pointer', marginLeft: 10 }}
            >
              x
            </ThemeIcon>
            </Flex>
          </List.Item>
        ))}
      </List>
    </Paper>
  );
}
