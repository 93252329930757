import { useEffect, useState } from "react";
import { ChatList } from './ChatList';
import { ChatThread } from './ChatThread';
import { useDispatch, useSelector } from "react-redux";
import { listChatThreads, sendChatMessage } from "../../redux/chat/actions";
export const Chat = () => {
    const dispatch = useDispatch();
    const [activeThread, setActiveThread] = useState<any>(null);
    const handleSubmitMessage = (message: string) => {
        dispatch(sendChatMessage(message, activeThread.id));
    };
    const chatThreads = useSelector((state: any) => state.chat.threads);
    useEffect(() => {
        dispatch(listChatThreads());
    }, [])
    return <div>
        
        
        {activeThread ? <ChatThread onSubmitMessage={handleSubmitMessage} currentChat={activeThread} onExit={() => setActiveThread(null)} /> :         <ChatList bandName="The Cool Cats" chats={chatThreads} onSelect={setActiveThread} />
}
    </div>
}