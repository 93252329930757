import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Custom hook to generate a UUID
function useUUID(dep: any = false) {
  const [uuid, setUUID] = useState(uuidv4());
  useEffect(() => {
    setUUID(uuidv4());
  }, [dep])
  return uuid;
}

export default useUUID;
