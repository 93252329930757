
export const INITIALIZE_WEBSOCKET = '@WS/INITIALIZE_WEBSOCKET';
export const WEBSOCKET_CONNECTED = '@WS/WEBSOCKET_CONNECTED';
export const WEBSOCKET_DISCONNECTED = '@WS/WEBSOCKET_DISCONNECTED';
export const EMIT_WEBSOCKET_EVENT = '@WS/EMIT_WEBSOCKET_EVENT';
export const WEBSOCKET_ERROR = '@WS/WEBSOCKET_ERROR';
export const SEND_WEBSOCKET_MESSAGE = '@WS/SEND_WEBSOCKET_MESSAGE';

export const sendWebsocketMessage = (key: string, message: any) => ({
  type: SEND_WEBSOCKET_MESSAGE,
  payload: {key, message},
});
export const initializeWebSocket = () => ({
  type: INITIALIZE_WEBSOCKET,
});

export const websocketConnected = () => ({
  type: WEBSOCKET_CONNECTED
});

export const websocketDisconnected = () => ({
  type: WEBSOCKET_DISCONNECTED,
});

export const emitWebSocketEvent = (event, payload) => ({
  type: EMIT_WEBSOCKET_EVENT,
  event,
  payload,
});

export const websocketError = (error) => ({
  type: WEBSOCKET_ERROR,
  payload: error,
});
