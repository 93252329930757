import { Fieldset, TextInput } from '@mantine/core';


export const AddressInput = ({ form }:any) => {


  return (
    <Fieldset legend="Address (Optional)">
        
   
          <TextInput label="Street" onChange={(e) => form.setFieldValue('street', e.target.value)} />
          <TextInput label="City" onChange={(e) => form.setFieldValue('city', e.target.value)} />
          <TextInput label="State/Province" onChange={(e) => form.setFieldValue('state', e.target.value)} />
          <TextInput label="Country" onChange={(e) => form.setFieldValue('country', e.target.value)} />
          <TextInput label="Postal Code" onChange={(e) => form.setFieldValue('postalCode', e.target.value)} />
      
    </Fieldset>
  );
};

