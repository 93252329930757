import { Button, Modal, TextInput, Textarea } from "@mantine/core";
import AudioPreview from "../../../components/AudioPreview/AudioPreview";
import OpenInSpotifyButton from "../../../components/AudioPlayer/OpenInSpotifyButton";
import { useForm } from "@mantine/form";
import { useEffect } from "react";

export const SetListSongOverview = ({ setListSongDetails, setSetListSongDetails}:any) => {
    const setListForm = useForm({
        initialValues: {
          title: '',
          artistName: '',
          notes: '',
          lyrics: '',
        },
      });
      useEffect(() => {
        setListForm.setValues({
            title: setListSongDetails?.song?.songName,
            artistName: setListSongDetails?.song?.artistName,
            notes: setListSongDetails?.notes,
            lyrics: setListSongDetails?.lyrics,
        })
      }, [setListSongDetails]);
    const handleUpdateSetListSong = (setListSongId: string, values: any) => {
        console.log(values);
      };
    return (
        <Modal
        opened={!!setListSongDetails}
        onClose={() => setSetListSongDetails(null)}
        fullScreen
        radius={0}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
       {setListSongDetails && setListSongDetails.song && <AudioPreview
                graphicUrl={setListSongDetails &&setListSongDetails.song.graphicUrl}
                previewUrl={setListSongDetails &&setListSongDetails.song.previewUrl}
              />}
            {setListSongDetails && setListSongDetails.song && <OpenInSpotifyButton spotifyId={setListSongDetails.song.spotifyId}/>}

<form style={{paddingBottom: '50px'}} onSubmit={setListForm.onSubmit((values) => handleUpdateSetListSong(setListSongDetails.id, values))}>
      <TextInput
        label="Title"
        placeholder="Song title"
        {...setListForm.getInputProps('title')}
        required
      />
      <TextInput
        label="Artist Name"
        placeholder="Artist name"
        {...setListForm.getInputProps('artistName')}
        required
      />
    
      <Textarea
        label="Notes"
        autosize
        maxRows={15}
        placeholder="Any special notes for the song"
        {...setListForm.getInputProps('notes')}
      />

      <Textarea
        label="Lyrics"
        autosize
        maxRows={25}
        placeholder="Lyrics of the song"
        {...setListForm.getInputProps('lyrics')}
      />
      <Button type="submit">Update</Button>
    </form>


      </Modal>
    )
};