import "@mantine/core/styles.css";
import { AppRouter } from "./routes/Router";
import { Notifications } from "@mantine/notifications";



function App() {
  
  return (
    <>
      <AppRouter />
      <Notifications position="top-right" zIndex={1000} />
    </>
  );
}

export default App;
