import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; 
import interactionPlugin from '@fullcalendar/interaction'; 
import { useNavigate } from 'react-router-dom';

export const Calendar= ({ events, onDateClick, onRangeChange }: any) => {
  const navigate = useNavigate();
  const handleEventClick = ({event}) => {
    navigate(`/gigs/${event.id}`);
  }
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridWeek"
        headerToolbar={{
          right: 'dayGridMonth,dayGridWeek,dayGridDay',
          left: 'prev,next today',
          center: 'title'
        }}
        eventClick={handleEventClick}
        events={events}
        dateClick={onDateClick}
        datesSet={onRangeChange}
      />
    </div>
  );
};

